import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import lodashGet from 'lodash/get';
import clsx from 'classnames';

import { SimpleFormInterface } from './simple-form.type';
import { useStyles } from './simple-form.style';
import { DynamicInput } from '../../dynamic-input';

const SimpleFormView = (props: SimpleFormInterface): ReactElement => {
  const { fields, className } = props;
  const locale = useLocale();
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {fields &&
        fields.map(field => {
          return (
            <div
              key={field.id as string}
              className={classes.inputContainer}
              data-style-simple-form="simpleForm"
            >
              <DynamicInput
                isProfile
                key={field.id}
                field={field}
                currentTabIndex={0}
                label={lodashGet(field, ['translatedCaption', locale], field.name)}
              />
            </div>
          );
        })}
    </div>
  );
};

export default SimpleFormView;
