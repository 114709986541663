import { FC, memo, useEffect, useState } from 'react';
import { FilterList } from '.';
import lodashFind from 'lodash/find';
import { useLocale } from 'react-admin';
import lodashGet from 'lodash/get';
import { ReportCalendarFilterInterface } from './report-calendar-filter.type';
import ReportCalendarFilterView from './report-calendar-filter.view';

const ReportCalendarFilterController: FC<ReportCalendarFilterInterface> = memo(
  props => {
    const { metaData, calendarEventsForFilter, calendarEvents, setCalendarEvents } =
      props;
    const [filterList, setFilterList] = useState<FilterList[]>([]);
    const locale = useLocale();

    useEffect(() => {
      const calendarConfig = metaData?.calendarConfig;
      const calculateNewFilter =
        calendarConfig?.quickFilters.map(quickFilter => ({
          name: {
            title: lodashGet(
              lodashFind(metaData?.tabsColumns?.table, {
                name: quickFilter,
              }),
              ['translatedCaption', locale],
            ) as string,
            id: quickFilter,
          },
          childFilter: [
            ...new Set(
              calendarEventsForFilter?.map(
                calendarEvent => calendarEvent[quickFilter as string] as string,
              ),
            ),
          ].reduce(
            (accumulatorFilterName, currentFilterName) => ({
              ...accumulatorFilterName,
              [currentFilterName]: true,
            }),
            {},
          ),
        })) ?? ([] as FilterList[]);

      setFilterList(calculateNewFilter);
    }, [calendarEventsForFilter]);

    /**
     * this function handle on click on filters
     *@function onClickChildFilter
     * @param {object} nameFilterClicked
     * @param {string} titleFilterClicked
     * @param {boolean}isCheckedFilterClicked
     * @returns {void}
     */
    const onClickChildFilter = (
      nameFilterClicked: { title: string; id: string },
      titleFilterClicked: string,
      isCheckedFilterClicked: boolean,
    ): void => {
      if (isCheckedFilterClicked) {
        setCalendarEvents(
          calendarEvents.filter(
            calendarEvent =>
              (calendarEvent[nameFilterClicked.id] as string)?.toString() !==
              titleFilterClicked.toString(),
          ),
        );
      } else {
        setCalendarEvents([
          ...calendarEvents,
          ...calendarEventsForFilter.filter(
            calendarEventFilter =>
              (calendarEventFilter[nameFilterClicked.id] as string)?.toString() ===
              titleFilterClicked.toString(),
          ),
        ]);
      }
      const newFilterList = filterList.map(filter =>
        filter.name === nameFilterClicked
          ? {
              ...filter,
              childFilter: {
                ...filter.childFilter,
                [titleFilterClicked]: !isCheckedFilterClicked,
              },
            }
          : filter,
      );
      setFilterList(newFilterList);
    };

    return (
      <ReportCalendarFilterView
        filterList={filterList}
        onClickChildFilter={onClickChildFilter}
      />
    );
  },
);

export default ReportCalendarFilterController;
