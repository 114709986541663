import moment from 'jalali-moment';
let jmoment = moment;
if (jmoment.default) {
  jmoment = jmoment.default;
}
jmoment.locale('fa');
import momentLocalizer from './moment';
const jalaliLocalizer = momentLocalizer(jmoment);

export default jalaliLocalizer;
