import { ReactElement } from 'react';
import { Icon, TextField } from '@material-ui/core';
import { SearchBoxViewInterface } from './search-box.type';
import { useStyles } from './search-box.style';

const SearchBoxView = (props: SearchBoxViewInterface): ReactElement => {
  const { id, onChange, inputRef, onSearchIconClickHandler } = props;
  const classes = useStyles();

  return (
    <>
      <Icon onClick={onSearchIconClickHandler} className={classes.icon} id={id}>
        search
      </Icon>
      <TextField
        autoFocus
        onChange={onChange}
        variant="outlined"
        size="small"
        data-test="menuSearchInput"
        inputRef={inputRef}
        className={classes.inputContainer}
        id="searchInputSidebar"
        InputProps={{
          classes: {
            input: classes.input,
            notchedOutline: classes.notchedOutline,
          },
          autoComplete: 'new-field',
        }}
      />
    </>
  );
};

export default SearchBoxView;
