import { FC, memo, useState } from 'react';
import {
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
} from '../../../core/configProvider';
import { FullContentDialogPopOut } from './full-screen-content.view';

import { PostCardInterface } from './post-card.type';
import PostCardView from './post-card.view';

const PostCardController: FC<PostCardInterface> = memo(props => {
  const { post, isLoading } = props;
  const [showFullContent, setshowFullContent] = useState<boolean>(false);
  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);

  const imageUrl = post?.annimageurl
    ? `${apiUrl}/oauth2/${sessionId}/${apiVersion}${post?.annimageurl}`
    : null;

  /**
   * @function onCloseShowFullContent
   */
  const onCloseShowFullContent = () => {
    setshowFullContent(false);
  };

  /**
   * @function onContinueReadingHandler
   * @param e
   * @returns { void }
   */
  const onContinueReadingHandler = (e: any): void => {
    e.stopPropagation();
    setshowFullContent(true);
  };

  return (
    <>
      {showFullContent && (
        <FullContentDialogPopOut
          post={post}
          imageUrl={imageUrl ?? ''}
          isLoading={isLoading}
          onCloseShowFullContent={onCloseShowFullContent}
        />
      )}
      <PostCardView
        post={post}
        isLoading={isLoading}
        imageUrl={imageUrl ?? ''}
        onContinueReadingHandler={onContinueReadingHandler}
      />
    </>
  );
});

export default PostCardController;
