import { ReactElement } from 'react';

import { useStyles } from './new-label-form.style';
import { FormController } from '../../../../../../form';
import { SimpleForm } from '../../../../../../form/simple-form';
import { staticNewLabelFormFields } from '../../../../../mail-form/mail-form.helper';

const NewLabelFormView = (): ReactElement => {
  const classes = useStyles();
  const colorDefaultValue = '#f05b41';

  return (
    <FormController formName="simpleForm">
      <SimpleForm
        fields={staticNewLabelFormFields}
        defaultValues={{ ColorCode: colorDefaultValue }}
        className={classes.simpleFormContainer}
      />
    </FormController>
  );
};

export default NewLabelFormView;
