import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: 0,
  },

  serviceBottomToolbarClassName: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: 5,
  },
}));
