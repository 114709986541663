import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 16px',
  },

  iconButton: {
    padding: 5,
  },
}));
