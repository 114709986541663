import lodashGet from 'lodash/get';
import { FC, memo } from 'react';

import { isEmpty } from '../../../helper/data-helper';
import { MetaData } from '../../../helper/Types';
import { actorGetActionValue } from '../../../type/actor-setup';
import { QuickEditDialogInterface } from './quick-edit-dialog.type';
import QuickEditDialogView from './quick-edit-dialog.view';

const QuickEditDialogController: FC<QuickEditDialogInterface> = memo(props => {
  const { isOpen, closeDialog, field, resource, id } = props;
  const metaData = actorGetActionValue('metaData', resource)! as unknown as MetaData;
  const computeId = !isEmpty(id) ? id : lodashGet(props, 'match.params.id', null);

  return (
    <QuickEditDialogView
      isOpen={isOpen}
      closeDialog={closeDialog}
      fields={[field]}
      resource={resource}
      metaData={metaData}
      id={computeId}
    />
  );
});

export default QuickEditDialogController;
