import { FC, useState, useEffect, memo } from 'react';

import { ColorInputInterface } from './color-input.type';
import ColorInputView from './color-input.view';
import { isEmpty } from '../../../helper/data-helper';
import { isValidColor } from './color-input.helper';

const ColorInputController: FC<ColorInputInterface> = memo(props => {
  const { value, field, label, inputRef } = props;
  const { name, defaultValue } = field;
  const [color, setColor] = useState<string>('');

  /**
   * Returns a valid hex color code based on data or none
   * @function getColor
   * @param {string} tempColor
   * @returns {string}
   */
  const getColor = (tempColor: string): string => {
    if (isEmpty(value)) {
      tempColor = defaultValue;
    } else {
      tempColor = value;
    }

    // Add # if not exist
    if (tempColor?.indexOf('#') === -1) {
      tempColor = `#${tempColor}`;
    }

    return isValidColor(tempColor) ? tempColor : '';
  };

  useEffect(() => {
    setColor(getColor(value));
  }, [value]);

  return (
    <ColorInputView color={color} tooltip={label} name={name} inputRef={inputRef} />
  );
});

export default ColorInputController;
