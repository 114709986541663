import { ReactElement } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useTranslate } from 'react-admin';
import { TagInputViewInterface } from './tag-input.type';
import { useStyles } from './tag-input.style';
import classNames from 'classnames';
import InputBase from '../../input-base';

const TagInputView = (props: TagInputViewInterface): ReactElement => {
  const {
    source,
    resource,
    resultList,
    preparedValue,
    disabled,
    isLoading,
    components,
    label,
    hint,
    inputMessage,
    handleChange,
    required,
    visibleClass,
    field,
    getRef,
    ...rest
  } = props;

  const classes = useStyles({ messageType: inputMessage?.messageType });
  const translate = useTranslate();
  return (
    <InputBase
      className={`${visibleClass}`}
      label={label}
      hint={hint}
      inputMessage={inputMessage}
      required={required}
      field={field}
    >
      <CreatableSelect
        {...rest}
        isMulti
        source={source}
        isClearable
        classes={classes}
        resource={resource}
        value={preparedValue}
        isDisabled={disabled}
        translate={translate}
        isLoading={isLoading}
        components={components}
        closeMenuOnSelect={false}
        ref={getRef}
        className={classNames(
          classes.select,
          !label ? classes.selectNoLabel : disabled ? classes.disabledDrop : null,
        )}
        onChange={handleChange}
        placeholder={translate('dropdown.placeholder')}
        loadingMessage={() => {
          return translate('ra.page.loading');
        }}
        formatCreateLabel={inputValue => {
          return translate('ra.page.create', { name: inputValue });
        }}
        options={
          resultList
            ? resultList?.map(tagItem => ({
                value: tagItem,
                label: tagItem,
              }))
            : []
        }
      />
    </InputBase>
  );
};

export default TagInputView;
