import { FC, memo, useEffect, useState } from 'react';
import { MailActions, OnOpenReferenceFormParams } from '../../..';
import { defaultMailFormValue } from '../../../mail-section.helper';

import { CheckedMailActionInterface } from './checked-mail-action.type';
import CheckedMailActionView from './checked-mail-action.view';

const CheckedMailActionController: FC<CheckedMailActionInterface> = memo(props => {
  const {
    mailActionsHandler,
    shouldMarkAsRead,
    onMarkUnReadClick,
    onArchiveClick,
    checkedMails,
    onUnArchiveClick,
  } = props;

  const [allArchived, setAllArchived] = useState<boolean>(false);

  useEffect(() => {
    isAllArchived();
  }, [checkedMails]);

  /**
   *@function onReferenceClick
   @returns { void } 
   */
  const onReferenceClick = (): void => {
    mailActionsHandler(MailActions.onOpenReferenceForm, {
      formData: defaultMailFormValue,
    } as OnOpenReferenceFormParams);
  };
  /**
   * @function isAllArchived
   * @returns { void }
   */
  const isAllArchived = (): void => {
    if (checkedMails) {
      setAllArchived(checkedMails.every(mail => mail.isarchived));
    }
  };

  return (
    <CheckedMailActionView
      mailActionsHandler={mailActionsHandler}
      shouldMarkAsRead={shouldMarkAsRead}
      onMarkUnReadClick={onMarkUnReadClick}
      onArchiveClick={onArchiveClick}
      onUnArchiveClick={onUnArchiveClick}
      onReferenceClick={onReferenceClick}
      allArchived={allArchived}
    />
  );
});

export default CheckedMailActionController;
