import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  parentButton: {
    background: 'transparent !important',
    color: `${theme.palette.secondary.main} !important`,
  },
  link: {
    color: theme.palette.secondary.contrastText,
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.secondary.contrastText,
    },
  },
}));
