import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';

import { CustomTheme } from '../../../core/themeProvider';
import { ThemeProps } from './custom-nav.type';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      flexGrow: 0,
      flexBasis: ({ drawerWidth }: ThemeProps) => drawerWidth,
    },
    transition: 'all 0.2s ease !important',
  },

  drawerPaper: {
    width: ({ drawerWidth }: ThemeProps) => drawerWidth,
    backgroundColor: grey[50],
    transition: 'all 0.2s ease !important',
  },
}));
