import { ReactElement } from 'react';
import clsx from 'classnames';
import { AppContentInterface } from './app-content.type';
import { useStyles } from './app-content.style';

const AppContentView = (props: AppContentInterface): ReactElement => {
  const {
    isDetailView = false,
    fullView = false,
    style = {},
    children,
    customClass,
  } = props;
  const classes = useStyles({ isDetailView, fullView });

  return (
    <div className={clsx(customClass, classes.appsContentContainer)} style={style}>
      {children}
    </div>
  );
};

export default AppContentView;
