import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { TemplateActionViewInterface } from './template-action.type';
import { useStyles } from './template-action.style';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { DialogTitle } from '@material-ui/core';
import LoadingBox from '../../../loading-box';
import { MessageTemplateData } from '.';
import { isEmptyObject } from '../../../../helper/data-helper';

const TemplateActionView = (props: TemplateActionViewInterface): ReactElement => {
  const {
    onViewMoreOpen,
    isMoreIcon,
    onViewMoreClose,
    isTemplateLoading,
    templateData,
    onSelectTemplate,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box>
      <Tooltip title={translate('mail.template')}>
        <Button onClick={onViewMoreOpen} variant="outlined">
          {translate('mail.template')}
        </Button>
      </Tooltip>

      <Dialog
        open={Boolean(isMoreIcon)}
        onClose={onViewMoreClose}
        className={classes.dialogContainer}
      >
        <DialogTitle>{translate('mail.template')}</DialogTitle>
        <Box className={classes.menuContainer}>
          {isTemplateLoading ? (
            <LoadingBox size={12} />
          ) : isEmptyObject(templateData) ? (
            <Box
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={1}
              width={1}
              textAlign="center"
              color="text.secondary"
            >
              {translate('mail.noTemplateData')}
            </Box>
          ) : (
            templateData?.map((item: MessageTemplateData) => (
              <MenuItem
                key={item.templates_id}
                onClick={() => onSelectTemplate(item)}
              >
                {item.title}
              </MenuItem>
            ))
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default TemplateActionView;
