import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxHeight: '98%',
    overflow: 'auto',
    marginTop: '1.5rem',
    borderTop: `1px solid ${theme.palette.primary.appBorderInput}`,
  },

  titleContainer: {
    width: '100%',
    textAlign: 'center',
    padding: '10px 0',
    background: theme.palette.background.default,
    color: theme.palette.grey[700],
  },
}));
