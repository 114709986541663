import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  paginationRoot: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 8,
    paddingTop: 8,
    borderTop: '1px solid',
    borderColor: grey[300],
  },
}));
