import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';
import { ListFooterInterface } from './list-footer.type';
import { useStyles } from './list-footer.style';
import LoadingBox from '../../loading-box';

const ListFooterView = (props: ListFooterInterface): ReactElement => {
  const { loading, footerText } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return loading ? (
    <Box className={classes.listFooterLoaderRoot}>
      <LoadingBox size={16} />
      <Box component="span" ml={2}>
        {translate('dropdown.loading')} ...
      </Box>
    </Box>
  ) : (
    <Box className={classes.listFooterRoot}>
      <Box component="p">{footerText}</Box>
    </Box>
  );
};

export default ListFooterView;
