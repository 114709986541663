import { FC, memo } from 'react';
import { useTranslate } from 'react-admin';
import { ListEmptyResultInterface } from './list-empty-result.type';
import ListEmptyResultView from './list-empty-result.view';

const ListEmptyResultController: FC<ListEmptyResultInterface> = memo(props => {
  const translate = useTranslate();
  const {
    loader,
    placeholder,
    loading,
    title = `${translate('general.noRecordFound')}`,
    actionTitle,
    content,
    onClick,
  } = props;

  return (
    <ListEmptyResultView
      loader={loader}
      placeholder={placeholder}
      loading={loading}
      title={title}
      actionTitle={actionTitle}
      content={content}
      onClick={onClick}
    />
  );
});

export default ListEmptyResultController;
