import { FC, memo, useState } from 'react';

import {
  actorDispatch,
  actorSetActionValue,
  actorGetActionValue,
} from '../../type/actor-setup';
import { QuickEditButtonInterface } from './quick-edit-button.type';
import QuickEditButtonView from './quick-edit-button.view';

const QuickEditButtonController: FC<QuickEditButtonInterface> = memo(props => {
  const { className, field, resource, record } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const currentResource = actorGetActionValue('resources')!.current;

  /**
   * open dialog and set resource and allFields
   * @function openDialog
   * @return {void}
   */
  const openDialog = (): void => {
    actorDispatch('allFields', [field], {
      path: `${currentResource.value}.${currentResource.type}`,
      callerScopeName: 'QuickEditButtonController => openDialog',
    });
    setIsDialogOpen(true);
  };

  /**
   * close dialog and remove allFields
   * @function closeDialog
   * @return {void}
   */
  const closeDialog = (): void => {
    setIsDialogOpen(false);
    actorSetActionValue('allFields', [], {
      path: `${currentResource.value}.${currentResource.type}`,
      replaceAll: true,
      callerScopeName: 'QuickEditButtonController => closeDialog',
    });
  };
  return (
    <QuickEditButtonView
      field={field}
      openDialog={openDialog}
      closeDialog={closeDialog}
      resource={resource}
      isDialogOpen={isDialogOpen}
      className={className}
      id={record.id}
    />
  );
});

export default QuickEditButtonController;
