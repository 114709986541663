import lodashGet from 'lodash/get';
import { FC, memo, useEffect, useState } from 'react';
import { useLocale } from 'react-admin';

import { ColorFieldInterface } from './color-field.type';
import ColorFieldView from './color-field.view';
import { isEmptyObject, isEmpty } from '../../../helper/data-helper';
import { isValidColor } from '../../dynamic-input/color-input/color-input.helper';

const ColorFieldController: FC<ColorFieldInterface> = memo(props => {
  const { field, data } = props;
  const { caption, defaultValue, isReport, id, relatedName, name } = field;

  const locale = useLocale();
  const [color, setColor] = useState<string>('');
  const tooltip = lodashGet(field, ['translatedCaption', locale], caption);

  /**
   * Returns a valid hex color code based on data or none
   * @function getColor
   * @returns {string}
   */
  const getColor = (): string => {
    let value = defaultValue;

    const fieldName = isReport ? id : relatedName;

    if (!isEmptyObject(data) && !isEmpty(data[fieldName])) {
      value = data[fieldName];
    }

    // Add # if not exist
    value = value?.indexOf('#') === -1 ? `#${value}` : value;

    return isValidColor(value) ? value : '';
  };

  useEffect(() => {
    setColor(getColor());
  }, [data]);

  return <ColorFieldView color={color} tooltip={tooltip} name={name} />;
});

export default ColorFieldController;
