import { FC, useState, memo } from 'react';

import { MoreDetailInterface } from './more-detail.type';
import MoreDetailView from './more-detail.view';

const MoreDetailController: FC<MoreDetailInterface> = memo(props => {
  const { mail, isMainMail } = props;
  const [isShowMoreDetailOpen, setIsShowMoreDetailOpen] = useState<
    (EventTarget & HTMLElement) | null
  >(null);

  /**
   * @function onMoreDetailClick
   * @param { React.MouseEvent<HTMLButtonElement, MouseEvent> } event
   * @returns { void }
   */
  const onMoreDetailOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.stopPropagation();

    setIsShowMoreDetailOpen(event.currentTarget);
  };

  /**
   * @function onMoreDetailClose
   * @returns { void }
   */
  const onMoreDetailClose = (): void => {
    setIsShowMoreDetailOpen(null);
  };

  return (
    <MoreDetailView
      mail={mail}
      isMainMail={isMainMail}
      onMoreDetailOpen={onMoreDetailOpen}
      onMoreDetailClose={onMoreDetailClose}
      isShowMoreDetailOpen={isShowMoreDetailOpen}
    />
  );
});

export default MoreDetailController;
