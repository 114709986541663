import { ReactElement } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import momentJalaali from 'moment-jalaali';
import ListItem from '@material-ui/core/ListItem';
import { Box, IconButton, Avatar, Tooltip } from '@material-ui/core';

import {
  gregorianDateTimeFormat,
  jalaliDateTimeFormat,
} from '../../../../../../helper/CalendarMetaHelper';
import {
  getValue,
  SESSION_ID,
  API_URL,
  API_VERSION,
} from '../../../../../../core/configProvider';
import { ReferenceItemViewInterface } from './reference-item.type';
import { useStyles } from './reference-item.style';
import { isEmpty } from '../../../../../../helper/data-helper';
import RecallIcon from '../../../../../../icon/RecallIcon';

const ReferenceItemView = (props: ReferenceItemViewInterface): ReactElement => {
  const { item, onRecallHandler } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();
  momentJalaali.locale(locale);

  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);

  return (
    <ListItem dense button key={item.messagereferences_id} className={classes.root}>
      <Box component="p" width={0.15} className={classes.box}>
        {locale === 'fa'
          ? momentJalaali(item?.createdate).format(jalaliDateTimeFormat)
          : momentJalaali(item?.createdate).format(gregorianDateTimeFormat)}
      </Box>

      <Box component="span" width={0.2} className={classes.userContainer}>
        <Avatar
          src={`${apiUrl}/oauth2/${sessionId}/${apiVersion}${item?.frompersonimage}`}
          alt={item?.fromcontactinfo}
          className={classes.avatar}
        />
        <Tooltip title={item?.fromcontactinfo}>
          <p className={classes.truncate}>{item?.fromcontactinfo}</p>
        </Tooltip>
      </Box>
      <Box component="span" width={0.2} className={classes.userContainer}>
        <Avatar
          src={`${apiUrl}/oauth2/${sessionId}/${apiVersion}${item?.topersonimage}`}
          alt={item?.tocontactinfo}
          className={classes.avatar}
        />
        <Tooltip title={item?.tocontactinfo}>
          <p className={classes.truncate}>{item?.tocontactinfo}</p>
        </Tooltip>
      </Box>
      <Tooltip title={item?.messageparaph}>
        <Box component="p" width={0.2} className={classes.truncate}>
          {item?.messageparaph}
        </Box>
      </Tooltip>
      {!isEmpty(item.seendate) ? (
        <Box component="p" width={0.15} className={classes.box}>
          {locale === 'fa'
            ? momentJalaali(item?.seendate).format(jalaliDateTimeFormat)
            : momentJalaali(item?.seendate).format(gregorianDateTimeFormat)}
        </Box>
      ) : (
        <Box component="p" width={0.15}>
          {translate('mail.notSeen')}
        </Box>
      )}
      <Box component="p" width={0.05} className={classes.box}>
        {item?.contactkindstitle}
      </Box>

      <Box component="span" width={0.02}>
        <IconButton onClick={onRecallHandler} style={{ color: '#FF5350' }}>
          <RecallIcon />
        </IconButton>
      </Box>
    </ListItem>
  );
};

export default ReferenceItemView;
