import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dialog: {
    height: 700,
  },

  list: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  ListCard: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  childrenContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > div.MuiTabs-root': {
      zIndex: 2,
    },
  },

  dialogAction: {
    flexDirection: 'row-reverse',
    margin: 0,
    padding: 10,
    backgroundColor: '#f5f5f5',
  },

  searchContainer: {
    padding: '8px 20px',
  },

  dialogTitle: {
    zIndex: 2,
  },

  input: {},

  icon: {
    fontSize: 15,
  },

  topToolbar: {
    width: '100%',
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey',
  },

  toolbarIconsAndSearchContainer: {
    display: 'flex',
    flex: 1,
    height: 60,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));
