import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

function ImportantIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.81334 12.5818C10.0621 12.2341 10.0622 11.7667 9.81373 11.4188L5.94319 6H15.5136L19.7737 12.0002L15.5234 17.99H5.94481L9.81334 12.5818Z"
        stroke="#666666"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default withStyles(styles)(ImportantIcon);
