import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Box, Tooltip, IconButton, Menu } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/ArrowDropDown';

import { MoreDetailViewInterface } from './more-detail.type';
import { useStyles } from './more-detail.style';
import MoreDetailBodyView from './more-detail-body.view';

const MoreDetailView = (props: MoreDetailViewInterface): ReactElement => {
  const {
    mail,
    isMainMail,
    onMoreDetailOpen,
    onMoreDetailClose,
    isShowMoreDetailOpen,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box>
      <Tooltip title={translate('general.more')}>
        <IconButton
          onClick={onMoreDetailOpen}
          data-test="mail-detail-more-info-icon"
        >
          <MoreIcon />
        </IconButton>
      </Tooltip>

      {isShowMoreDetailOpen && (
        <Menu
          anchorEl={isShowMoreDetailOpen}
          open={Boolean(isShowMoreDetailOpen)}
          onClose={onMoreDetailClose}
          className={classes.menuContainer}
          data-test="more-detail-container"
        >
          <MoreDetailBodyView mail={mail} isMainMail={isMainMail} />
        </Menu>
      )}
    </Box>
  );
};

export default MoreDetailView;
