import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

function InboxIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1797 11V17"
        stroke="inherit"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.1797 8V9"
        stroke="#666666"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 13.8203L16.1801 17.0003L19.36 13.8203"
        stroke="#666666"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 6H12.58C12.3746 6.00887 12.1717 5.95289 12 5.84C11.8239 5.7262 11.6904 5.55748 11.62 5.36C11.485 4.96325 11.2292 4.61874 10.8884 4.37482C10.5477 4.1309 10.139 3.99982 9.71997 4H4C3.47046 4.00289 2.96344 4.21453 2.58899 4.58897C2.21454 4.96342 2.00289 5.47046 2 6V18C2.00289 18.5295 2.21454 19.0366 2.58899 19.411C2.96344 19.7855 3.47046 19.9971 4 20H20C20.5295 19.9971 21.0366 19.7855 21.411 19.411C21.7855 19.0366 21.9971 18.5295 22 18V8C21.9971 7.47046 21.7855 6.96342 21.411 6.58897C21.0366 6.21453 20.5295 6.00289 20 6V6Z"
        stroke="#666666"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M2 16.98V11C2.00289 10.4705 2.21454 9.96342 2.58899 9.58897C2.96344 9.21453 3.47046 9.00289 4 9H10.5601"
        stroke="#666666"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default withStyles(styles)(InboxIcon);
