import { ReactElement } from 'react';
import { IconInputViewInterface } from './icon-input.type';
import { useStyles } from './icon-input.style';
import { Tooltip } from '@material-ui/core';

const IconInputView = (props: IconInputViewInterface): ReactElement => {
  const { name, tooltip, icon, inputRef } = props;
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <i
        ref={inputRef}
        data-test-icon-input={name}
        className={`fa fa-${icon} ${classes.item}`}
      ></i>
    </Tooltip>
  );
};

export default IconInputView;
