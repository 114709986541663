import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    padding: '8px 16px',
    display: 'block !important',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    justifyContent: 'space-between',
  },
  item: { display: 'inline-block' },
  buttonLink: {
    color: 'white',
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
}));
