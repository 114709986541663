import lodashGet from 'lodash/get';
import { isEmpty } from '../../../helper/data-helper';
import { FieldType } from '../../../helper/Types';

/**
 * A) If the field ID was zero, it means that the field is not mapped and must be active in all cases.
 * B) Fields whose ID is non-zero are mapped fields and the following states should be checked for them:
 *    1- If the field is not mandatory, it must be disabled.
 *    2- If the field was mandatory and did not have a value, it must be active.
 *    3- If the field was mandatory and had a value, it must be disabled.
 * @function computeDisableField
 * @param {FieldType} field
 * @param {object} serviceParams
 * @returns {boolean}
 */
export const computeDisableField = (
  field: FieldType,
  serviceParams: Object,
): boolean => {
  const { id, required, relatedParameterName } = field;
  if (id === 0) {
    // A
    return false;
  } else {
    // B
    if (!required) {
      // B-1
      return true;
    } else if (isEmpty(lodashGet(serviceParams, relatedParameterName))) {
      // B-2
      return false;
    } else {
      // B-3
      return true;
    }
  }
};
