import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  dialog: {
    overflow: 'visible',
  },
  simpleForm: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 300,
    justifyContent: 'space-between',
    '& > div:first-child ': {
      display: 'flex',
      '& > div': {
        width: '100%',
      },
    },
  },
}));
