import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { MenuItem } from '@material-ui/core';

import { MailSidebarContextMenuViewInterface } from './mail-sidebar-context-menu.type';

const MailSidebarContextMenuView = (
  props: MailSidebarContextMenuViewInterface,
): ReactElement => {
  const { handleDeleteFolder } = props;
  const translate = useTranslate();

  return (
    <MenuItem
      onClick={handleDeleteFolder}
      data-test="mail-sidebar-item-delete-button"
    >
      {translate('general.delete')}
    </MenuItem>
  );
};

export default MailSidebarContextMenuView;
