import { FC, memo } from 'react';

import { SortableItemInterface } from './sortable-item.type';
import SortableItemView from './sortable-item.view';

const SortableItemController: FC<SortableItemInterface> = memo(props => {
  const { field, locale, checked, onChange, index, sortDisabled } = props;

  return (
    <SortableItemView
      field={field}
      locale={locale}
      checked={checked}
      onChange={onChange}
      index={index}
      sortDisabled={sortDisabled}
    />
  );
});

export default SortableItemController;
