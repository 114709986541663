import { ReactElement } from 'react';
import { FileInputViewInterface } from './file-input.type';
import { useStyles } from './file-input.style';
import { isEmpty } from '../../../helper/data-helper';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { IconButton, Icon, Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { default as InputBaseLabel } from '../../input-base';
import LoadingBox from '../../loading-box';

const FileInputView = (props: FileInputViewInterface): ReactElement => {
  const {
    dropRef,
    disabled,
    label,
    hint,
    resource,
    required,
    name,
    onFileChange,
    inputFileRef,
    handleClick,
    dragging,
    isImage,
    imageSrc,
    fileName,
    handleRemoveFile,
    inputMessage,
    fileSize,
    loading,
    calcHeight,
    visibleClass,
  } = props;
  const translate = useTranslate();
  const classes = useStyles({ calcHeight, messageType: inputMessage?.messageType });

  return (
    <InputBaseLabel
      className={`${visibleClass}`}
      label={label}
      hint={hint}
      required={required}
      inputMessage={inputMessage}
    >
      <fieldset
        ref={dropRef}
        className={`${classes.root} ${
          inputMessage?.message ? classes.fieldsetMessage : ''
        } ${disabled && classes.disabled}`}
        data-test-field-name={`${resource}/${name}`}
        disabled={disabled}
      >
        <input
          name={name}
          id={name}
          onChange={onFileChange}
          type="file"
          className={classes.customFileInput}
          disabled={disabled}
          required={required}
          data-test-has-error={!!inputMessage?.message}
          ref={inputFileRef}
        />
        <div className={classes.wrap}>
          {!isImage && (
            <div onClick={handleClick} className={classes.uploadArea}>
              {dragging ? (
                <div className={classes.uploadBox} />
              ) : (
                <InsertDriveFileIcon style={{ color: 'rgba(0, 0, 0, 0.3)' }} />
              )}
            </div>
          )}
          <div className={classes.inputFileValueName} onClick={handleClick}>
            {isImage ? (
              <span
                className={classes.previewImage}
                style={{
                  backgroundImage: `url(${imageSrc})`,
                }}
                title={fileName ? fileName : ''}
              />
            ) : !isImage && fileName ? (
              <div className={classes.fileName}>
                <Typography
                  noWrap
                  title={fileName ? fileName : ''}
                  variant="caption"
                >
                  {fileName}
                </Typography>
                &nbsp;
                <Typography
                  variant="caption"
                  title={fileSize ? fileSize : ''}
                  className={classes.fileSizeSpan}
                >
                  {fileSize && fileSize !== 'NaN undefined'
                    ? `  ${fileSize}   `
                    : null}
                </Typography>
              </div>
            ) : (
              translate('ra.input.selectFile')
            )}
          </div>
          {loading ? (
            <LoadingBox size={15} />
          ) : !isEmpty(imageSrc) || !isEmpty(fileName) ? (
            <IconButton
              color="primary"
              size="small"
              onClick={handleRemoveFile}
              data-test-info={`${name}-removeFile`}
              disabled={disabled}
            >
              <Icon fontSize="small">clear</Icon>
            </IconButton>
          ) : null}
        </div>
      </fieldset>
    </InputBaseLabel>
  );
};

export default FileInputView;
