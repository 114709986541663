import PropTypes from 'prop-types';
import invariant from 'invariant';

const localePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.func]);

function _format(localizer, formatter, value, format, culture) {
  let result =
    typeof format === 'function'
      ? format(value, culture, localizer)
      : formatter.call(localizer, value, format, culture);

  invariant(
    result == null || typeof result === 'string',
    '`localizer format(..)` must return a string, null, or undefined',
  );

  return result;
}

export class DateLocalizer {
  constructor(spec) {
    invariant(
      typeof spec.format === 'function',
      'date localizer `format(..)` must be a function',
    );
    invariant(
      typeof spec.firstOfWeek === 'function',
      'date localizer `firstOfWeek(..)` must be a function',
    );

    this.propType = spec.propType || localePropType;

    this.startOfWeek = spec.firstOfWeek;
    this.formats = spec.formats;
    this.format = (...args) => _format(this, spec.format, ...args);

    this.merge = spec.merge;
    this.inRange = spec.inRange;
    this.lt = spec.lt;
    this.lte = spec.lte;
    this.gt = spec.gt;
    this.gte = spec.gte;
    this.eq = spec.eq;
    this.neq = spec.neq;
    this.startOf = spec.startOf;
    this.endOf = spec.endOf;
    this.add = spec.add;
    this.range = spec.range;
    this.diff = spec.diff;
    this.ceil = spec.ceil;
    this.min = spec.min;
    this.max = spec.max;
    this.minutes = spec.minutes;
    this.firstVisibleDay = spec.firstVisibleDay;
    this.lastVisibleDay = spec.lastVisibleDay;
    this.visibleDays = spec.visibleDays;
    this.getSlotDate = spec.getSlotDate;
    this.getTotalMin = spec.getTotalMin;
    this.continuesPrior = spec.continuesPrior;
    this.continuesAfter = spec.continuesAfter;
    this.sortEvents = spec.sortEvents;
    this.inEventRange = spec.inEventRange;
    this.isSameDate = spec.isSameDate;
    this.segmentOffset = spec.browserTZOffset ? spec.browserTZOffset() : 0;
    this.getMinutesFromMidnight = spec.getMinutesFromMidnight;
    this.startAndEndAreDateOnly = spec.startAndEndAreDateOnly;
  }
}
