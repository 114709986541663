import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'center',
    marginBottom: 8,
  },
  label: {
    flex: 1,
    color: '#878787',
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: '4px 0px',
    flexFlow: 'wrap',
  },
  navigateButton: {
    borderRadius: 4,
    background: '#C7E1F2',
    color: '#2C6288',
    padding: 0,
    minWidth: 28,
    height: 28,
  },
  navigateButtonToday: {
    borderRadius: 4,
    background: '#C7E1F2',
    color: '#2C6288',
    padding: 0,
    minWidth: 50,
    height: 28,
    margin: '0px 4px',
  },
  viewButton: {
    height: '100%',
    minWidth: 40,
    padding: 0,
  },
  viewButtons: {
    display: 'flex',
    border: '1px solid #C4C4C4',
    borderRadius: 4,
    height: 28,
    margin: '4px 0px',
  },
  buttonActions: {
    display: 'flex',
    margin: '4px 0px',
  },
  checkedButton: {
    background: '#F2F2F5',
  },
}));
