import { ReactElement } from 'react';
import { AppListInterface } from './app-list.type';
import { ListContainer } from './list-container';
import { ListFooter } from './list-footer';

const AppListView = (props: AppListInterface): ReactElement => {
  const { footerProps, renderRow, data, loading, className, ListEmptyComponent } =
    props;

  return (
    <ListContainer
      className={className}
      renderRow={renderRow}
      data={data}
      loading={loading}
      ListEmptyComponent={ListEmptyComponent}
      ListFooterComponent={
        footerProps ? (
          <ListFooter
            loading={footerProps.loading}
            footerText={footerProps.footerText}
          />
        ) : null
      }
    />
  );
};

export default AppListView;
