import { FC, useCallback, useState, useRef, memo } from 'react';
import { ValidationAction } from '../../../../helper/Types';

import { ButtonGroupInterface } from './button-group.type';
import ButtonGroupView from './button-group.view';

const ButtonGroupController: FC<ButtonGroupInterface> = memo(props => {
  const {
    actions,
    caption,
    iconName,
    handleRunService,
    visibleClass,
    disabled,
    customTestAttribute,
  } = props;

  const buttonRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * @function handleToggle
   * @returns {void}
   */
  const handleToggle = useCallback((): void => {
    setIsOpen(prevOpen => !prevOpen);
  }, [setIsOpen]);

  /**
   * @function handleClose
   * @param { React.MouseEvent<Document, MouseEvent> } event
   * @returns { void }
   */
  const handleClose = (event: React.MouseEvent<Document, MouseEvent>): void => {
    if (
      buttonRef.current &&
      buttonRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setIsOpen(false);
  };

  /**
   * @function handleMenuItemClick
   * @param { ValidationAction } action
   * @returns { void }
   */
  const handleMenuItemClick = (action: ValidationAction): void => {
    handleRunService?.(action);
    setIsOpen(false);
  };

  return (
    <ButtonGroupView
      caption={caption}
      actions={actions}
      isOpen={isOpen}
      handleToggle={handleToggle}
      handleClose={handleClose}
      buttonRef={buttonRef}
      iconName={iconName}
      handleMenuItemClick={handleMenuItemClick}
      visibleClass={visibleClass}
      disabled={disabled}
      customTestAttribute={customTestAttribute}
    />
  );
});

export default ButtonGroupController;
