import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import ColorBox from 'devextreme-react/color-box';

import InputBase from '../../input-base';
import { ColorPickerInputViewInterface } from './color-picker-input.type';

const ColorPickerInputView = (
  props: ColorPickerInputViewInterface,
): ReactElement => {
  const {
    getRef,
    field,
    value,
    inputMessage,
    label,
    disabled,
    handleChange,
    handleBlur,
    visibleClass,
    inputContainerClass,
  } = props;

  const { name, required } = field;

  const translate = useTranslate();

  return (
    <InputBase
      label={label}
      required={required}
      inputMessage={inputMessage}
      className={`${visibleClass} ${inputContainerClass}`}
    >
      <ColorBox
        ref={getRef}
        defaultValue="#f05b41"
        name={name}
        applyButtonText={translate('general.apply')}
        cancelButtonText={translate('general.decline')}
        onValueChanged={handleChange}
        disabled={disabled}
        style={{ padding: 5 }}
      />
    </InputBase>
  );
};

export default ColorPickerInputView;
