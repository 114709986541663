import { Icon, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { ReactElement } from 'react';

import { QuickEditDialog } from './quick-edit-dialog';
import { QuickEditButtonViewInterface } from './quick-edit-button.type';

const QuickEditButtonView = (props: QuickEditButtonViewInterface): ReactElement => {
  const { resource, openDialog, className, field, isDialogOpen, closeDialog, id } =
    props;

  const { name } = field;

  return (
    <>
      <IconButton
        data-test-quick-button-icon={`${resource}/${name}`}
        color="default"
        size="small"
        onClick={openDialog}
        className={classNames(className, 'quickEditButton')}
        data-test-quick-edit-button={name}
        data-style-quick-edit-button="edit"
      >
        <Icon style={{ fontSize: 18 }}>edit</Icon>
      </IconButton>
      <QuickEditDialog
        isOpen={isDialogOpen}
        closeDialog={closeDialog}
        resource={resource}
        field={field}
        id={id}
      />
    </>
  );
};

export default QuickEditButtonView;
