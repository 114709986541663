import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: '2px',
    fontSize: '13px',
    color: 'hsl(0, 0%, 60%)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      margin: 5,
    },
  },

  IconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      padding: 14,
      margin: 0,
    },
  },
}));
