import { ReactElement } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { AttachedFile, MailDetailBodyViewInterface } from './mail-detail-body.type';
import {
  Checkbox,
  IconButton,
  Button,
  Chip,
  Divider,
  Hidden,
} from '@material-ui/core';
import momentJalaali from 'moment-jalaali';
import clsx from 'classnames';
import { useStyles } from './mail-detail-body.style';
import Avatar from '@material-ui/core/Avatar/index';
import ReplyIcon from '@material-ui/icons/Reply';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ImportantIcon from '@material-ui/icons/LabelImportant';
import Tooltip from '@material-ui/core/Tooltip/index';
import Box from '@material-ui/core/Box';
import { MailDetailSkeleton } from '../../../skeleton/mail-detail-skeleton.view';
import { RichTextEditorField } from '../../../rich-text-editor-field';
import { removePageBreakFromHtmlString } from '../../../rich-text-editor-field/rich-text-editor-field.helper';
import {
  jalaliDateTimeFormat,
  gregorianDateTimeFormat,
} from '../../../../helper/CalendarMetaHelper';
import {
  API_URL,
  getValue,
  API_VERSION,
  SESSION_ID,
} from '../../../../core/configProvider';
import { MailLabelType } from '../..';
import { isEmpty } from '../../../../helper/data-helper';
import ForwardIcon from '@material-ui/icons/CallMade';

const MailDetailBodyView = (props: MailDetailBodyViewInterface): ReactElement => {
  const {
    selectedMail,
    selectedMailLoading,
    onReplyToMail,
    onReplyAllMail,
    onImportantClick,
    onStarClick,
    onReferenceMail,
    onForwardMailHandler,
  } = props;
  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();
  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);

  momentJalaali.locale(locale);

  if (!selectedMail || selectedMailLoading) {
    return <MailDetailSkeleton />;
  }

  const parsedAttachedFiles: AttachedFile[] = JSON.parse(selectedMail.attachfiles);
  const currentLabels: MailLabelType[] =
    JSON.parse(selectedMail.labelsarray) ?? null;

  return (
    <Box className={classes.container} px={6} py={2}>
      <Box
        color="primary.main"
        fontWeight="light"
        fontSize={14}
        ml={10}
        lineHeight={'30px'}
      >
        {selectedMail?.messageparaph}
      </Box>
      {selectedMail ? (
        <Box color="text.secondary">
          <Box
            mb={{ xs: 2, sm: 3 }}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Box color="text.primary" component="h4" fontWeight={700}>
              {selectedMail.subject ? selectedMail.subject : null}
            </Box>

            <Box mb={0.8} component="span" color="text.disabled">
              <Checkbox
                checked={Boolean(selectedMail.isimportant)}
                onChange={() =>
                  onImportantClick(
                    [selectedMail as any],
                    selectedMail.isimportant ? false : true,
                  )
                }
                icon={
                  <ImportantIcon
                    style={{ color: '#fff', stroke: '#000', fontSize: '18px' }}
                  />
                }
                checkedIcon={
                  <ImportantIcon style={{ color: '#D4AF37', fontSize: '18px' }} />
                }
              />
            </Box>

            {currentLabels && (
              <Box
                display="flex"
                alignItems="center"
                mb={0.8}
                ml={1}
                component="span"
              >
                {currentLabels?.map((label: MailLabelType) => (
                  <Box ml={1} key={label.lables_id} component="span">
                    <Tooltip title={label.lablestitle} placement="top">
                      <Box
                        height={12}
                        width={12}
                        component="span"
                        borderRadius="50%"
                        display="block"
                        bgcolor={label.colorcode}
                      />
                    </Tooltip>
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          <Box
            mb={{ xs: 3, md: 2 }}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={`${apiUrl}/oauth2/${sessionId}/${apiVersion}${selectedMail.personimage}`}
                alt={selectedMail.contactinfo}
                className={classes.avatar}
              />
              <Box ml={2}>
                <Box color="primary.main" fontWeight="bold" fontSize={14}>
                  {selectedMail.contactinfo}
                </Box>
              </Box>
            </Box>

            <Box
              ml={{ xs: 0, md: 'auto' }}
              mt={{ xs: 3, md: 0 }}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Box fontSize={12}>
                {locale === 'fa'
                  ? momentJalaali(selectedMail.createdate).format(
                      jalaliDateTimeFormat,
                    )
                  : momentJalaali(selectedMail.createdate).format(
                      gregorianDateTimeFormat,
                    )}
              </Box>

              <Box ml={{ sm: 'auto' }} display="flex" alignItems="center">
                <Tooltip title={translate('mail.reply')}>
                  <IconButton onClick={onReplyToMail}>
                    <ReplyIcon style={{ fontSize: '18px' }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title={translate('mail.replyAll')}>
                  <IconButton onClick={onReplyAllMail}>
                    <ReplyAllIcon style={{ fontSize: '18px' }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title={translate('mail.toStar')}>
                  <Box component="span" color="text.secondary" ml={0.5}>
                    <Checkbox
                      icon={<StarBorderIcon style={{ fontSize: '18px' }} />}
                      checkedIcon={
                        <StarIcon style={{ color: '#D4AF37', fontSize: '18px' }} />
                      }
                      checked={selectedMail.isstared}
                      onChange={() =>
                        onStarClick(
                          [selectedMail as any],
                          selectedMail.isstared ? false : true,
                        )
                      }
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>

          <Box mb={5}>
            {!isEmpty(selectedMail.messagebody) && (
              <Box
                className={classes.editorContainer}
                component="p"
                color="text.secondary"
                fontSize={{ xs: 14, xl: 16 }}
                mb={{ xs: 3, xl: 5 }}
              >
                <RichTextEditorField
                  externalValue={removePageBreakFromHtmlString(
                    selectedMail.messagebody,
                  )}
                  isPrintMode
                />
              </Box>
            )}

            {Boolean(selectedMail.hasattachment) && (
              <>
                <Divider />
                <Box my={3} display="flex" alignItems="center" width={1}>
                  <Tooltip title={translate('mail.attachedFiles')}>
                    <AttachFileIcon />
                  </Tooltip>
                  {parsedAttachedFiles?.map((file: AttachedFile) => (
                    <Box mx={1}>
                      <Chip
                        label={file.realFileName}
                        component="a"
                        href={`${apiUrl}/${file.filePath}`}
                        download={`${apiUrl}/${file.filePath}`}
                        target="_blank"
                        variant="outlined"
                        style={{ borderRadius: '8px' }}
                        clickable
                      />
                    </Box>
                  ))}
                </Box>
              </>
            )}
            {/* @ts-ignore */}
            <Hidden smDown>
              <Box ml={{ sm: 'auto' }} mt={4} display="flex">
                <Tooltip title={translate('mail.toReference')}>
                  <Button
                    onClick={onReferenceMail}
                    variant="contained"
                    color="secondary"
                    className={clsx(classes.btnRoot, classes.referenceBtn)}
                  >
                    {translate('mail.toReference')}
                  </Button>
                </Tooltip>

                <Tooltip title={translate('mail.reply')}>
                  <Button
                    onClick={onReplyToMail}
                    variant="outlined"
                    endIcon={<ReplyIcon />}
                    style={{ marginRight: '1rem' }}
                  >
                    {translate('mail.reply')}
                  </Button>
                </Tooltip>

                <Tooltip title={translate('mail.replyAll')}>
                  <Button
                    onClick={onReplyAllMail}
                    variant="outlined"
                    endIcon={<ReplyAllIcon />}
                    style={{ marginRight: '1rem' }}
                  >
                    {translate('mail.replyAll')}
                  </Button>
                </Tooltip>
                <Tooltip title={translate('mail.forward')}>
                  <Button
                    onClick={onForwardMailHandler}
                    variant="outlined"
                    endIcon={<ForwardIcon />}
                    style={{ marginRight: '1rem' }}
                    data-test="mail-detail-forward-button"
                  >
                    {translate('mail.forward')}
                  </Button>
                </Tooltip>
              </Box>
            </Hidden>
            {/* @ts-ignore */}
            <Hidden mdUp>
              <Tooltip title={translate('mail.toReference')}>
                <Button
                  onClick={onReferenceMail}
                  variant="contained"
                  color="secondary"
                  className={clsx(classes.btnRoot, classes.referenceBtn)}
                >
                  {translate('mail.toReference')}
                </Button>
              </Tooltip>
            </Hidden>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default MailDetailBodyView;
