import { FC, useState, memo, useRef, useEffect } from 'react';
import { actorSetActionValue, FormKeyMode } from '../../type/actor-setup';

import { AppSearchbarInterface } from './app-searchbar.type';
import AppSearchbarView from './app-searchbar.view';

const AppSearchbarController: FC<AppSearchbarInterface> = memo(props => {
  const { onChange, placeholder, className, ...rest } = props;

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  /**
   * @function onClick
   * @returns { void }
   */
  const onClick = (): void => {
    setIsInputFocused(true);
    searchInputRef?.current?.focus();
  };

  useEffect(() => {
    actorSetActionValue('inputsRef', searchInputRef, {
      path: `mail.${FormKeyMode.ROOT}.headerSearchInput`,
    });
  }, []);

  /**
   * when nput search cloese is empty
   * @function onBlur
   * @returns { void }
   */
  const onBlur = (): void => {
    !searchInputRef?.current?.value && isInputFocused && setIsInputFocused(false);
  };

  return (
    <AppSearchbarView
      {...rest}
      inputRef={searchInputRef}
      onChange={onChange}
      placeholder={placeholder}
      className={className}
      isInputFocused={isInputFocused}
      onClick={onClick}
      onBlur={onBlur}
    />
  );
});

export default AppSearchbarController;
