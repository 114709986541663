import { FC } from 'react';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { VisitorCalendarViewInterface } from '.';
import { BigCalendar } from '../big-calendar';
import { EventsCalendar } from '../events-calendar';
import { CustomForm, FormController } from '../form';
import { visitorFields } from './visitor-calendar.helper';
import { useStyles } from './visitor-calendar.style';
import { VisitorForm } from './visitor-form';

const VisitorCalendarView: FC<VisitorCalendarViewInterface> = props => {
  const classes = useStyles();
  const {
    draggedEvent,
    calendarEvents,
    isLoading,
    setDraggedEvent,
    onRangeChangeCallback,
    createNewCalendarEvent,
    onSelectCalendarEventCallback,
    updateCalendarEventCallback,
    onSelectSlotCallback,
    setCalendarEvents,
  } = props;
  return (
    <div className={classes.root}>
      <div className={classes.topRoot}>
        <div className={classes.visitor}>
          <FormController formName="VisitorForm">
            <CustomForm>
              <VisitorForm fields={visitorFields} />
            </CustomForm>
          </FormController>
        </div>
        <div className={classes.items}>
          <EventsCalendar setDraggedEvent={setDraggedEvent} />
        </div>
      </div>
      <div className={classes.customCalendar}>
        <BigCalendar
          draggedCalendarEvent={draggedEvent}
          calendarEvents={calendarEvents}
          isLoading={isLoading}
          onRangeChangeCallback={onRangeChangeCallback}
          createNewCalendarEvent={createNewCalendarEvent}
          onSelectCalendarEventCallback={onSelectCalendarEventCallback}
          updateCalendarEventCallback={updateCalendarEventCallback}
          onSelectSlotCallback={onSelectSlotCallback}
          setCalendarEvents={setCalendarEvents}
        />
      </div>
    </div>
  );
};

export default VisitorCalendarView;
