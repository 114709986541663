import { ReactElement } from 'react';
import { ReportCalendarFilterViewInterface } from './report-calendar-filter.type';
import { useStyles } from './report-calendar-filter.style';
import { MenuAccordion } from '../../menu/menu-accordion';
import CheckBoxIcon from '@material-ui/icons/Check';
const ReportCalendarFilterView = (
  props: ReportCalendarFilterViewInterface,
): ReactElement => {
  const classes = useStyles();
  const { filterList, onClickChildFilter } = props;

  return (
    <>
      {filterList.map(item => {
        return (
          <MenuAccordion
            key={item?.name?.id}
            containerClassName={classes.accordionlistLiContainer}
            iconClassName={classes.menuIcon}
            titleClassName={classes.menucontent}
            title={item.name.title}
            isArrowShow
          >
            <div className={classes.items}>
              {Object.entries(item.childFilter).map(([title, checked]) => {
                return (
                  <MenuAccordion
                    key={title}
                    containerClassName={classes.accordionlistLiContainer}
                    iconClassName={classes.menuIcon}
                    titleClassName={`${classes.menucontentChild} ${
                      checked && classes.menuContentChildSelected
                    }`}
                    Icon={
                      checked ? (
                        <CheckBoxIcon className={classes.checkBox} />
                      ) : (
                        <div className={classes.outlineCheckBox} />
                      )
                    }
                    title={title}
                    isArrowShow={false}
                    onClick={() => onClickChildFilter(item.name, title, checked)}
                  />
                );
              })}
            </div>
          </MenuAccordion>
        );
      })}
    </>
  );
};

export default ReportCalendarFilterView;
