import { makeStyles } from '@material-ui/core';

const loadingBoxBackgroundColor = 'rgb(208 208 208 / 28%)';

export const useStyles = makeStyles(() => ({
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },

  absolute: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 10,
    backgroundColor: loadingBoxBackgroundColor,
  },
}));
