import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  containerNote: {
    maxWidth: '95%',
    width: '95%',
  },
  userName: {
    fontSize: 10,
    fontWeight: 700,
  },

  notePin: {
    display: 'flex',
    backgroundColor: '#F4DADD',
    padding: 2,
    justifyContent: 'space-between',
    fontSize: 12,
    paddingLeft: 5,
    paddingRight: 5,
    alignItems: 'center',
    marginBottom: 2,
    color: '#000',
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-2px)',
      backgroundColor: '#e8b3b9',
      // boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.black, 0.2)}`,
    },
  },
  text: {
    textOverflow: 'ellipsis',
    maxHeight: ' 20px',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  iconNote: {
    marginTop: 5,
  },
  iconCloes: {
    cursor: 'pointer',
    color: '#C36D79',
    '&:hover': {
      color: 'blue',
    },
  },
  mainPin: {
    maxHeight: 200,
    overflowY: 'scroll',
  },
}));
