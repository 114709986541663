import { DropdownOption } from './auto-complete-input.type';

/**
 * this function find selected option
 *@function getTextSelectedOption
 * @param {DropdownOption[]} {preparedOptions}
 * @param {string} {key}
 * @returns {string | undefined}
 */
export const getTextSelectedOption = (
  preparedOptions: DropdownOption[],
  key: string,
): string | undefined => {
  const selectedOption = preparedOptions.find(ite => ite.value === key);
  if (selectedOption) {
    if (selectedOption.text) {
      return selectedOption.text;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

/**
 * Get value form new object in onChange AutocompleteInput
 * @function handleChange
 * @param {object[]} {array}
 * @returns {string}
 */
export const convertToStringValue = (array: unknown[]): string => {
  return array
    .map(item => (item && typeof item === 'object' ? item['value'] : item))
    .join();
};

/**
 * Finds those values that are not in the current `dropdwonData`
 * @function findValuesNotInDropdownData
 * @param { string[] } values
 * @param { Record<string, unknown>[] } dropdownData
 * @returns { string[] }
 */
export const findValuesNotInDropdownData = (
  values: string[],
  dropdownData: Record<string, unknown>[],
): string[] => {
  const valuesNotInDropdownData: string[] = [];

  const valuesLength = values.length;
  for (let index = 0; index < valuesLength; index++) {
    // we just want to check the value not its type
    if (!dropdownData.find(item => item.id == values[index])) {
      valuesNotInDropdownData.push(values[index]);
    }
  }

  return valuesNotInDropdownData;
};

/**
 * Based on selected options, it removes those options from the main options
 * and shows them in beginning of the options
 * @function updateRenderOptions
 * @param {DropdownOption[]} newValueList
 * @param {DropdownOption[]} renderOptions
 * @param {DropdownOption[]} selectedOptionTemporaryRef
 * @returns {void}
 */
export const updateRenderOptions = (
  newValueList: DropdownOption[],
  renderOptions: DropdownOption[],
  selectedOptionTemporaryRef: DropdownOption[],
): void => {
  if (newValueList.length < selectedOptionTemporaryRef.length) {
    const difference = selectedOptionTemporaryRef.filter(
      option => newValueList.findIndex(item => item.value == option.value) === -1,
    );

    for (const diffItem of difference) {
      const targetIndexInRenderOptions = renderOptions.findIndex(
        item => item.value == diffItem.value,
      );

      if (targetIndexInRenderOptions === -1) {
        renderOptions.unshift(diffItem);
      }
    }
  } else {
    const difference = newValueList.filter(
      option =>
        selectedOptionTemporaryRef.findIndex(item => item.value == option.value) ===
        -1,
    );

    for (const diffItem of difference) {
      const targetIndexInRenderOptions = renderOptions.findIndex(
        item => item.value == diffItem.value,
      );

      if (targetIndexInRenderOptions > -1) {
        renderOptions.splice(targetIndexInRenderOptions, 1);
      }
    }
  }
};
