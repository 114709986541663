import { makeStyles } from '@material-ui/core';
import { MenuHeaderStyleProps } from '.';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, MenuHeaderStyleProps>(theme => ({
  menuHeader: {
    display: ({ isDrawerOpen }) => (isDrawerOpen ? 'flex' : 'none'),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0.5em 1em',
  },

  currentMenu: {
    fontSize: 13,
  },

  menuHeaderIcon: {
    position: 'absolute',
    left: 13,
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.7)',
    fontSize: 18,
  },

  iconSearch: {
    color: 'rgba(0, 0, 0, 0.7)',
    position: 'absolute',
    right: 40,
    cursor: 'pointer',
    fontSize: 18,
    marginTop: '2px',
    zIndex: 1,
  },

  iconCancel: {
    color: 'rgba(0, 0, 0, 0.7)',
    position: 'absolute',
    right: 3,
    cursor: 'pointer',
    fontSize: 18,
  },
}));
