const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

export function register() {
  if (isLocalhost) {
    console.log('service worker disabled because running on localhost');
    return;
  }

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      const prefix = location.pathname.replace(/\/(index\.html)?$/, '');
      navigator.serviceWorker.register(`${prefix}/sw.js?v=10`).then(
        function (registration) {
          registration.update();
          // Registration was successful
          console.log('[success] scope: ', registration.scope);
        },
        function (err) {
          // registration failed :(
          console.log('[fail]: ', err);
        },
      );
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
