import { useContext } from 'react';
import { VisitorCalendar } from '../component/visitor-calendar';
import { NewMetaContext } from '../container/NewMetaContext';

export const visitorCalenderResource = 'report/75038bdb-efae-47d2-8daa-06d4f41dd8c4';

const VisitorCalendarPage = () => {
  const { getMeta } = useContext(NewMetaContext);
  const metaData = getMeta(visitorCalenderResource);

  return <VisitorCalendar metaData={metaData} />;
};

export default VisitorCalendarPage;
