import { ReactElement } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { FileManagerViewInterface } from './file-manager.type';
import FileManager, {
  Permissions,
  Toolbar,
  ContextMenu,
  Item,
  ItemView,
  FileSelectionItem,
  Upload,
} from 'devextreme-react/file-manager';
import { customizeIcon } from './file-manager.helper';
import { isEmpty, megaByteToByte } from '../../helper/data-helper';

const FileManagerView = (props: FileManagerViewInterface): ReactElement => {
  const {
    customFileProvider,
    onOptionChanged,
    onSelectedFileOpened,
    itemViewMode,
    onContextMenuItemClick,
    maxFileSize,
  } = props;
  const locale = useLocale();
  const translate = useTranslate();

  return (
    // @ts-ignore
    <FileManager
      onContextMenuItemClick={onContextMenuItemClick}
      fileSystemProvider={customFileProvider}
      customizeThumbnail={customizeIcon}
      height="100%"
      onOptionChanged={onOptionChanged}
      onSelectedFileOpened={onSelectedFileOpened}
      rtlEnabled={locale !== 'en'}
    >
      <ItemView mode={itemViewMode}></ItemView>
      <Permissions
        create={true}
        copy={true}
        move={true}
        delete={true}
        rename={true}
        upload={true}
        download={true}
      ></Permissions>
      <Upload
        chunkSize={5_000_000_000}
        maxFileSize={
          !isEmpty(maxFileSize) ? megaByteToByte(+maxFileSize) : 999_999_999_999
        }
      />
      {/* @ts-ignore */}
      <Toolbar>
        <Item name="showNavPane" visible="true" />
        <Item name="separator" />
        <Item name="create" />
        <Item name="refresh" />
        <Item name="separator" location="after" />
        <Item name="switchView" />

        <FileSelectionItem name="download" />
        <FileSelectionItem name="separator" location="before" />
        <FileSelectionItem name="move" />
        <FileSelectionItem name="copy" />
        <FileSelectionItem name="rename" />
        <FileSelectionItem name="separator" location="before" />
        <FileSelectionItem name="delete" />
        <FileSelectionItem name="refresh" />
        <FileSelectionItem name="clearSelection" />
      </Toolbar>
      {/* @ts-ignore */}
      <ContextMenu>
        <Item name="create" />
        <Item name="upload" />
        <Item name="rename" beginGroup="true" />
        <Item name="move" />
        <Item name="copy" />
        <Item name="delete" />
        <Item name="download" beginGroup="true" />
        <Item
          name="properties"
          text={translate('fileManager.properties')}
          icon="cellproperties"
        />
      </ContextMenu>
    </FileManager>
  );
};

export default FileManagerView;
