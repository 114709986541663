import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import lodashGet from 'lodash/get';
import { ProfileFormViewInterface } from './profile-form.type';
import { useStyles } from './profile-form.style';
import { DynamicInput } from '../../dynamic-input';

const ProfileFormView = (props: ProfileFormViewInterface): ReactElement => {
  const { fields } = props;
  const locale = useLocale();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {fields &&
        fields.map(field => {
          return (
            <div className={classes.inputContainer}>
              <DynamicInput
                isProfile
                key={field.id}
                field={field}
                currentTabIndex={0}
                label={lodashGet(field, ['translatedCaption', locale], field.name)}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ProfileFormView;
