import { ReactElement } from 'react';
import NumberFormat from 'react-number-format';

import { NumberInputViewInterface } from './number-input.type';

const NumberInputView = (props: NumberInputViewInterface): ReactElement => {
  const {
    onChange,
    onBlur,
    onKeyDown,
    onFocus,
    onClick,
    field,
    value,
    CustomInput,
    getRef,
    ...other
  } = props;
  const { precision, format } = field;

  return (
    <NumberFormat
      value={value}
      getInputRef={getRef}
      inputCustomProps={{ field, getRef, ...other }}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onClick={onClick}
      isNumericString
      customInput={CustomInput}
      decimalScale={precision}
      thousandSeparator={!!format}
      autoComplete="off"
    />
  );
};

export default NumberInputView;
