import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Tooltip, IconButton, Icon, Badge } from '@material-ui/core';

import { MessageNotificationViewInterface } from './message-notification.type';

const MessageNotificationView = (
  props: MessageNotificationViewInterface,
): ReactElement => {
  const { totalUnseen, handleNotificationIconClick } = props;
  const translate = useTranslate();

  return (
    <Tooltip title={translate('notification.messages')}>
      <div>
        <IconButton
          color="inherit"
          onClick={handleNotificationIconClick}
          data-test-notification-icon
          id="messageNotify"
        >
          <Badge
            badgeContent={totalUnseen}
            max={100}
            color="error"
            showZero={false}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Icon>message</Icon>
          </Badge>
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default MessageNotificationView;
