import lodashGet from 'lodash/get';
import { FC, memo, useEffect, useState } from 'react';
import { useLocale } from 'react-admin';

import { isEmpty, isEmptyObject } from '../../../helper/data-helper';
import { IconFieldInterface } from './icon-field.type';
import IconFieldView from './icon-field.view';

const IconFieldController: FC<IconFieldInterface> = memo(props => {
  const { field, data } = props;
  const { relatedName, caption, name } = field;

  const locale = useLocale();
  const [icon, setIcon] = useState<string>('');

  const tooltip = lodashGet(field, ['translatedCaption', locale], caption);

  /**
   * this function get value from record
   * @function getIcon
   * @returns {string}
   */
  const getIcon = () => {
    let value = '';
    if (!isEmptyObject(data) && !isEmpty(data[relatedName])) {
      value = data[relatedName];
    }
    return value;
  };

  useEffect(() => {
    setIcon(getIcon());
  }, [data]);

  return <IconFieldView icon={icon} tooltip={tooltip} name={name} />;
});

export default IconFieldController;
