import { FC, memo } from 'react';

import { AppHeaderInterface } from './app-header.type';
import AppHeaderView from './app-header.view';

const AppHeaderController: FC<AppHeaderInterface> = memo(props => {
  const { children, className } = props;

  return <AppHeaderView children={children} className={className} />;
});

export default AppHeaderController;
