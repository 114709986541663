import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function TickIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 11.86L8.83502 16.7C9.03448 16.8925 9.30082 17 9.578 17C9.85518 17 10.1215 16.8925 10.321 16.7L20 7"
        stroke="#B0B0B0"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default withStyles(styles)(TickIcon);
