import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 3,
  },
  homeTypography: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    paddingRight: 0,
  },
}));
