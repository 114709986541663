import { ReactElement } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { useLocale } from 'react-admin';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

import { useStyles } from './form-with-tab.style';
import { Table as CustomFormTable } from '../../table';

import { QuickFormWithTabsViewProps } from './form-with-tab.type';
import { DynamicInput } from '../../dynamic-input/dynamic-input.controller';

const QuickFormWithTabsView = (
  props: QuickFormWithTabsViewProps,
): ReactElement | null => {
  const { tabList = [], selectedTab, tabsTitlesRef, tabChangeHandler } = props;

  const classes = useStyles();
  const theme = useTheme();
  const locale = useLocale();

  if (!(Array.isArray(tabList) && tabList.length > 0)) {
    return null;
  }

  return (
    <div
      className={`${classes.quickFormWithTabContainer} quickFormWithTabContainer`}
    >
      <Tabs
        defaultActiveKey={selectedTab ?? '0'}
        direction={theme.direction}
        onChange={tabChangeHandler}
      >
        {tabList.map((tab, index) => {
          return (
            <TabPane
              key={tab.id}
              tab={
                <p
                  ref={tabRef => {
                    tabsTitlesRef.current[index] = tabRef;
                  }}
                >
                  {tab.translatedTitle?.[locale] ?? tab.title ?? ''}
                </p>
              }
            >
              <div className={classes.tabGroupsContainer}>
                {tab.groupList.map((group, index: number) => {
                  const { translatedTitle, id } = group;
                  const title: string =
                    translatedTitle?.[locale] ?? String(id) ?? '';

                  return (
                    <div key={index} className={classes.groupContainer}>
                      <div className={classes.groupHeader}>
                        <Typography variant="body2">{title}</Typography>
                        {group.specialFields &&
                          group.specialFields.map(field => (
                            <DynamicInput key={field.id} field={field} />
                          ))}
                      </div>

                      <CustomFormTable
                        className={classes.table}
                        group={group}
                        type="form"
                      />
                    </div>
                  );
                })}
              </div>
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export { QuickFormWithTabsView };
