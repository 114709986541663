import { TagInputStyleProps } from './tag-input.type';
import { emphasize, makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';
import { getColorBaseOfStatus } from '../../input-base/input-message/input-message.helper';

export const useStyles = makeStyles<CustomTheme, TagInputStyleProps>(theme => ({
  select: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    fontSize: 13,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      fontSize: 10,
    },

    '& legend': {
      display: 'none',
    },
  },

  disabledDrop: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[700],
  },

  selectNoLabel: {
    '& > div': {
      '& > div': {
        padding: 2,
      },
    },
  },

  control: {
    width: '100%',
    '& > div': {
      height: 40,
      padding: 0,
    },
    '& label': {
      fontSize: 13,
    },
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        padding: 0,
        height: 27,
      },
      '& label': {
        fontSize: 10,
      },
    },

    '& fieldset': {
      border: props => `1px solid ${getColorBaseOfStatus(props.messageType, theme)}`,
    },
  },

  chip: {
    margin: '0 2px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  chipFocused: {
    color: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08,
    ),
  },

  chipIcon: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      fontSize: 16,
    },
  },

  input: {
    display: 'flex',
    width: '100%',
    padding: 0,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  placeholder: {
    fontSize: 13,
    position: 'absolute',
    left: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  indicatorSeparator: {
    alignSelf: 'stretch',
    backgroundColor: theme.palette.primary.appPrimaryDividerColor,
    width: 1,
    boxSizing: 'border-box',
  },

  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    position: 'relative',
    overflow: 'auto',
    boxSizing: 'border-box',
    padding: '0 5px',
  },

  clearIndicator: {
    display: 'flex',
    cursor: 'pointer',
    transition: 'color 150ms',
    color: theme.palette.primary.appPrimaryDisableIconColor,
  },
}));
