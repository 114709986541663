import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import clsx from 'classnames';
import { MoreOptionsViewInterface } from './more-options.type';
import { useStyles } from './more-options.style';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const MoreOptionsView = (props: MoreOptionsViewInterface): ReactElement => {
  const {
    onViewMoreOpen,
    isMoreIcon,
    onViewMoreClose,
    onStarClick,
    onImportantClick,
    onMarkUnReadClick,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box>
      <Tooltip title={translate('general.more')}>
        <MoreVertIcon
          className={clsx(classes.pointer, classes.moreVertIconRoot)}
          onClick={onViewMoreOpen}
        />
      </Tooltip>

      <Menu
        anchorEl={isMoreIcon}
        open={Boolean(isMoreIcon)}
        onClose={onViewMoreClose}
        style={{ marginTop: 45 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={() => onMarkUnReadClick(null, false)}>
          {translate('mail.markAsRead')}
        </MenuItem>
        <MenuItem onClick={() => onMarkUnReadClick(null, true)}>
          {translate('mail.markAsUnRead')}
        </MenuItem>
        <MenuItem onClick={() => onImportantClick(null, true)}>
          {translate('mail.markAsImportant')}
        </MenuItem>
        <MenuItem onClick={() => onImportantClick(null, false)}>
          {translate('mail.removeImportant')}
        </MenuItem>
        <MenuItem onClick={() => onStarClick(null, true)}>
          {translate('mail.markAsStarred')}
        </MenuItem>
        <MenuItem onClick={() => onStarClick(null, false)}>
          {translate('mail.removeStarred')}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default MoreOptionsView;
