import { FC, memo } from 'react';
import { setResource } from '../../helper/resource-helper';
import { actorDispatch, FormKeyMode } from '../../type/actor-setup';
import { RelationEditDialogItem } from '../dialogs-stack';

import { RelationEditButtonInterface } from './relation-edit-button.type';
import RelationEditButtonView from './relation-edit-button.view';

const RelationEditButtonController: FC<RelationEditButtonInterface> = memo(props => {
  const { resource, parentResource, parentId, id, disabled, iconClassName } = props;

  /**
   * @function openDialog
   * @returns {void}
   */
  const openDialog = () => {
    setResource(resource, FormKeyMode.RELATION);

    actorDispatch(
      'quickDialog',
      {
        relationEditIsOpen: true,
        data: {
          relationMode: false,
          mustRefresh: true,
          data: {
            parentResource,
            parentId,
            id,
            resource,
          } as RelationEditDialogItem,
        },
      },
      {
        replaceAll: true,
      },
    );
  };

  return (
    <RelationEditButtonView
      onClick={openDialog}
      onTouchStart={openDialog}
      disabled={disabled}
      iconClassName={iconClassName}
    />
  );
});

export default RelationEditButtonController;
