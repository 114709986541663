import { FC } from 'react';

import { NoteStreamCard } from './note-stream-card';
import { CreateEditNote } from './create-edit-note';
import type { NoteRelationViewInterface } from './note-relation.type';
import { useStyles } from './note-relation.style';

const EXPAND_LENGTH = 3;

const NoteRelationView: FC<NoteRelationViewInterface> = props => {
  const {
    relationMetaData,
    relationResource,
    parentFieldName,
    parentResource,
    parentRecordId,
    childFieldName,
    onSubmitNote,
    refreshView,
    data: noteVisibleData,
  } = props;

  const classes = useStyles();

  return (
    <div className={noteVisibleData.length ? classes.container : undefined}>
      <div
        data-test-is-have-load-more-button={EXPAND_LENGTH}
        className={noteVisibleData.length ? classes.noteListContainer : undefined}
        data-test-items-number={noteVisibleData.length}
        id="notes-container"
      >
        <CreateEditNote
          relationResource={relationResource}
          parentFieldName={parentFieldName}
          parentResource={parentResource}
          onSubmitNote={onSubmitNote}
          refreshView={refreshView}
          parentId={parentRecordId}
          relationData={null} // its null in create mode
        />

        {noteVisibleData.map(relationData => (
          <NoteStreamCard
            relationResource={relationResource}
            relationMetaData={relationMetaData}
            parentFieldName={parentFieldName}
            parentResource={parentResource}
            parentRecordId={parentRecordId}
            childFieldName={childFieldName}
            relationData={relationData}
            isCardInNoteStream={false}
            key={relationData.id}
            isDetailView={true}
            forceShowFullText
          />
        ))}
      </div>
    </div>
  );
};

export default NoteRelationView;
