import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import { TextField } from '@material-ui/core';
import lodashGet from 'lodash/get';
import { useStyles } from './coding-input.style';
import { CodingInputViewInterface } from './coding-input.type';
import LoadingBox from '../../LoadingBox';
import InputBase from '../../input-base';

export const CodingInputView = (props: CodingInputViewInterface): ReactElement => {
  const {
    field,
    disabled,
    label,
    hint,
    isLoading,
    maxLength,
    inputMessage,
    codingValues,
    handleChange,
    handleFocus,
    handleBlur,
    value,
    visibleClass,
    customTestAttribute,
    getRef,
  } = props;

  // --------------------------------------- custom hooks ----------------------------------------------
  const classes = useStyles({ messageType: inputMessage?.messageType });
  const locale = useLocale();
  const [firstItem, ...restItems] = codingValues;

  return (
    <InputBase
      className={`${visibleClass}`}
      required={field.required}
      field={field}
      inputMessage={inputMessage}
      label={label}
      hint={hint}
    >
      <div className={classes.codingContainer}>
        <TextField
          {...customTestAttribute}
          required={field.required}
          margin="normal"
          classes={{ root: classes.root }}
          ref={getRef}
          InputProps={{
            classes: {
              error: classes.error,
              root: classes.outlineRoot,
              notchedOutline: classes.notchedOutline,
              input: classes.input,
              disabled: classes.disabled,
            },
            inputProps: {
              maxLength: maxLength,
            },
            autoComplete: 'off',
          }}
          onChange={handleChange}
          variant="outlined"
          disabled={disabled}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
        />
        {isLoading && <LoadingBox size={25} />}
        {restItems?.map((field, index) => {
          return (
            <div className={classes.field} key={`${field.name}_${index}`}>
              <div className={classes.fieldCaption}>
                {lodashGet(field.translatedCaption, locale)}
              </div>
              <div className={classes.fieldValue}>
                {lodashGet(field, 'defaultValue')}
              </div>
            </div>
          );
        })}
      </div>
    </InputBase>
  );
};
