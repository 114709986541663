import { FC, memo, useCallback, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import {
  MailActions,
  OnArchiveParams,
  OnGetReferenceDataParams,
  OnMarkUnReadParams,
  ReferencesDataInterface,
} from '../..';
import { isEmptyObject } from '../../../../helper/data-helper';
import { getParamFromUrl } from '../../../../helper/UrlHelper';
import { AppDialog } from '../../../app-dialog';
import { DiagramContainer } from './diagram-container';

import {
  MailDetailHeaderInterface,
  ReferenceResponse,
} from './mail-detail-header.type';
import MailDetailHeaderView from './mail-detail-header.view';
import { ReferencesList } from './references-list';
import { useStyles } from './mail-detail-header.style';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const MailDetailHeaderController: FC<MailDetailHeaderInterface> = memo(props => {
  const { mailActionsHandler, selectedMail } = props;
  const classes = useStyles();
  const translate = useTranslate();

  const [diagramData, setDiagramData] = useState<ReferencesDataInterface[]>([]);
  const [referencesResponse, setReferencesResponse] =
    useState<ReferenceResponse | null>(null);
  const [currentReferencePage, setCurrentReferencePage] = useState<number>(1);
  const [referenceLoading, setReferenceLoading] = useState<boolean>(false);
  const [isDiagramDialogOpen, setIsDiagramDialogOpen] = useState<boolean>(false);
  const [isReferencesDialogOpen, setIsReferencesDialogOpen] =
    useState<boolean>(false);

  const location = useLocation();
  const uniqueId: string = getParamFromUrl(location.search, 'uniqueid')!;
  const referencePerPage = 10;

  /**
   * @function onMarkUnReadClick
   * @param { boolean } IsUnRead
   * @returns { void }
   */
  const onMarkUnReadClick = (IsUnRead: boolean): void => {
    mailActionsHandler(MailActions.onMarkUnRead, {
      mailsData: selectedMail && [selectedMail],
      IsUnRead,
    } as OnMarkUnReadParams);
  };

  /**
   * @function onArchiveClick
   * @returns { void }
   */
  const onArchiveClick = (): void => {
    mailActionsHandler(MailActions.onArchive, {
      mailsData: selectedMail && [selectedMail],
    } as OnArchiveParams);
  };
  /**
   * @function onUnArchiveClick
   * @returns { void }
   */
  const onUnArchiveClick = (): void => {
    mailActionsHandler(MailActions.onUnArchive, {
      mailsData: selectedMail && [selectedMail],
    } as OnArchiveParams);
  };

  /**
   * @function handleOpenDiagram
   * @returns { void }
   */
  const handleOpenDiagram = useCallback(() => {
    setIsDiagramDialogOpen(true);
  }, [setIsDiagramDialogOpen]);

  /**
   * @function handleCloseDiagram
   * @returns { void }
   */
  const handleCloseDiagram = useCallback(() => {
    setIsDiagramDialogOpen(false);
  }, [setIsDiagramDialogOpen]);

  /**
   * @function handleOpenReferences
   * @returns { void }
   */
  const handleOpenReferences = useCallback(() => {
    setIsReferencesDialogOpen(true);
  }, [setIsReferencesDialogOpen]);

  /**
   * @function handleCloseReferences
   * @returns { void }
   */
  const handleCloseReferences = useCallback(() => {
    setIsReferencesDialogOpen(false);
    setCurrentReferencePage(1);
  }, [setIsReferencesDialogOpen]);

  /**
   * @function successGetReferenceDataCallback
   * @param { ReferenceResponse } response
   * @returns { void }
   */
  const successGetReferenceDataCallback = (response: ReferenceResponse): void => {
    setReferencesResponse(response as ReferenceResponse);
    if (!isEmptyObject(response?.data)) {
      handleOpenReferences();
    }
    setReferenceLoading(false);
  };

  /**
   * @function successGetDiagramDataCallback
   * @param { ReferenceResponse } response
   * @returns { void }
   */
  const successGetDiagramDataCallback = (response: ReferenceResponse): void => {
    setDiagramData(response?.data);
    if (!isEmptyObject(response?.data)) {
      handleOpenDiagram();
    }
  };

  /**
   * @function onGetReferencesDataClick
   * @param { number } page
   * @returns { void }
   */
  const onGetReferencesDataClick = (page: number): void => {
    setReferenceLoading(true);
    mailActionsHandler(MailActions.onGetReferenceData, {
      id: uniqueId,
      IsChart: false,
      pagination: { page, perPage: referencePerPage },
      successCallback: successGetReferenceDataCallback,
    } as OnGetReferenceDataParams);
  };

  /**
   * @function handleChangeReferencePage
   * @param page
   * @returns { void }
   */
  const handleChangeReferencePage = (page: number): void => {
    setCurrentReferencePage(page);
    onGetReferencesDataClick(page);
  };

  /**
   * @function onGetDiagramDataClick
   * @returns { void }
   */
  const onGetDiagramDataClick = (): void => {
    mailActionsHandler(MailActions.onGetReferenceData, {
      id: uniqueId,
      IsChart: true,
      pagination: { perPage: 999999 },
      successCallback: successGetDiagramDataCallback,
    } as OnGetReferenceDataParams);
  };

  return (
    <>
      <MailDetailHeaderView
        selectedMail={selectedMail}
        onMarkUnReadClick={onMarkUnReadClick}
        onArchiveClick={onArchiveClick}
        onUnArchiveClick={onUnArchiveClick}
        onGetReferencesDataClick={onGetReferencesDataClick}
        onGetDiagramDataClick={onGetDiagramDataClick}
      />
      {isDiagramDialogOpen && (
        <AppDialog
          open={isDiagramDialogOpen}
          handleClose={handleCloseDiagram}
          dialogTitle={
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box component="span">{translate('mail.cycle')}</Box>
              <IconButton
                aria-label="close"
                onClick={handleCloseDiagram}
                onTouchEnd={handleCloseDiagram}
              >
                <CloseIcon
                  style={{
                    fontSize: '1.2rem',
                  }}
                />
              </IconButton>
            </Box>
          }
          dialogContent={
            <DiagramContainer data={diagramData as ReferencesDataInterface[]} />
          }
          dialogActions={<div />}
          fullScreen
        />
      )}
      {isReferencesDialogOpen && (
        <AppDialog
          open={isReferencesDialogOpen}
          handleClose={handleCloseReferences}
          dialogTitle={translate('mail.references')}
          dialogContent={
            <ReferencesList
              mailActionsHandler={mailActionsHandler}
              data={referencesResponse?.data as ReferencesDataInterface[]}
              loading={referenceLoading}
              pagination={{
                total: referencesResponse?.total ?? 0,
                currentPage: currentReferencePage,
                perPage: referencePerPage,
              }}
              onChangePageHandler={handleChangeReferencePage}
            />
          }
          dialogActions={<div />}
          maxWidth="lg"
          contentClassName={classes.contentClassName}
        />
      )}
    </>
  );
});

export default MailDetailHeaderController;
