import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  item: {
    marginLeft: 7,
    fontSize: 15,
    minWidth: 15,
    minHeight: 15,
    cursor: 'pointer',
  },
}));
