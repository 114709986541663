import { ReactElement } from 'react';
import clsx from 'classnames';
import { AppFooterInterface } from './app-footer.type';
import { useStyles } from './app-footer.style';
import Box from '@material-ui/core/Box';

const AppFooterView = (props: AppFooterInterface): ReactElement => {
  const { children, className } = props;
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.paginationRoot, className)}
      mt="auto"
      data-style-footer="footer"
    >
      {children}
    </Box>
  );
};

export default AppFooterView;
