import { makeStyles } from '@material-ui/core/styles';

import { CustomTheme, themeParams } from '../../core/themeProvider';
import { ThemeProps } from './app-layout.type';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    backgroundColor: themeParams.palette.primary.appPrimaryBackgroundColor,
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    display: 'flex',
  },

  container: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  toolbar: {
    minHeight: ({ headerHeight }: ThemeProps) => headerHeight,
    [theme.breakpoints.down('xs')]: {
      minHeight: ({ headerHeightXs }: ThemeProps) => headerHeightXs,
      maxHeight: ({ headerHeightXs }: ThemeProps) => headerHeightXs,
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    overflow: 'auto',
  },
}));
