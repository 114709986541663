import { ReactElement } from 'react';
import { ReferencesListInterface } from './references-list.type';
import { useStyles } from './references-list.style';
import { AppList } from '../../../../app-list';
import { ListEmptyResult } from '../../../../app-list/list-empty-result';
import { isEmptyObject } from '../../../../../helper/data-helper';
import { Box } from '@material-ui/core';
import Pagination from '../../../../Pagination';
import { AppFooter } from '../../../../app-container/app-footer';
import { ReferencesDataInterface } from '../../..';
import { ReferenceItem } from './reference-item';
import { ReferenceHeader } from './reference-header';
import ReferencesListSkeleton from '../../../../skeleton/references-list-skeleton.view';

const ReferencesListView = (props: ReferencesListInterface): ReactElement => {
  const { mailActionsHandler, data, loading, pagination, onChangePageHandler } =
    props;
  const classes = useStyles();

  return (
    <div data-test="mail-detail-references-list-container">
      <Box>
        <ReferenceHeader />
      </Box>
      <AppList
        className={classes.root}
        data={data}
        loading={loading}
        ListEmptyComponent={
          <ListEmptyResult
            loading={loading}
            placeholder={<ReferencesListSkeleton />}
          />
        }
        renderRow={item => (
          <ReferenceItem
            mailActionsHandler={mailActionsHandler}
            item={item as ReferencesDataInterface}
            refreshData={() => onChangePageHandler(1)}
          />
        )}
      />
      {!isEmptyObject(data) && (
        <AppFooter>
          <Pagination
            total={pagination.total}
            page={pagination.currentPage}
            perPage={pagination.perPage}
            rowsPerPageOptions={[10]}
            setPage={onChangePageHandler}
            setPerPage={() => {}}
            isLoading={loading}
            isCompactMode={false}
          />
        </AppFooter>
      )}
    </div>
  );
};

export default ReferencesListView;
