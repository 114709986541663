import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  menuContainer: {
    top: '100px !important',
  },
  itemsContainer: {
    flexWrap: 'nowrap',
    padding: '2px 20px',
    marginBottom: '1rem',
    maxHeight: 200,
    minWidth: 200,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: 3,
      },
    },
  },
  btn: {
    display: 'block',
    width: '70%',
    margin: '5px auto',
    fontSize: '.7 rem',
  },
}));
