import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-jalaali';

const JalaliDateField = props => {
  const { record, source, format = 'jYYYY-jMM-jDD HH:mm:ss' } = props;

  if (!record?.[source]) return <div data-test-date-field-name="source" />;

  return (
    <Typography component="span" body1="body1" data-test-date-field-name={source}>
      {moment(record[source]).format(format)}
    </Typography>
  );
};

JalaliDateField.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.object,
  addLabel: PropTypes.bool,
};

JalaliDateField.defaultProps = {
  addLabel: true,
};

export default JalaliDateField;
