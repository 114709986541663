import { FieldType } from '../../helper/Types';

export const visitorItems = {
  1: 'آزاد',
  2: 'مرخصی',
};

export const actionUniqueId = {
  create: '30b07712-43d0-4e8a-b3d1-c122f333bb52',
  update: 'be0fe17e-2bee-434d-8570-f00b7154bd43',
  delete: '0a2caee7-22ee-461a-9a7e-46afbdc95527',
};

export const visitorFields: Partial<FieldType>[] = [
  {
    caption: 'ویزیتور',
    dataType: {
      erp: 'dropdown',
      sql: 'bigint',
      simple: 'number',
      id: 1,
      defaultOperator: 'Equals',
    },
    disabled: false,
    javaScriptFormula: '',
    dropdown: {
      columns: null,
      tableName: '',
      displayMember: 'fullname',
      displayMember2: '',
      forceTreeLevel: false,
      id: '2d23bf41-f133-4e1b-86c7-ff8d1f0e0bec',
      moduleName: 'task',
      parameters: [],
      remoteSearch: false,
      translatedComment: null,
      translatedTitle: {
        fa: 'ویزیتور',
        en: 'visitor',
        ar: 'زائر',
      },
      type: 'Simple',
      uniqueId: '2d23bf41-f133-4e1b-86c7-ff8d1f0e0bec',
      valueMember: 'personinfo_id',
      name: 'visitorpersoninfo_id',
      title: 'VisitorPersonInfoTitle',
    },
    hidden: false,
    id: 0,
    name: 'visitorpersoninfo_id',
    translatedCaption: {
      fa: 'ویزیتور',
      en: 'visitor',
      ar: 'زائر',
    },
    relatedName: '__visitorpersoninfo_id_value',
    required: true,
    values: null,
    resource: '',
  },
];
