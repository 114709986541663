import { FC, memo } from 'react';

import { ModuleItemInterface } from './module-item.type';
import ModuleItemView from './module-item.view';

const ModuleItemController: FC<ModuleItemInterface> = memo(props => {
  const { title, onStarClick, dataTest, onTitleClick, isFavorite } = props;

  return (
    <ModuleItemView
      dataTest={dataTest}
      title={title}
      onStarClick={onStarClick}
      onTitleClick={onTitleClick}
      isFavorite={isFavorite}
    />
  );
});

export default ModuleItemController;
