import { ReactElement } from 'react';

import { useStyles } from './color-input.style';
import { ColorInputViewInterface } from './color-input.type';
import { Tooltip } from '@material-ui/core';
import { isEmpty } from '../../../helper/data-helper';

const ColorInputView = (props: ColorInputViewInterface): ReactElement => {
  const { tooltip, color, name, inputRef } = props;
  const classes = useStyles();

  if (isEmpty(color)) return <></>;

  return (
    <Tooltip title={tooltip}>
      <span
        ref={inputRef}
        data-test-color-input={name}
        className={classes.item}
        style={{ backgroundColor: color }}
      ></span>
    </Tooltip>
  );
};

export default ColorInputView;
