/**
 * download an element with simulation <a/> tag
 * @function downloadFile
 * @param {string} filePath
 * @param {string} apiBaseUrl
 * @returns { void } void
 */
export const downloadFile = (filePath: string, apiBaseUrl: string): void => {
  const anchor = document.createElement('a');
  const fullUrl = `${apiBaseUrl}/${filePath}`;
  anchor.href = fullUrl;
  anchor.download = fullUrl;
  anchor.target = '_blank';
  anchor.click();
};
