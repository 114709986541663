import { makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  docIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: blue[300],
    height: 35,
    width: 35,
    borderRadius: '1000%',
  },

  imageBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 35,
    width: 35,
    borderRadius: '1000%',
  },
}));
