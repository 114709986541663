import { ReactElement } from 'react';
import {
  Button,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  Icon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import classNames from 'classnames';

import { ButtonGroupViewInterface } from './button-group.type';
import { useStyles } from './button-group.style';
import { isEmpty } from '../../../../helper/data-helper';

const ButtonGroupView = (props: ButtonGroupViewInterface): ReactElement => {
  const {
    buttonRef,
    caption,
    handleToggle,
    handleClose,
    isOpen,
    actions,
    iconName,
    handleMenuItemClick,
    visibleClass,
    disabled,
    customTestAttribute,
  } = props;
  const classes = useStyles();

  return (
    <>
      <Tooltip title={caption}>
        <Button
          variant="contained"
          color="secondary"
          ref={buttonRef}
          aria-label="split button"
          disabled={disabled}
          className={classNames(classes.container, visibleClass)}
          {...customTestAttribute}
          onClick={handleToggle}
          startIcon={
            !isEmpty(iconName) ? (
              <Icon className={classNames(classes.icon, `fa fa-${iconName}`)}></Icon>
            ) : null
          }
          endIcon={<ArrowDropDownIcon />}
        >
          <Typography variant="button" noWrap>
            {caption}
          </Typography>
        </Button>
      </Tooltip>
      <Popper
        open={isOpen}
        anchorEl={buttonRef.current}
        role={undefined}
        transition
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {actions?.map(action => (
                    <MenuItem
                      key={action.id}
                      onClick={() => handleMenuItemClick(action)}
                    >
                      {action?.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ButtonGroupView;
