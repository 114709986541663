import lodashGet from 'lodash/get';
import { ValidationAction } from '../../../helper/Types';

import { actorGetActionValue } from '../../../type/actor-setup';

/**
 * convert  seconds to time format (hh:mm:ss)
 * @function convertSecondsToTimeFormat
 * @param seconds
 * @returns { number }
 */
export const convertSecondsToTimeFormat = (seconds: number): string => {
  const newDate = new Date(1970, 0, 1) as any;
  newDate.setSeconds(seconds);

  let convertedTime = newDate.toTimeString().substr(0, 8);

  if (seconds > 86399)
    convertedTime =
      Math.floor((newDate - Date.parse('1/1/70')) / 3600000) +
      convertedTime.substr(2);
  return convertedTime;
};

/**
 * convert time format (hh:mm:ss) to seconds
 * @function convertTimeFormatToSeconds
 * @param { string } time
 * @returns { number }
 */
export const convertTimeFormatToSeconds = (time: string): number => {
  const splittedTime = time.split(':');

  const seconds =
    +splittedTime[0] * 60 * 60 + +splittedTime[1] * 60 + +splittedTime[2];
  return seconds;
};

export const isCurrentFieldHasValidationActions = (
  fieldId: string | number,
): boolean => {
  const currentResource = actorGetActionValue('resources')!.current;

  const currentMetaData = actorGetActionValue('metaData', currentResource.value)!;

  const currentFieldValidationActions = lodashGet(
    currentMetaData,
    'validationActions',
  )?.[fieldId] as ValidationAction[] | undefined;

  if (currentFieldValidationActions) return true;
  return false;
};
