import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Avatar, Icon } from '@material-ui/core';
import { translate } from 'react-admin';

import { CONFIG_CURRENCY_SYMBOL, getValue } from '../../core/configProvider';

const useStyles = makeStyles(theme => ({
  product: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  badge: {
    top: '10%',
    right: 30,
    color: '#fafafa',
  },

  marginText: {
    margin: '0 15px',
  },
}));

const PointOfSaleProductItem = props => {
  const { product, translate } = props;
  const classes = useStyles();

  return (
    <div key={product.id} className={classes.product}>
      <div>
        <Badge badgeContent={4} color="secondary" classes={{ badge: classes.badge }}>
          <Avatar alt={product.name} src={product.imgUrl} />
        </Badge>
        <div className={classes.marginText}>{product.name}</div>
      </div>
      <div>
        <div className={classes.marginText}>
          {product.price}
          {getValue(CONFIG_CURRENCY_SYMBOL) || translate('shop.basket.currency')}
        </div>
        <Icon color="action" fontSize="small">
          close
        </Icon>
      </div>
    </div>
  );
};

PointOfSaleProductItem.propTypes = {
  product: PropTypes.object.isRequired,
};

export default compose(translate)(PointOfSaleProductItem);
