import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Button, Icon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  calculatorContainer: {
    marginTop: 20,
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: 'transparent',
    // border: `1px solid ${theme.palette.divider}`,
  },

  calculatorRow: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },

  calculatorBtn: {
    margin: 5,
    width: 45,
    height: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fafafa',
  },
}));

const PointOfSaleCalculator = props => {
  const classes = useStyles();

  return (
    <Paper className={classes.calculatorContainer} elevation={0}>
      <div className={classes.calculatorRow}>
        <Button variant="contained" className={classes.calculatorBtn}>
          1
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          2
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          3
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          +
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          <Icon>keyboard_backspace</Icon>
        </Button>
      </div>
      <div className={classes.calculatorRow}>
        <Button variant="contained" className={classes.calculatorBtn}>
          4
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          5
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          6
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          -
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          cus
        </Button>
      </div>
      <div className={classes.calculatorRow}>
        <Button variant="contained" className={classes.calculatorBtn}>
          7
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          8
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          9
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          *
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          calc
        </Button>
      </div>
      <div className={classes.calculatorRow}>
        <Button variant="contained" className={classes.calculatorBtn}>
          0
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          000
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          .
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          %
        </Button>
        <Button variant="contained" className={classes.calculatorBtn}>
          <Icon>subdirectory_arrow_left</Icon>
        </Button>
      </div>
    </Paper>
  );
};

PointOfSaleCalculator.propTypes = {};

export default PointOfSaleCalculator;
