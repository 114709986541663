import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import lodashGet from 'lodash/get';
import { ChangePasswordFormViewInterface } from './change-password-form.type';
import { useStyles } from './change-password-form.style';
import { DynamicInput } from '../../dynamic-input';

const ChangePasswordFormView = (
  props: ChangePasswordFormViewInterface,
): ReactElement => {
  const { fields } = props;
  const locale = useLocale();
  const classes = useStyles();

  return (
    //this is a trick for disable autofill in browser
    <form autoComplete={'off'} className={classes.root}>
      <input
        autoComplete="false"
        name="hidden"
        type="password"
        style={{ display: 'none' }}
      />
      {fields &&
        fields.map(field => {
          return (
            <div className={classes.inputContainer}>
              <DynamicInput
                key={field.id}
                field={field}
                currentTabIndex={0}
                label={lodashGet(field, ['translatedCaption', locale], field.name)}
              />
            </div>
          );
        })}
    </form>
  );
};

export default ChangePasswordFormView;
