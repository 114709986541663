import React from 'react';

export const CloseChildMenuIcon = (props: any) => {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 612 612"
      fill={props.color}
      width={props.width}
      height={props.height}
    >
      <defs></defs>
      <g id="_6" data-name="6">
        <path
          className="cls-1"
          d="M248.54,343.93H78.88a18.84,18.84,0,1,0,0,37.68H204.23L0,585l26.75,26.64,204-203.18-.59,123.82a18.92,18.92,0,0,0,37.83,0V362.75a16.81,16.81,0,0,0-6-13.19A19.14,19.14,0,0,0,248.54,343.93Zm284.6-113.54H407.79L612,27,585.25.38l-204,203.18.59-123.82a18.92,18.92,0,0,0-37.83,0V249.25a16.75,16.75,0,0,0,6,13.16,18.88,18.88,0,0,0,13.47,5.68H533.1a18.85,18.85,0,1,0,0-37.7Z"
        />
      </g>
    </svg>
  );
};
