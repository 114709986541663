import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: '600px',
    minWidth: '1024px',
    overflowY: 'auto',
    alighItems: 'center',
  },
}));
