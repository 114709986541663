import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FC } from 'react';

import { useStyles } from './pinned-notes-style';
import { PinnedNotesPropsView } from './pinned-notes-type';

const PinnedNotesView: FC<PinnedNotesPropsView> = props => {
  const { pinnedNotes, handlePinNoteScroll, hidePinnedNote, parsedJSONNote } = props;
  const classes = useStyles();

  return (
    <div className={classes.mainPin}>
      {Array.isArray(pinnedNotes) &&
        pinnedNotes?.map(note => (
          <div className={classes.notePin}>
            <div
              className={classes.containerNote}
              id={`${note.id}`}
              onClick={handlePinNoteScroll}
            >
              <p className={classes.text}>
                <span className={classes.userName}> {note.createusername}</span> :
                {note.notetext
                  ? note.notetext
                  : parsedJSONNote(note.notejson as string)}
              </p>
            </div>
            <div className={classes.iconNote} onClick={hidePinnedNote(note.id)}>
              <p className={classes.iconCloes}>
                <HighlightOffIcon />
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default PinnedNotesView;
