import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import PointOfSaleProductItem from './PointOfSaleProductItem';

const useStyles = makeStyles(theme => ({
  productList: {
    overflow: 'auto',
    maxHeight: 380,
  },
}));

const PointOfSaleProductList = props => {
  const { productList, className } = props;
  const classes = useStyles();

  return (
    <div className={classNames(className, classes.productList)}>
      {productList &&
        productList.map(item => {
          return <PointOfSaleProductItem product={item}></PointOfSaleProductItem>;
        })}
    </div>
  );
};

PointOfSaleProductList.propTypes = {
  productList: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default PointOfSaleProductList;
