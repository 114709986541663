import { FC, ReactElement } from 'react';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';
import { ClearIndicatorInterface } from './clear-indicator.type';

const ClearIndicator: FC<ClearIndicatorInterface> = (props): ReactElement => {
  const { selectProps, clearValue } = props;
  const { classes } = selectProps;

  return (
    <div className={classNames(classes.clearIndicator, 'clearIndicator')}>
      <Icon fontSize="small" onClick={clearValue} onTouchStart={clearValue}>
        close
      </Icon>
    </div>
  );
};

export default ClearIndicator;
