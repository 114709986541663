import { FC, memo } from 'react';

import { ReferencesListInterface } from './references-list.type';
import ReferencesListView from './references-list.view';

const ReferencesListController: FC<ReferencesListInterface> = memo(props => {
  const { mailActionsHandler, data, loading, pagination, onChangePageHandler } =
    props;

  return (
    <ReferencesListView
      mailActionsHandler={mailActionsHandler}
      data={data}
      loading={loading}
      pagination={pagination}
      onChangePageHandler={onChangePageHandler}
    />
  );
});

export default ReferencesListController;
