import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles, Toolbar } from '@material-ui/core';
import { isEmbeddedPage } from '../helper/UrlHelper';

const styles = createStyles({
  toolbar: {
    flexDirection: 'column',
    alignItems: 'unset',
    padding: 5,
    // maxHeight: '35%',
    flexShrink: 0,
    zIndex: 1000,
  },
  innerToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 1000,
  },

  settingToolbar: {
    padding: 0,
    height: 40,
    minHeight: 'auto',
    zIndex: 1000,
  },
});

const ListToolbar = ({
  classes,
  filters,
  filterValues, // dynamically set via the UI by the user
  permanentFilter, // set in the ListContainer component by the developer
  actions,
  bulkActions,
  exporter,
  settingToolbar,
  activeTabIndex,
  ...rest
}) => {
  if (isEmbeddedPage()) {
    return null;
  }

  return (
    <Toolbar className={settingToolbar ? classes.settingToolbar : classes.toolbar}>
      {actions &&
        React.cloneElement(actions, {
          ...actions.props,
          ...rest,
          className: classes.actions,
          bulkActions,
          exporter,
          filters,
          filterValues,
          permanentFilter,
          activeTabIndex,
        })}

      {filters &&
        React.cloneElement(filters, {
          ...rest,
          filterValues,
          'data-test-filter-bar': '',
          context: 'form',
          'data-style': 'filterBar',
        })}
    </Toolbar>
  );
};

ListToolbar.propTypes = {
  classes: PropTypes.object,
  filters: PropTypes.element,
  permanentFilter: PropTypes.object,
  actions: PropTypes.element,
  settingToolbar: PropTypes.bool,
  bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isAccessPath: PropTypes.bool,
};

ListToolbar.defaultProps = {
  classes: {},
};

export default withStyles(styles)(ListToolbar);
