import { FC, memo } from 'react';
import { GridBooleanInputViewInterface } from './grid-boolean-input.type';
import { Grid, Switch, Typography } from '@material-ui/core';

const GridBooleanInputView: FC<GridBooleanInputViewInterface> = props => {
  const { handleChangeValue, value, isDisabled } = props;
  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <Switch
            disabled={isDisabled}
            checked={!!value}
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
    </Typography>
  );
};

export default memo(GridBooleanInputView);
