import React from 'react';
import { useTranslate } from 'react-admin';
import { List, ListItem, makeStyles, Typography } from '@material-ui/core';

import { CustomTheme } from '../../core/themeProvider';
import { ActionListInterface } from '../../helper/Types';

interface QuickAccessActionListInterfaceProps {
  selectedAction: string;
  setSelectedAction: Function;
  actionList: ActionListInterface[];
}

const styles = makeStyles((theme: CustomTheme) => ({
  container: {
    maxHeight: 300,
    overflow: 'auto',
    borderTop: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
  },

  action: {
    cursor: 'pointer',
    padding: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.appPrimaryLightBackgroundColor,
    },
  },
}));

const QuickAccessActionList: React.FC<
  QuickAccessActionListInterfaceProps
> = props => {
  const { selectedAction, setSelectedAction, actionList } = props;
  const classes = styles();
  const translate = useTranslate();

  /**
   * Set selected action name.
   * @function selectItemHandle
   * @param {string} name
   * @returns {void}
   */
  const selectItemHandle = (name: string) => (): void => {
    setSelectedAction(name);
  };

  return (
    <List className={classes.container}>
      {actionList &&
        actionList.length &&
        actionList.map(item => {
          return (
            <ListItem
              key={item.name}
              selected={selectedAction === item.name}
              onClick={selectItemHandle(item.name)}
              className={classes.action}
            >
              <Typography variant="body2">
                {translate(`quickAccess.${item.title}`)}
              </Typography>
            </ListItem>
          );
        })}
    </List>
  );
};

export default QuickAccessActionList;
