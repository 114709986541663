import { makeStyles } from '@material-ui/core';
import { StyleProps } from '.';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  searchOnFocus: {
    minWidth: '8rem',
    width: '20%',
    transition: 'width .2s',
  },
  searchOnBlur: {
    width: '2rem',
    transition: 'width .2s',
  },
  searchBar: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  inputView: {
    width: '100%',
  },

  icon: {
    height: 20,
    width: 20,
  },

  inputRoot: {
    flexGrow: 1,
    marginLeft: '.5rem',
    opacity: ({ isInputFocused }) => (isInputFocused ? 1 : 0),
    width: '90%',
    transition: 'width .2s',
    '&:focus': { width: '95%' },
  },

  input: {
    height: 0,
    '&:-webkit-autofill': {
      border: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
      '-webkit-box-shadow': `0 0 0 100px ${theme.palette.primary.appSecondaryBackgroundColor} inset`,
      '-webkit-text-fill-color': '#000',
      borderLeft: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
      borderRadius: '4px',
      padding: 'none',
      opacity: ({ isInputFocused }) => (isInputFocused ? 1 : 0),
      width: '90%',
      transition: 'width .2s',
      '&:focus': { width: '95%' },
    },
  },
}));
