import { memo, ReactElement } from 'react';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InfoIcon from '@material-ui/icons/Info';

import { InputMessageViewInterface } from './input-message.type';
import { useStyles } from './input-message.style';
import { isEmpty } from '../../../helper/data-helper';

import type { ValidationErrorMessageType } from '../../../helper/Types';

const InputMessageView = (props: InputMessageViewInterface): ReactElement => {
  const { inputMessage } = props;

  let messageType = '';
  let tooltipTitle = '';
  if (inputMessage) {
    messageType = inputMessage.messageType;
    tooltipTitle = inputMessage.message;
  }

  const classes = useStyles({
    messageType: messageType as ValidationErrorMessageType,
  });

  return (
    <Tooltip
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      title={tooltipTitle}
      placement="top"
      interactive
      arrow
    >
      {messageType === 'warning' ? (
        <WarningIcon className={`${classes.icon}`} />
      ) : messageType === 'success' ? (
        <CheckCircleOutlineIcon className={`${classes.icon}`} />
      ) : messageType === 'info' ? (
        <InfoIcon className={`${classes.icon}`} />
      ) : (
        <InfoOutlinedIcon
          className={`${classes.icon} ${
            isEmpty(inputMessage?.message) ? classes.hiddenError : null
          }`}
        />
      )}
    </Tooltip>
  );
};

export default memo(InputMessageView);
