import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function RemindTomorrowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.6465 9.8535l1.646 1.646h-4.292v1h4.292l-1.646 1.647.707.707 2.854-2.854-2.854-2.853z" />
      <path d="M5 12c0-3.859 3.14-7 7-7s7 3.141 7 7-3.14 7-7 7-7-3.141-7-7zm-1 0c0 4.411 3.589 8 8 8s8-3.589 8-8-3.589-8-8-8-8 3.589-8 8z" />
    </SvgIcon>
  );
}

export default withStyles(styles)(RemindTomorrowIcon);
