import { FC, memo, useCallback } from 'react';
import { ChangeFormValueParams, FormActions, OnBlurParams } from '../../form';

import { ColorPickerInputInterface } from './color-picker-input.type';
import ColorPickerInputView from './color-picker-input.view';

const ColorPickerInputController: FC<ColorPickerInputInterface> = memo(props => {
  const { formActionsHandler, field, value } = props;
  const { name } = field;

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void} void
   */
  const handleBlur = (): void => {
    formActionsHandler &&
      formActionsHandler(FormActions.InputBlur, {
        fieldName: name,
        value,
      } as OnBlurParams);
  };

  /**
   * @function handleChange
   * @param param0
   * @returns { void }
   */
  const handleChange = useCallback((event: any) => {
    formActionsHandler &&
      formActionsHandler(FormActions.InputChange, {
        fieldName: name,
        value: event?.value,
      } as ChangeFormValueParams);
  }, []);

  return (
    <ColorPickerInputView
      handleChange={handleChange}
      handleBlur={handleBlur}
      {...props}
    />
  );
});

export default ColorPickerInputController;
