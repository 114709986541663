import React from 'react';
import Typography from '@material-ui/core/Typography';

export function NoOptionsMessage(props) {
  const { selectProps, innerProps } = props;
  const { dropdownError } = selectProps;

  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
      data-test-select-no-available-option="true"
    >
      {dropdownError || selectProps.translate('dropdown.noOptionsMessage')}
    </Typography>
  );
}

export function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

export function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
