import { FC, memo } from 'react';

import { AppDialogInterface } from './app-dialog.type';
import AppDialogView from './app-dialog.view';

const AppDialogController: FC<AppDialogInterface> = memo(props => {
  return <AppDialogView {...props} />;
});

export default AppDialogController;
