import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  pointer: {
    cursor: 'pointer',
  },
  moreVertIconRoot: {
    color: grey[700],
    display: 'block',
  },
  dialogContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuContainer: {
    position: 'relative',
    minWidth: '300px',
    minHeight: '100px',
    maxHeight: '200px',
    overflowY: 'auto',
  },
}));
