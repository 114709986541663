import { FieldType, ValidationAction } from '../../../helper/Types';
import { FormActionsHandler } from '../../form';
import { LinkType } from '../../quick-access-menu';

export interface ButtonInputInterface {
  value?: string;
  formActionsHandler?: FormActionsHandler;
  resource?: string;
  field: FieldType;
  className?: string;
  disabled?: boolean;
  visibleClass: string;
  inputContainerClass?: string;
  customTestAttribute: Record<string, unknown>;
}

export interface ButtonInputViewInterface extends ButtonInputInterface {
  handleRunService: (action: ValidationAction) => void;
  actions?: ValidationAction[];
}

export type IconType = {
  fontName: string;
  imagePath: string;
  svgPath: string;
};

export const ButtonInputViewType = {
  ICON_AND_CAPTION: 'IconAndCaption',
  ICON_ONLY: 'Icon',
  CAPTION_ONLY: 'Caption',
};

export type ViewType = keyof typeof ButtonInputViewType;

export type ButtonInputValuesType = {
  icon?: IconType;
  viewType: ViewType;
};

export interface ServiceResultInterface {
  userMessage?: string;
  data?: Record<string, unknown>;
  actionOutput?: {
    additionalData: {
      link: string;
      linktype: LinkType;
    };
  };
}
