import { memo, ReactElement } from 'react';
import { InputLabel, Tooltip } from '@material-ui/core';

import { InputLabelViewInterface } from './input-label.type';
import { useStyles } from './input-label.style';
import { isEmpty } from '../../../helper/data-helper';

const InputLabelView = (props: InputLabelViewInterface): ReactElement => {
  const { label, required, hint } = props;
  const classes = useStyles();

  return isEmpty(hint) ? (
    <InputLabel
      className={classes.inputLabel}
      required={required}
      shrink={false}
      data-style-label-input-empety="inputLabelEmpty"
    >
      {label}
    </InputLabel>
  ) : (
    <Tooltip title={hint}>
      <InputLabel
        className={classes.inputLabel}
        required={required}
        shrink={false}
        data-style-label-input="inputLabel"
      >
        {label}
      </InputLabel>
    </Tooltip>
  );
};

export default memo(InputLabelView);
