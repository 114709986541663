import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: 0,
    height: 38,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
      marginBottom: 5,
      height: 'auto',
    },
  },

  emptyTableCell: {
    padding: 0,
    height: 38,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
    },
  },
}));
