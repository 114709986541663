import { Tooltip } from '@material-ui/core';
import { ReactElement } from 'react';

import { useStyles } from './icon-field.style';
import { IconFieldViewInterface } from './icon-field.type';

const IconFieldView = (props: IconFieldViewInterface): ReactElement => {
  const { tooltip, icon, name } = props;
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <i data-test-icon-field={name} className={`fa fa-${icon} ${classes.item}`}></i>
    </Tooltip>
  );
};

export default IconFieldView;
