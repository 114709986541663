import { ChangeEvent, FC, memo, ReactNode, useCallback } from 'react';
import lodashDebounce from 'lodash.debounce';
import { ControlInterface } from './control.type';
import ControlView from './control.view';
import { inputComponent } from '../../../DropdownCommonComponents';
import { actorGetActionValue } from '../../../../type/actor-setup';
import { FindTagInputDataWithActorParams, FormActions } from '../../../form';

const ControlController: FC<ControlInterface> = memo(props => {
  const { children, selectProps, innerRef, innerProps, errorMessage } = props;
  const { resource, source } = selectProps;

  //-----------------------------ACTOR--------------------------------------------------------
  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;

  /**
   * @function handleDebounceChangeText
   * @param {string} value
   * @returns {void}
   */
  const handleDebounceChangeText = (value: string): void => {
    formActionsHandler(FormActions.FindTagInputDataWithRedux, {
      resource: resource,
      source: source,
      value: value,
    } as FindTagInputDataWithActorParams);
  };

  /** trigger a debounced function in useCallback
   * @function debounceFn
   * @returns {void}
   */
  const debounceFn = useCallback(lodashDebounce(handleDebounceChangeText, 1000), []);

  /** the usage of this function is for find out the suggestions in a result list
   *  while typing in TextField
   * @function handleChangeText
   * @param {ChangeEvent} event
   * @returns {void}
   */
  const handleChangeText = (event: ChangeEvent<HTMLInputElement>): void => {
    debounceFn(event.target.value);
  };

  return (
    <ControlView
      children={children}
      inputComponent={inputComponent as unknown as ReactNode}
      innerProps={innerProps}
      selectProps={selectProps}
      handleChangeText={handleChangeText}
      innerRef={innerRef}
      errorMessage={errorMessage}
    />
  );
});

export default ControlController;
