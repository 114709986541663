import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Box, Button } from '@material-ui/core';
import clsx from 'classnames';
import { ListEmptyResultInterface } from './list-empty-result.type';
import { useStyles } from './list-empty-result.style';
import LoadingBox from '../../loading-box';

const ListEmptyResultView = (props: ListEmptyResultInterface): ReactElement => {
  const { loader, placeholder, loading, title, actionTitle, content, onClick } =
    props;
  const classes = useStyles();
  const translate = useTranslate();

  if (loading || loader) {
    return (
      <>
        {placeholder ? (
          placeholder
        ) : (
          <Box className={clsx(classes.emptyListContainer, classes.flexRow)}>
            <LoadingBox size={16} />
            <Box component="span" ml={2}>
              {translate('dropdown.loading')} ...
            </Box>
          </Box>
        )}
      </>
    );
  } else {
    return (
      <Box className={classes.emptyListContainer}>
        {title ? (
          <Box component="h4" fontSize={18} color="text.secondary" mb={3}>
            {title}
          </Box>
        ) : null}
        <Box fontSize={16} component="p" color="text.hint">
          {content}
        </Box>

        {actionTitle ? (
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: 30, height: 45, minWidth: 150 }}
            onClick={onClick}
          >
            {actionTitle}
          </Button>
        ) : null}
      </Box>
    );
  }
};

export default ListEmptyResultView;
