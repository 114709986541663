import { FC, memo, RefObject, useRef } from 'react';

import { SearchBoxInterface } from './search-box.type';
import SearchBoxView from './search-box.view';

const SearchBoxController: FC<SearchBoxInterface> = memo(props => {
  const { id, onChange } = props;
  const searchInputRef = useRef<HTMLInputElement>(null);

  /**
   * @function onSearchIconClickHandler
   * @returns { void }
   */
  const onSearchIconClickHandler = (): void => {
    searchInputRef?.current?.focus();
  };

  return (
    <SearchBoxView
      id={id}
      inputRef={searchInputRef}
      onChange={onChange}
      onSearchIconClickHandler={onSearchIconClickHandler}
    />
  );
});

export default SearchBoxController;
