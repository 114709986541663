import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

function MoveToFolderIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.17 6L11.17 8H20V18H4V6H9.17ZM10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z"
        fill="#757575"
      />
      <path
        d="M14.6627 9.45839C14.5075 9.29368 14.2308 9.40352 14.2308 9.62984V11.2857H11V14.7143H14.2308V16.3702C14.2308 16.5965 14.5075 16.7063 14.6627 16.5416L17.8384 13.1715C17.9292 13.0752 17.9292 12.9248 17.8384 12.8285L14.6627 9.45839ZM15.3077 13.5714H12.0769V12.4286H15.3077V11.76L16.4762 13L15.3077 14.24V13.5714Z"
        fill="#757575"
      />
    </svg>
  );
}

export default withStyles(styles)(MoveToFolderIcon);
