import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  pointer: {
    cursor: 'pointer',
    userSelect: 'none',
  },

  simpleFormContainer: {
    flexDirection: 'column',
    padding: '.5rem 1.5rem !important',
  },
}));
