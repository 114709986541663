import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: '100%',
    minWidth: '1024px',
    height: '6rem',
    fontWeight: 700,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid',
    borderColor: 'white !important',
    paddingLeft: 20,
    paddingRight: 20,
    background: theme.palette.primary.appPrimaryBackgroundColor,
  },
}));
