import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '.5rem 0',
  },
  title: {
    cursor: 'pointer',
    fontSize: 10,
    '&:hover': {
      opacity: 0.8,
    },
  },
  starIcon: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.7)',
    fontSize: 15,
    '&:hover': {
      opacity: 0.5,
    },
  },
}));
