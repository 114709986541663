import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  searchInputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '80%',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.7)',
    cursor: 'pointer',
    fontSize: 18,
    zIndex: 1,
  },

  inputContainer: {
    marginLeft: '1rem',
  },

  input: {
    zIndex: 1,
    height: 'auto',
    '&:-webkit-autofill': {
      border: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
      '-webkit-box-shadow': `0 0 0 100px ${theme.palette.primary.appSecondaryBackgroundColor} inset`,
      '-webkit-text-fill-color': '#000',
    },
  },
  notchedOutline: {
    border: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
  },
}));
