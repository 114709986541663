import {
  ASK_NAME_FOR_FILTER,
  CLOSE_DIALOG,
  CLEAR_FILTER_REQUEST_LIST,
  ADD_TO_FILTER_REQUEST_LIST,
} from './constant';

export const askNameForAction = ({ resource, data }) => ({
  type: ASK_NAME_FOR_FILTER,
  resource,
  data,
});

export const closeDialogAction = () => ({
  type: CLOSE_DIALOG,
});

export const addToFilterRequestListAction = filterData => ({
  type: ADD_TO_FILTER_REQUEST_LIST,
  filterData,
});

export const clearFilterRequestListAction = () => ({
  type: CLEAR_FILTER_REQUEST_LIST,
});
