import produce from 'immer';
import { USER_LOGOUT } from 'react-admin';

import {
  ASK_NAME_FOR_FILTER,
  CLOSE_DIALOG,
  CLEAR_FILTER_REQUEST_LIST,
  ADD_TO_FILTER_REQUEST_LIST,
} from './constant';

const initialState = {
  isDialogOpen: false,
  dialogResource: null,
  dialogData: null,
  filterRequestList: [],
};

const myReducer = (state = initialState, { type, resource, data, filterData }) =>
  produce(state, draft => {
    switch (type) {
      case USER_LOGOUT:
      case CLOSE_DIALOG:
        draft.isDialogOpen = false;
        draft.dialogResource = null;
        draft.dialogData = null;
        draft.filterRequestList = [];
        break;

      case ASK_NAME_FOR_FILTER:
        draft.isDialogOpen = true;
        draft.dialogResource = resource;
        draft.dialogData = data;
        break;

      case CLEAR_FILTER_REQUEST_LIST:
        draft.filterRequestList = [];
        break;

      case ADD_TO_FILTER_REQUEST_LIST:
        draft.filterRequestList.push(filterData);
        break;
    }
  });

export default myReducer;
