import { ReactElement } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import classNames from 'classnames';

import {
  ButtonInputValuesType,
  ButtonInputViewInterface,
} from './button-input.type';
import { ValidationAction } from '../../../helper/Types';

const IconButtonInputView = (props: ButtonInputViewInterface): ReactElement => {
  const {
    field,
    handleRunService,
    actions,
    visibleClass,
    disabled,
    customTestAttribute,
  } = props;

  const { caption, values } = field;

  return (
    <Tooltip title={caption}>
      <IconButton
        disabled={disabled}
        onClick={() => handleRunService(actions?.[0] as ValidationAction)}
        className={classNames(
          `fa fa-${(values as unknown as ButtonInputValuesType)?.icon?.fontName}`,
          visibleClass,
        )}
        {...customTestAttribute}
      />
    </Tooltip>
  );
};

export default IconButtonInputView;
