import { FC, memo, useState, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { useTheme } from '@material-ui/core';

import { ConfirmDialog } from '../../../../../confirm-dialog';
import { RecallConfirmDialogInterface } from '../../../multiple-mail-detail-body';
import { ReferenceItemInterface } from './reference-item.type';
import ReferenceItemView from './reference-item.view';
import {
  MailActions,
  MailInterface,
  OnRecallParams,
} from '../../../../mail-section.type';
import { showNotification } from '../../../../../../helper/general-function-helper';

const ReferenceItemController: FC<ReferenceItemInterface> = memo(props => {
  const { mailActionsHandler, item, refreshData } = props;

  const translate = useTranslate();
  const theme = useTheme();

  const [confirmDialogData, setConfirmDialogData] =
    useState<RecallConfirmDialogInterface>({
      isOpen: false,
      data: null,
    });

  /**
   * @function onRecallClick
   * @param { MailInterface } mail
   * @returns { void }
   */
  const onRecallClick = useCallback(
    (mail: MailInterface) => {
      setConfirmDialogData({
        isOpen: true,
        data: mail,
      });
    },
    [setConfirmDialogData],
  );

  /**
   * @function handleCloseRecallConfirmDialog
   * @returns { void }
   */
  const handleCloseRecallConfirmDialog = useCallback(() => {
    setConfirmDialogData({
      isOpen: false,
      data: null,
    });
  }, [setConfirmDialogData]);

  /**
   * @function onRecallSuccessCallback
   * @returns { void }
   */
  const onRecallSuccessCallback = (): void => {
    showNotification(translate('mail.successRecall'), 'info');
    handleCloseRecallConfirmDialog();
    refreshData();
  };

  /**
   * @function onRecallFailureCallback
   * @param { unknown } error
   * @returns { void }
   */
  const onRecallFailureCallback = (error: unknown): void => {
    showNotification(error as string, 'error');
    handleCloseRecallConfirmDialog();
  };

  /**
   * @function onConfirmRecallHandler
   * @returns { void }
   */
  const onConfirmRecallHandler = (): void => {
    mailActionsHandler?.(MailActions.onRecall, {
      mailsData: [confirmDialogData?.data],
      successCallback: onRecallSuccessCallback,
      failureCallback: onRecallFailureCallback,
    } as OnRecallParams);
  };

  /**
   * @function onRecallHandler
   * @param { React.MouseEvent<HTMLButtonElement, MouseEvent> } event
   * @returns { void }
   */
  const onRecallHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.stopPropagation();

    if (item) {
      onRecallClick &&
        onRecallClick({
          doctype_id: item.doctype_id,
          doc_id: item.messages_id,
          refrences_id: item.messagereferences_id,
        } as MailInterface);
    }
  };

  return (
    <>
      {confirmDialogData.isOpen && (
        <ConfirmDialog
          open={confirmDialogData.isOpen}
          title={translate('mail.recallConfirmMessage')}
          content=""
          handleClose={handleCloseRecallConfirmDialog}
          onConfirm={onConfirmRecallHandler}
          color={theme.palette.error.main}
        />
      )}
      <ReferenceItemView item={item} onRecallHandler={onRecallHandler} />
    </>
  );
});

export default ReferenceItemController;
