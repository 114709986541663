import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  menuContainer: {
    top: '30px !important',
  },

  infoContainer: {
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  moreText: {
    color: theme.palette.primary.appPrimaryTextColor,
  },
}));
