import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
  },
  card: {
    width: 'auto',
    maxWidth: '60%',
  },
  title: { margin: 8 },
  items: {
    borderRadius: '4px',
    width: '100px',
    margin: '10px',
    cursor: 'pointer',
    padding: 4,
  },
  rootItems: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  freeType: {
    background: theme.palette.primary.eventColorPrimary,
  },
  vacationType: {
    background: theme.palette.primary.eventColorSecondary,
  },
  text: {
    color: theme.palette.primary.appPrimaryToolbarBackgroundColor,
  },
}));
