import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    marginBottom: 10,
    '& .ck.ck-toolbar': {
      border: 'none !important',
    },
  },

  image: {
    width: 250,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
