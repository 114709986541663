import { Tooltip } from '@material-ui/core';
import { ReactElement } from 'react';
import { isEmpty } from '../../../helper/data-helper';

import { useStyles } from './color-field.style';
import { ColorFieldViewInterface } from './color-field.type';

const ColorFieldView = (props: ColorFieldViewInterface): ReactElement => {
  const { tooltip, color, name } = props;
  const classes = useStyles();

  if (isEmpty(color)) return <></>;

  return (
    <Tooltip title={tooltip}>
      <span
        data-test-color-field={name}
        className={classes.item}
        style={{ backgroundColor: color }}
      ></span>
    </Tooltip>
  );
};

export default ColorFieldView;
