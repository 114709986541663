import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core';

import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  noteItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
  },
  iconPinDisable: { width: 20, height: 20, opacity: 0.3 },
  iconPin: { width: 20, height: 20, opacity: 1 },

  noteContent: {
    flexGrow: 1,
    marginLeft: '0.5em',
    fontSize: 11,
    backgroundColor: grey[50],
    borderRadius: 2,
    padding: 15,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.23)',
    whiteSpace: 'pre-line',
  },

  textField: {
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  textFieldInput: {
    minHeight: 22,
  },

  saveBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 15,
  },

  icon: {
    margin: '0 5px',
  },

  previewFileContainer: {
    display: 'flex',
  },

  noteButton: {
    margin: theme.spacing(1),
  },
}));
