import { FC } from 'react';
import { MenuAccordionViewInterface } from './menu-accordion.type';
import Collapse from '@kunukn/react-collapse';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import classes from './menu-accordion.styles.module.css';

const MenuAccordionView: FC<MenuAccordionViewInterface> = props => {
  const {
    children,
    title,
    Icon,
    containerClassName,
    titleClassName,
    collapseClassName,
    isArrowShow,
    isOpen,
    onToggle,
    onClick,
  } = props;
  return (
    <span onClick={onClick}>
      <li
        data-test="list-accordion"
        onClick={onToggle}
        className={`${containerClassName}`}
        data-style-list-acc="listAccordion"
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {Icon}
          <span className={titleClassName}>{title}</span>
        </span>
        {isArrowShow && (
          <KeyboardArrowUp
            className={`${isOpen ? classes['icon'] : classes['icon-expanded']}`}
          />
        )}
      </li>
      {children && (
        <Collapse
          data-test="list-item-collapse"
          className={collapseClassName}
          isOpen={isOpen}
          data-style-colaps-menu="colapsMenu"
        >
          {children}
        </Collapse>
      )}
    </span>
  );
};

export default MenuAccordionView;
