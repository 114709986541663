import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  item: {
    width: 15,
    height: 15,
    borderRadius: 10,
    display: 'block',
    marginLeft: 7,
    border: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    cursor: 'pointer',
  },
}));
