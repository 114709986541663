import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  fab: {
    color: '#ffffff',
    backgroundColor: '#404040',
    borderRadius: '8px 8px 0 0',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    width: '260px !important',
    '&:hover ': {
      backgroundColor: '#404040',
    },
  },
  fabDetail: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
  },

  fabDetailLeft: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
  },

  textFabMailLeft: {
    margin: '0 0px 0px 10px',
    maxWidth: 200,
    fontSize: 12,
  },
  textFabMail: { margin: '0 0px 0px 10px', maxWidth: 200, fontSize: 12 },

  mainIcon: { display: 'flex', margin: '0px 5px 0px 5px' },

  fabIcon: {
    width: '15px',
    height: '15px',
    marginLeft: 5,
    marginRight: 5,
  },

  fabIconHeight: { width: '15px', height: '15px', transform: 'rotate(-45deg)' },

  fabIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
