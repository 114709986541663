import { DEFAULT_COLON } from '.';

/**
 * it should findout that income value is number or not
 * @param {string|number} value
 * @returns {boolean} true if is number false if it's not a number
 */
export function isNumber<T>(value: T): boolean {
  const number = Number(value);

  return !isNaN(number) && String(value) === String(number);
}

/**
 * it should add 0 or 00 if value be empty or one digit
 * @param {string|number} value
 * @returns {string} formated value
 */
export function formatTimeItem(value?: string | number): string {
  return `${value || ''}00`.substr(0, 2);
}

/**
 * it should receve last time and new time
 * seprate hour, minute and second to diffrent variables
 * make each part formatted in two digits and handle new position
 * then assemble all parts to a single clean string to show base of
 * show second prop.
 * @param {boolean} showSeconds it came from props
 * @param {string|number} value
 * @param {string|number} defaultValue
 * @param {string} colon declared on top of the page
 * @param {number} cursorPosition
 * @returns {Array} validatedValue is compelete formated value and newCursorPosition is last position in string has been changed
 */
export function validateTimeAndCursor(
  showSeconds = false,
  value = '',
  defaultValue = '',
  colon = DEFAULT_COLON,
  cursorPosition = 0,
): [string, number] {
  // cast new cursor position to number
  let newCursorPosition = Number(cursorPosition);

  // extract previous hour, minute and second from default value prop
  const [oldHour, oldMinute, oldSecond] = defaultValue.split(colon);

  // extract new hour, minute and second from value prop
  let [newHour, newMinute, newSecond] = String(value).split(colon);

  // formatting hour to currect format of show (tow digits)
  newHour = formatTimeItem(newHour);

  // seprate hour digits and cast to number
  const oldHourFirstDigit = Number(oldHour[0]);
  const newHourFirstDigit = Number(newHour[0]);
  const newHourSecondDigit = Number(newHour[1]);

  // making currect format of hour after new changes
  if (newHourFirstDigit > 2) {
    newHour = oldHour;
    newCursorPosition -= 1;
  } else if (newHourFirstDigit === 2) {
    if (oldHourFirstDigit === 2 && newHourSecondDigit > 3) {
      newHour = `2${oldHour[1]}`;
      newCursorPosition -= 2;
    } else if (newHourSecondDigit > 3) {
      newHour = '23';
    }
  }

  // formatting minute to currect format of show (tow digits)
  newMinute = formatTimeItem(newMinute);

  // seprate minute digits and cast to number
  const newMinuteFirstDigit = Number(newMinute[0]);

  // making currect format of minute after new changes
  if (newMinuteFirstDigit > 5) {
    newMinute = oldMinute;
    newCursorPosition -= 1;
  }

  // it should check if show second prop has true value should do these commands
  if (showSeconds) {
    // formatting second to currect format of show (tow digits)
    newSecond = formatTimeItem(newSecond);

    // seprate second digits and cast to number
    const newSecondFirstDigit = Number(newSecond[0]);

    // making currect format of second after new changes
    if (newSecondFirstDigit > 5) {
      newSecond = oldSecond;
      newCursorPosition -= 1;
    }
  }

  // assemble new time parts to a single formatted string
  const validatedValue = showSeconds
    ? `${newHour}${colon}${newMinute}${colon}${newSecond}`
    : `${newHour}${colon}${newMinute}`;

  return [validatedValue, newCursorPosition];
}
