/**
 * to truncate a html string after a certain word
 * @function truncateHtmlString
 * @param { string } htmlString
 * @param { string } certainWord
 * @returns { string }
 */
export const truncateHtmlString = (
  htmlString: string,
  certainWord: string,
): string => {
  return htmlString && htmlString.indexOf(certainWord) !== -1
    ? htmlString.substring(0, htmlString.indexOf(certainWord))
    : htmlString;
};

/**
 * to truncate htmlString till first page-break seen
 * @function truncateHtmlStringPageBreak
 * @param { string } htmlString
 * @returns { string }
 */
export const truncateHtmlStringPageBreak = (htmlString: string): string => {
  return truncateHtmlString(htmlString, '<div class="page-break"');
};

/**
 * @function removeTagWithSpecificClassNameFromString
 * @param { string } string
 * @param { string } className
 * @returns { string }
 */
export const removeTagWithSpecificClassNameFromString = (
  string: string,
  className: string,
): string => {
  // create a new div container
  const div = document.createElement('div');

  // assing  HTML to div's innerHTML
  div.innerHTML = string;
  // get all <className> elements from div
  const elements = div.getElementsByClassName(className);

  // remove all certain class-name elements
  while (elements[0]) elements[0]?.parentNode?.removeChild(elements[0]);

  // get div's innerHTML into a new variable
  const newDiv = div.innerHTML;
  return newDiv;
};

/**
 * @function removePageBreakFromHtmlString
 * @param { string } data
 * @returns { string }
 */
export const removePageBreakFromHtmlString = (data: string): string => {
  return removeTagWithSpecificClassNameFromString(data, 'page-break');
};
