import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

function StarIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8901 2.56998L15.1901 7.48997C15.2616 7.63677 15.3681 7.7638 15.5001 7.85997C15.6296 7.95735 15.7804 8.02248 15.9401 8.04997L21.1602 8.84998C21.3416 8.87346 21.512 8.95 21.6502 9.06998C21.7886 9.18635 21.8923 9.33856 21.9501 9.50996C22.0099 9.68026 22.0203 9.86401 21.9801 10.04C21.9394 10.218 21.8492 10.3809 21.7201 10.51L17.8701 14.47C17.7684 14.5835 17.6903 14.716 17.6401 14.86C17.5904 15.0081 17.58 15.1666 17.6101 15.32L18.5101 20.85C18.536 21.0336 18.5115 21.2209 18.4393 21.3917C18.3671 21.5625 18.2499 21.7106 18.1001 21.82C17.9451 21.9221 17.7655 21.9808 17.5801 21.99C17.3957 21.9997 17.2124 21.9581 17.0502 21.87L12.4801 19.33C12.3324 19.2512 12.1676 19.21 12.0001 19.21C11.8327 19.21 11.6679 19.2512 11.5201 19.33L6.95008 21.87C6.78818 21.9589 6.6046 22.0005 6.42017 21.99C6.23503 21.9794 6.0558 21.9208 5.90015 21.82C5.74927 21.7108 5.63162 21.562 5.56019 21.39C5.49073 21.219 5.46991 21.0321 5.50013 20.85L6.40015 15.32C6.42201 15.1656 6.40829 15.0083 6.36011 14.86C6.31975 14.7117 6.24033 14.577 6.13013 14.47L2.28016 10.51C2.15327 10.3807 2.06646 10.2174 2.03016 10.04C1.98665 9.86495 1.9936 9.6812 2.05018 9.50996C2.10808 9.33774 2.21249 9.18491 2.35193 9.06842C2.49137 8.95193 2.66031 8.87632 2.84009 8.84998L8.06018 8.04997C8.22021 8.02159 8.37136 7.95615 8.50159 7.85892C8.63182 7.76168 8.7375 7.63534 8.81018 7.48997L11.1101 2.56998C11.1876 2.40118 11.3127 2.25868 11.4701 2.15997C11.6272 2.05566 11.8116 2 12.0001 2C12.1887 2 12.3731 2.05566 12.5302 2.15997C12.6875 2.25868 12.8126 2.40118 12.8901 2.56998V2.56998Z"
        stroke="#585858"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </svg>
  );
}

export default withStyles(styles)(StarIcon);
