import { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';

export const MailItem = (): ReactElement => (
  <ContentLoader viewBox="10 0 430 25">
    <rect x="70" y="10" rx="0" ry="0" width="10" height="10" />
    <rect x="110" y="10" rx="0" ry="0" width="20" height="10" />
    <rect x="170" y="10" rx="0" ry="0" width="50" height="10" />
    <rect x="240" y="10" rx="0" ry="0" width="20" height="10" />
    <rect x="270" y="10" rx="10" ry="10" width="10" height="10" />
    <rect x="320" y="10" rx="0" ry="0" width="20" height="10" />
    <rect x="350" y="10" rx="10" ry="10" width="10" height="10" />
    <rect x="410" y="10" rx="0" ry="0" width="20" height="10" />
  </ContentLoader>
);
const ReferencesListSkeleton = (): ReactElement => {
  return (
    <>
      <MailItem />
      <MailItem />
      <MailItem />
      <MailItem />
      <MailItem />
      <MailItem />
      <MailItem />
      <MailItem />
    </>
  );
};

export default ReferencesListSkeleton;
