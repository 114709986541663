import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  faContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  icons: {
    fontSize: 14,
  },
}));
