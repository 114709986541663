import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function CycleIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M1.8 15V16.8H5.9274L0 22.7274L1.2726 24L7.2 18.0726V22.2H9V15H1.8Z"
        fill="#666666"
      />
      <path
        d="M11.5 22V20.2727C13.4113 20.2704 15.255 19.565 16.6797 18.2908C18.1043 17.0166 19.0104 15.2629 19.2252 13.3636H12.3636C11.9057 13.3632 11.4666 13.1811 11.1428 12.8572C10.8189 12.5334 10.6368 12.0943 10.6364 11.6364V4.77477C8.73715 4.98959 6.98335 5.89567 5.70919 7.32034C4.43503 8.74501 3.72958 10.5887 3.72727 12.5H2C2.00274 9.98129 3.00451 7.56652 4.78552 5.78552C6.56652 4.00451 8.98129 3.00274 11.5 3C11.7291 3 11.9487 3.09099 12.1107 3.25295C12.2726 3.41492 12.3636 3.63459 12.3636 3.86364V11.6364H20.1364C20.3654 11.6364 20.5851 11.7274 20.747 11.8893C20.909 12.0513 21 12.2709 21 12.5C20.9973 15.0187 19.9955 17.4335 18.2145 19.2145C16.4335 20.9955 14.0187 21.9973 11.5 22V22Z"
        fill="#666666"
      />
      <path
        d="M23.1716 10H15.6625C15.4438 9.99989 15.2274 9.95668 15.0254 9.87283C14.8235 9.78898 14.6401 9.66615 14.4857 9.51135C14.3313 9.35655 14.2089 9.17284 14.1256 8.9707C14.0422 8.76857 13.9996 8.55198 14 8.33334V0.833388C14.0009 0.722794 14.0236 0.613461 14.0668 0.511662C14.11 0.409863 14.1729 0.317603 14.2519 0.240177C14.3309 0.162751 14.4244 0.101683 14.527 0.0604773C14.6296 0.0192716 14.7394 -0.00126022 14.85 5.98402e-05C17.2745 0.00709522 19.5978 0.973363 21.3122 2.68779C23.0266 4.40221 23.9929 6.72545 23.9999 9.15C24.0017 9.2603 23.9816 9.36985 23.9409 9.47235C23.9001 9.57486 23.8395 9.6683 23.7625 9.7473C23.6856 9.8263 23.5937 9.88929 23.4923 9.93266C23.3909 9.97603 23.2819 9.99892 23.1716 10ZM15.6667 8.33334H22.2874C22.0959 6.64341 21.3368 5.06843 20.1342 3.86582C18.9315 2.66321 17.3566 1.90409 15.6667 1.71255V8.33334Z"
        fill="#666666"
      />
    </SvgIcon>
  );
}

export default withStyles(styles)(CycleIcon);
