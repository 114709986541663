import { alpha, makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    fontWeight: 300,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderBottom: '1px solid',
    borderColor: 'white !important',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 20,
    paddingRight: 20,
    cursor: 'pointer',
    transition: 'all .2s ease',
    transform: 'scale(1)',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      transform: 'translateY(-2px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.black, 0.2)}`,
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },

  cell: {
    fontWeight: 300,
    [theme.breakpoints.up('sm')]: {
      width: '30%',
    },
  },
  box: {
    marginTop: '15px',
  },
  truncate: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  userContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  avatar: {
    width: 30,
    height: 30,
    marginRight: 5,
    marginLeft: 5,
  },
}));
