/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-undef */

const _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault');

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.default = void 0;

const _react = _interopRequireDefault(require('react'));

const _createSvgIcon = _interopRequireDefault(
  require('@material-ui/icons/utils/createSvgIcon'),
);

const _default = (0, _createSvgIcon.default)(
  _react.default.createElement(
    _react.default.Fragment,
    null,
    _react.default.createElement('path', {
      fill: 'none',
      d: 'M0 0h24v24H0z',
    }),
    _react.default.createElement('path', {
      d: 'M22.416.587H2.514C1.613.587 1.141 1.702 1.784 2.345L9.72 10.281V19.116C9.72 19.46 9.891 19.76 10.148 19.975L13.58 22.377C14.266 22.849 15.21 22.334 15.21 21.519V10.281L23.145 2.345C23.788 1.702 23.317.801 22.416.587ZM1.348 19.273 8.674 19.273 5.011 22.985Z',
    }),
  ),
  'SaveFilter',
);

exports.default = _default;
