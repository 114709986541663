import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    '& .ck-content': {
      border: 'none !important',
    },
    '& .ck-widget.raw-html-embed .raw-html-embed__content-wrapper, .ck-widget.ck-widget_selected, .ck-widget.raw-html-embed .raw-html-embed__preview-content, .ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected, .ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected:hover':
      {
        outline: 'none',
        background: '#fff',
      },
    '& .ck-rounded-corners .ck.ck-button, .ck-widget.raw-html-embed:before, .ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected.ck-widget_with-selection-handle>.ck-widget__selection-handle':
      {
        display: 'none',
      },
  },
  toolbarContainer: {
    background: theme.palette.primary.appPrimaryToolbarBackgroundColor,
  },
}));
