import { FieldType, Translate } from '../../../helper/Types';

export const changePasswordFields = (
  translate: Translate,
): Array<Partial<FieldType>> => [
  {
    name: 'CurrentPassword',
    dataType: {
      simple: 'text',
      erp: 'text',
      defaultOperator: '',
      id: -1,
      sql: '',
    },
    required: true,
    translatedCaption: {
      fa: 'کلمه عبور فعلی',
      en: 'Current password',
      ar: 'كلمة المرور الحالي',
    },
    tabId: 3,
    tabTitle: translate('menu.changePassword'),
    isHashed: true,
  },
  {
    name: 'NewPassword',
    dataType: {
      simple: 'text',
      erp: 'text',
      defaultOperator: '',
      id: -1,
      sql: '',
    },
    required: true,
    translatedCaption: {
      fa: 'کلمه عبور جدید',
      en: 'New password',
      ar: 'كلمة مرور جديدة',
    },
    tabId: 3,
    tabTitle: translate('menu.changePassword'),
    isHashed: true,
  },
  {
    name: 'ConfirmPassword',
    dataType: {
      simple: 'text',
      erp: 'text',
      defaultOperator: '',
      id: -1,
      sql: '',
    },
    required: true,
    translatedCaption: {
      fa: 'تکرار کلمه عبور جدید',
      en: 'Repeat the new password',
      ar: 'كرر كلمة المرور الجديدة',
    },
    tabId: 3,
    tabTitle: translate('menu.changePassword'),
    isHashed: true,
  },
];
