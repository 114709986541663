import { ReactElement } from 'react';
import { AppSearchbarViewInterface } from './app-searchbar.type';
import { useStyles } from './app-searchbar.style';
import clsx from 'classnames';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import { TextField } from '@material-ui/core';

const AppSearchBarView = (props: AppSearchbarViewInterface): ReactElement => {
  const {
    inputRef,
    placeholder,
    isInputFocused,
    onChange,
    onClick,
    onBlur,
    className,
    ...rest
  } = props;

  const classes = useStyles({ isInputFocused });

  return (
    <Box
      {...rest}
      className={clsx(
        isInputFocused ? classes.searchOnFocus : classes.searchOnBlur,
        className,
      )}
      tabIndex={1}
      onClick={onClick}
      onBlur={onBlur}
    >
      <div className={classes.searchBar}>
        <div className={classes.icon}>
          <SearchIcon />
        </div>
        <TextField
          autoFocus={true}
          inputRef={inputRef}
          onChange={onChange}
          classes={{
            root: classes.inputRoot,
          }}
          InputProps={{
            classes: {
              input: classes.input,
            },
          }}
          variant="outlined"
          placeholder={placeholder}
        />
      </div>
    </Box>
  );
};

export default AppSearchBarView;
