import { ReactElement } from 'react';
import { useTheme } from '@material-ui/core';
import { MenuHeaderViewInterface } from './menu-header.type';
import { useStyles } from './menu-header.style';
import { TextField, Icon } from '@material-ui/core';
import { SearchBox } from './search-box';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const MenuHeaderView = (props: MenuHeaderViewInterface): ReactElement => {
  const {
    width,
    showSearchBox,
    searchInMenu,
    selectedMenuLabel,
    backClickHandler,
    toggleSearchBox,
    selectedList,
    isDrawerOpen,
  } = props;
  const classes = useStyles({ isDrawerOpen });
  const theme = useTheme();

  const BackArrow =
    theme.direction === 'rtl' ? KeyboardArrowRight : KeyboardArrowLeft;

  return (
    <div className={classes.menuHeader}>
      {width === 'xs' ? (
        showSearchBox ? (
          <>
            <Icon
              className={classes.iconSearch}
              onClick={toggleSearchBox}
              data-test="menuSearchIconButton"
            >
              close
            </Icon>
            <span className={classes.currentMenu}>
              <TextField
                autoFocus
                onChange={event => searchInMenu(event.target.value)}
                data-test="menuSearchInput"
              />
            </span>
          </>
        ) : (
          <>
            <Icon
              onClick={toggleSearchBox}
              className={classes.iconSearch}
              data-test="menuSearchIconButton"
            >
              search
            </Icon>
            <span className={classes.currentMenu}>{selectedMenuLabel}</span>
            {!!selectedList && selectedList.length > 0 && (
              <BackArrow
                className={classes.menuHeaderIcon}
                onClick={backClickHandler}
              />
            )}
          </>
        )
      ) : (
        <SearchBox
          data-test="menuSearchInput"
          onChange={event => searchInMenu(event.target.value)}
        />
      )}
    </div>
  );
};

export default MenuHeaderView;
