import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  btnRoot: {
    display: 'block',
    width: '70%',
    margin: '5px auto',
    transition: '.5s all linear',
  },
  btnNewLabel: {
    display: 'block',
    width: '70%',
    margin: '5px auto',
    fontSize: '.7 rem',
  },
  labelsContainer: {
    flexWrap: 'nowrap',
    padding: '2px 20px',
    marginBottom: '1rem',
    maxHeight: 200,
    minWidth: 150,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: 3,
      },
    },
  },
  labelsMenuContainer: {
    minWidth: 150,
    top: '100px !important',
  },
  newLabelFormContainer: {
    overflow: 'visible !important',
  },
}));
