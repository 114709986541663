import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  backdrop: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 9998,
    background: '#000',
    opacity: 0.2,
  },
}));
