import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    flexWrap: 'wrap',
    width: '100%',
    maxHeight: 100,
    overflowY: 'auto',
    cursor: 'pointer',
  },
}));
