import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { useStyles } from './mail-list-item-header.style';

const MailListItemHeaderView = (): ReactElement => {
  const classes = useStyles();
  const translate = useTranslate();
  const location = useLocation();

  return (
    <Box className={classes.root} data-style-mail-header="mailHeader">
      <Box
        display="flex"
        alignItems="center"
        ml={12}
        width={{ xs: '100%', sm: 'auto' }}
      >
        <Box component="span" pr={2} display="inline-block"></Box>
        <Box component="span">
          <Box component="span" color="text.disabled"></Box>
        </Box>
        <Box component="span" mr={10}>
          <Box component="span" color="text.disabled"></Box>
        </Box>
        <Box component="p">
          {location?.search?.includes('outbox') ||
          location?.search?.includes('draft')
            ? translate('mail.referenceListHeader.receiver')
            : translate('mail.referenceListHeader.sender')}
        </Box>
      </Box>
      <Box ml={20} className={classes.crMailInfo}>
        <Box width={1} className={classes.subjectContainer}>
          <Box mb={0} component="p">
            {translate('mail.referenceListHeader.referenceParaph')}
          </Box>
        </Box>
        <Box component="span" className={classes.crMailTime}>
          <Box component="span" pl={2}>
            {translate('mail.referenceListHeader.date')}
          </Box>
          <Box component="span" display="flex" alignItems="center" mt={1}>
            <Box component="span"></Box>
            <Box component="span"></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MailListItemHeaderView;
