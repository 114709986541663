import { FC, memo } from 'react';

import { ListFooterInterface } from './list-footer.type';
import ListFooterView from './list-footer.view';

const ListFooterController: FC<ListFooterInterface> = memo(props => {
  const { loading = false, footerText } = props;

  return <ListFooterView loading={loading} footerText={footerText} />;
});

export default ListFooterController;
