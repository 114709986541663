import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function LaterTodayIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M15.98 10H20V6h-1v2.149C17.618 5.625 14.947 4 12 4c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8h-1c0 3.859-3.14 7-7 7s-7-3.141-7-7 3.14-7 7-7c2.716 0 5.152 1.587 6.294 4H15.98v1z" />
      <path d="M11 8v5h4v-1h-3V8z" />
    </SvgIcon>
  );
}

export default withStyles(styles)(LaterTodayIcon);
