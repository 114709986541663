import { FC, memo } from 'react';

import { AppListInterface } from './app-list.type';
import AppListView from './app-list.view';

const AppListController: FC<AppListInterface> = memo(props => {
  const { footerProps, renderRow, data, loading, className, ListEmptyComponent } =
    props;

  return (
    <AppListView
      className={className}
      footerProps={footerProps}
      renderRow={renderRow}
      data={data}
      loading={loading}
      ListEmptyComponent={ListEmptyComponent}
    />
  );
});

export default AppListController;
