import React from 'react';

import type { FieldType } from '../../../helper/Types';
import type { InputMessage } from '../../form';

export const DEFAULT_COLON = ':';
export const DEFAULT_VALUE_SHORT = `00${DEFAULT_COLON}00`; // it will be like 00:00
export const DEFAULT_VALUE_FULL = `00${DEFAULT_COLON}00${DEFAULT_COLON}00`; // it will be like 00:00:00

export interface LastPositionInterface {
  input: HTMLInputElement;
  position: number;
}

export interface TimeInputInterface {
  field: FieldType;
  value: string;
  inputMessage?: InputMessage;
  label: string;
  hint: string;
  resource: string;
  visibleClass: string;
  customTestAttribute: Record<string, unknown>;
  getRef: (ref: unknown) => void;
  source?: string;
  colon?: string;
  disabled?: boolean;
  showSeconds?: boolean;
  className?: string;
}

export interface TimeInputViewInterface {
  field: FieldType;
  value: string;
  inputMessage?: InputMessage;
  label: string;
  hint: string;
  resource: string;
  clearInput: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  visibleClass: string;
  customTestAttribute: Record<string, unknown>;
  getRef: (ref: unknown) => void;
  source?: string;
  disabled?: boolean;
  className?: string;
}
