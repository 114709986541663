import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topRoot: {
    display: 'flex',
    flex: 1,
    padding: 16,
  },
  visitor: {
    flex: 1,
    width: '50%',
  },
  items: {
    flex: 1,
  },
  customCalendar: {
    flex: 5,
  },
}));
