import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';

import { isEmpty } from '../../helper/data-helper';

const ResourceLinkField = ({ record, source, field, label }) => {
  if (isEmpty(record[source])) {
    return <div />;
  }

  let url;
  switch (field.resourceType) {
    case 'Table':
      url = `/${field.resource}/${record[field.relatedName]}/show`;
      break;

    default:
      url = `/${field.resource}/${record[field.relatedName]}/show`;
  }

  return (
    <Link
      component={RouterLink}
      to={url}
      target="_blank"
      rel="noreferrer"
      variant="caption"
      color="secondary"
      label={label}
      data-test-resource-field-link
    >
      {record[source]}
    </Link>
  );
};

export default ResourceLinkField;
