import { FC, memo, useState } from 'react';
import { useTranslate } from 'react-admin';
import { MailActions, OnDeleteFolderParams, OnGetFoldersParams } from '../..';

import { ConfirmDialog } from '../../../confirm-dialog';
import { MailSidebarContextMenuInterface } from './mail-sidebar-context-menu.type';
import MailSidebarContextMenuView from './mail-sidebar-context-menu.view';

const MailSidebarContextMenuController: FC<MailSidebarContextMenuInterface> = memo(
  props => {
    const { mailActionsHandler, folderId, title } = props;
    const translate = useTranslate();

    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
      useState<boolean>(false);

    /**
     * @function handleDeleteFolder
     * @param { React.MouseEvent<HTMLElement> } event
     * @returns { void }
     */
    const handleDeleteFolder = (event: React.MouseEvent<HTMLElement>): void => {
      event.preventDefault();
      setIsConfirmDeleteDialogOpen(true);
    };

    /**
     * @function handleCloseDeleteConfirmDialog
     * @returns { void }
     */
    const handleCloseDeleteConfirmDialog = (): void => {
      setIsConfirmDeleteDialogOpen(false);
    };

    /**
     * @function successDeleteFolderCallback
     * @returns { void }
     */
    const successDeleteFolderCallback = (): void => {
      mailActionsHandler(MailActions.onGetFolders, {
        isForInbox: 1,
        successCallback: undefined,
      } as OnGetFoldersParams);
      handleCloseDeleteConfirmDialog();
    };

    /**
     * @function onConfirmDeleteFolder
     * @returns { void }
     */
    const onConfirmDeleteFolder = (): void => {
      mailActionsHandler(MailActions.onDeleteFolder, {
        Folders_ID: folderId,
        successCallback: successDeleteFolderCallback,
      } as OnDeleteFolderParams);
    };

    return (
      <>
        <MailSidebarContextMenuView handleDeleteFolder={handleDeleteFolder} />
        {isConfirmDeleteDialogOpen && (
          <ConfirmDialog
            open={isConfirmDeleteDialogOpen}
            handleClose={handleCloseDeleteConfirmDialog}
            onConfirm={onConfirmDeleteFolder}
            title={translate('mail.confirmDeleteFolderMessage', {
              folderName: title,
            })}
            content=""
          />
        )}
      </>
    );
  },
);

export default MailSidebarContextMenuController;
