export const field = {
  fixCalendar: '',
  regex: null,
  regexDescription: null,
  width: 100,
  allowSort: true,
  hasValidationActions: true,
  comment: 'الگو',
  tableName: 'messages',
  numberOfLines: 1,
  dataType: {
    erp: 'dropdown',
    sql: 'bigint',
    simple: 'number',
    id: 1,
    defaultOperator: 'Equals',
  },
  disabled: false,
  defaultValue: null,
  parentField: null,
  moduleName: 'automation',
  id: 63960,
  caption: 'الگو',
  translatedCaption: {
    fa: 'الگو',
    en: 'Templates_ID',
    ar: 'Templates_ID',
  },
  translatedComment: {
    fa: 'الگو',
    en: 'Templates_ID',
    ar: 'الگو',
  },
  relatedName: '__templates_id_value',
  linkName: '__templates_id_link',
  name: 'templates_id',
  required: false,
  isHashed: false,
  hasSummary: false,
  miladiDisplay: false,
  filterDefaultValue: null,
  maxLength: null,
  dropdown: {
    id: 6489,
    uniqueId: 'cbdce7d7-d2a4-4d03-95fb-bfe2d4baca89',
    comment: '',
    displayMember: 'title',
    displayMember2: null,
    valueMember: 'templates_id',
    moduleName: 'automation',
    tableName: 'templates',
    type: 'Simple',
    title: 'اتوماسيون اداري_الگو پيام',
    translatedTitle: {
      fa: 'اتوماسيون اداري_الگو پيام',
      en: 'اتوماسيون اداري_الگو پيام',
      ar: 'اتوماسيون اداري_الگو پيام',
    },
    translatedComment: null,
    treeLevel: 0,
    forceTreeLevel: false,
    remoteSearch: false,
    parameters: [],
    maps: [],
    columns: [
      {
        isHidden: false,
        width: 100,
        priority: 0,
        title: 'templates_id',
        format: null,
        dataType: {
          erp: 'bigint',
          sql: 'bigint',
          simple: 'number',
          id: 127,
          defaultOperator: 'Equals',
        },
        translatedTitle: {
          fa: 'Templates_ID',
          en: 'Templates_ID',
          ar: 'Templates_ID',
        },
      },
      {
        isHidden: false,
        width: 100,
        priority: 1,
        title: 'title',
        format: null,
        dataType: {
          erp: 'string',
          sql: 'nvarchar(max)',
          simple: 'string',
          id: 167,
          defaultOperator: 'Contains',
        },
        translatedTitle: {
          fa: 'عنوان',
          en: 'Name',
          ar: 'العنوان',
        },
      },
    ],
    table: null,
  },
  report: null,
  minValue: null,
  maxValue: null,
  values: null,
  precision: 0,
  multiLanguage: false,
  relatedLocaleFieldId: null,
  multiSelectDropdown: null,
  relatedParameterName: null,
  hidden: false,
  hasViewPermission: false,
  hasEditPermission: false,
  format: '',
  uiVisible: null,
  uiEnable: null,
  defaultValueGlobalParameter: null,
  javaScriptFormula: '',
  codingPattern: null,
  dropdownValueMemberVisibility: false,
  isUniqueInGrid: false,
  isMapped: false,
  groupingPriority: 0,
  treeParentField: null,
  javaScriptUiEnable: null,
  javaScriptUiVisible: null,
};

export const fieldName = 'templates_id';

export const fieldId = 63960;

export const dropdownId = 6489;

export const dropdownMeta = {
  id: 6489,
  uniqueId: 'cbdce7d7-d2a4-4d03-95fb-bfe2d4baca89',
  comment: '',
  displayMember: 'title',
  displayMember2: null,
  valueMember: 'templates_id',
  moduleName: 'automation',
  tableName: 'templates',
  type: 'Simple',
  title: 'اتوماسيون اداري_الگو پيام',
  translatedTitle: {
    fa: 'اتوماسيون اداري_الگو پيام',
    en: 'اتوماسيون اداري_الگو پيام',
    ar: 'اتوماسيون اداري_الگو پيام',
  },
  translatedComment: null,
  treeLevel: 0,
  forceTreeLevel: false,
  remoteSearch: false,
  parameters: [],
  maps: [],
  columns: [
    {
      isHidden: false,
      width: 100,
      priority: 0,
      title: 'templates_id',
      format: null,
      dataType: {
        erp: 'bigint',
        sql: 'bigint',
        simple: 'number',
        id: 127,
        defaultOperator: 'Equals',
      },
      translatedTitle: {
        fa: 'Templates_ID',
        en: 'Templates_ID',
        ar: 'Templates_ID',
      },
    },
    {
      isHidden: false,
      width: 100,
      priority: 1,
      title: 'title',
      format: null,
      dataType: {
        erp: 'string',
        sql: 'nvarchar(max)',
        simple: 'string',
        id: 167,
        defaultOperator: 'Contains',
      },
      translatedTitle: {
        fa: 'عنوان',
        en: 'Name',
        ar: 'العنوان',
      },
    },
  ],
  table: null,
};
