import { ReactElement } from 'react';
import { Button, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';

import {
  ButtonInputValuesType,
  ButtonInputViewInterface,
} from './button-input.type';
import { useStyles } from './button-input.style';
import { ButtonGroup } from './button-group';
import { ValidationAction } from '../../../helper/Types';

const ButtonInputView = (props: ButtonInputViewInterface): ReactElement => {
  const {
    field,
    actions,
    handleRunService,
    visibleClass,
    disabled,
    customTestAttribute,
  } = props;

  const classes = useStyles();

  const { caption, values } = field;

  /**
   * @function renderView
   * @returns { ReactElement }
   */
  const renderView = (): ReactElement => {
    if (actions?.length && actions.length > 1) {
      return (
        <ButtonGroup
          actions={actions}
          handleRunService={handleRunService}
          caption={caption}
          iconName={(values as unknown as ButtonInputValuesType)?.icon?.fontName}
          visibleClass={visibleClass}
          disabled={disabled}
          customTestAttribute={customTestAttribute}
        />
      );
    } else {
      return (
        <Tooltip title={caption}>
          <div className={classes.container}>
            <Button
              className={classNames(classes.button, visibleClass)}
              disabled={disabled}
              variant="contained"
              color="secondary"
              onClick={() => handleRunService(actions?.[0] as ValidationAction)}
              startIcon={
                <i
                  className={classNames(
                    classes.icon,
                    `fa fa-${
                      (values as unknown as ButtonInputValuesType)?.icon?.fontName
                    }`,
                  )}
                ></i>
              }
              {...customTestAttribute}
            >
              <Typography variant="button" noWrap>
                {caption}
              </Typography>
            </Button>
          </div>
        </Tooltip>
      );
    }
  };

  return renderView();
};

export default ButtonInputView;
