import { FC, memo } from 'react';

import { AppFooterInterface } from './app-footer.type';
import AppFooterView from './app-footer.view';

const AppFooterController: FC<AppFooterInterface> = memo(props => {
  const { children, className } = props;

  return <AppFooterView children={children} className={className} />;
});

export default AppFooterController;
