import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

function DraftIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1797 6.50826L18.88 7.80793L16.1907 5.11859L17.4903 3.81892C17.5874 3.72182 17.7426 3.72182 17.8397 3.81892L20.1797 6.15892C20.2768 6.25603 20.2768 6.41116 20.1797 6.50826ZM6.43934 20.2486H3.75V17.5593L14.06 7.24925L16.7493 9.93859L6.43934 20.2486Z"
        stroke="#666666"
        stroke-width="1.5"
      />
    </svg>
  );
}

export default withStyles(styles)(DraftIcon);
