import { FC, memo, useState } from 'react';

import { MoreOptionsInterface } from './more-options.type';
import MoreOptionsView from './more-options.view';

const MoreOptionsController: FC<MoreOptionsInterface> = memo(props => {
  const { onImportantClick, onMarkUnReadClick, onStarClick } = props;
  const [isMoreIcon, onOpenMoreIcon] = useState<(EventTarget & HTMLElement) | null>(
    null,
  );

  /**
   * @function onViewMoreOpen
   * @param event
   * @returns { void }
   */
  const onViewMoreOpen = (event: any): void => {
    onOpenMoreIcon(event.currentTarget);
  };

  /**
   * @function onViewMoreClose
   * @returns { void }
   */
  const onViewMoreClose = () => {
    onOpenMoreIcon(null);
  };

  return (
    <MoreOptionsView
      onViewMoreOpen={onViewMoreOpen}
      onViewMoreClose={onViewMoreClose}
      isMoreIcon={isMoreIcon}
      onStarClick={onStarClick}
      onImportantClick={onImportantClick}
      onMarkUnReadClick={onMarkUnReadClick}
    />
  );
});

export default MoreOptionsController;
