import { isEmpty, isEmptyObject } from '../../helper/data-helper';
import { PrepareLink } from './breadcrumb.type';

/**
 * check `meta` for create a link.
 * @function prepareLink
 * @param {string} resource
 * @param {object} metaData
 * @returns {string}
 */
export const prepareLink: PrepareLink = (resource, metaData) => {
  if (!isEmptyObject(metaData?.config)) {
    const { treeLevel, hasOneRow } = metaData.config!; // It's surely doesn't equal null or undefined

    if (!isEmpty(treeLevel)) {
      return `/tree/${resource}`;
    } else if (hasOneRow) {
      return `/single-record/${resource}`;
    }
  }

  return `/${resource}`;
};
