import { Dialog, DialogTitle } from '@material-ui/core';
import { ReactElement } from 'react';
import { useLocale } from 'react-admin';

import { getTranslatedName } from '../../../helper/MetaHelper';
import { CustomForm, FormController } from '../../form';
import { QuickEditButtonToolbar } from '../quick-edit-button-toolbar';
import { QuickEditDialogForm } from '../quick-edit-dialog-form';
import { useStyles } from './quick-edit-dialog.style';
import { QuickEditDialogViewInterface } from './quick-edit-dialog.type';

const QuickEditDialogView = (props: QuickEditDialogViewInterface): ReactElement => {
  const { isOpen, closeDialog, fields, resource, id, metaData, ...rest } = props;
  const classes = useStyles();
  const locale = useLocale();

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      maxWidth="sm"
      fullWidth
      aria-labelledby="show-image-dialog-title"
      classes={{
        paperFullWidth: classes.dialog,
      }}
    >
      <DialogTitle id="show-image-dialog-title">
        {getTranslatedName(metaData, locale)}
      </DialogTitle>
      <FormController
        {...rest}
        formName="quickEditDialog"
        isQuickForm
        disableValidationErrorNotification
      >
        <CustomForm
          isBottomToolbar
          Toolbar={
            <QuickEditButtonToolbar
              resource={resource}
              closeDialog={closeDialog}
              redirect={false}
              id={id}
            />
          }
        >
          <QuickEditDialogForm fields={fields} />
        </CustomForm>
      </FormController>
    </Dialog>
  );
};

export default QuickEditDialogView;
