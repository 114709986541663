import { LinkType } from '.';

export const quickAccessMenuResource = 'report/7754341d-3191-4ad5-a05e-81a08da6e540';

/**
 * @function CreateLinkTarget
 * @param { LinkType } linkType
 * @returns { string }
 */
export const CreateLinkTarget = (linkType: LinkType): string => {
  switch (linkType) {
    case 'newtab':
      return '_blank';

    case 'self':
      return '_self';
  }
};
