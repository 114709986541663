import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { translate } from 'react-admin';
import classNames from 'classnames';

import { CONFIG_CURRENCY_SYMBOL, getValue } from '../../core/configProvider';

const useStyles = makeStyles(theme => ({
  invoiceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  total: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
  },

  tax: {
    '& > p': {
      fontWeight: 'bold',
    },
  },

  invoice: {},
}));

const PointOfSaleInvoice = props => {
  const { className, translate } = props;
  const classes = useStyles();

  return (
    <div className={classNames(className, classes.invoice)}>
      <div className={classes.invoiceItem}>
        <Typography variant="body1" color="secondary">
          {translate('pos.discount')}
        </Typography>
        <Typography variant="body1" color="secondary">
          0 {getValue(CONFIG_CURRENCY_SYMBOL) || translate('shop.basket.currency')}
        </Typography>
      </div>
      <div className={classes.invoiceItem}>
        <Typography variant="body1">{translate('pos.subtotal')}</Typography>
        <Typography variant="body1">
          2000000{' '}
          {getValue(CONFIG_CURRENCY_SYMBOL) || translate('shop.basket.currency')}
        </Typography>
      </div>
      <div className={classNames(classes.invoiceItem, classes.tax)}>
        <Typography variant="body1">{translate('pos.tax')}</Typography>
        <Typography variant="body1">
          0 {getValue(CONFIG_CURRENCY_SYMBOL) || translate('shop.basket.currency')}
        </Typography>
      </div>
      <div className={classNames(classes.total)}>
        <Typography variant="body1">{translate('pos.total')}</Typography>
        <Typography variant="body1">
          2000000{' '}
          {getValue(CONFIG_CURRENCY_SYMBOL) || translate('shop.basket.currency')}
        </Typography>
      </div>
    </div>
  );
};

PointOfSaleInvoice.propTypes = {
  className: PropTypes.string,
};

export default compose(translate)(PointOfSaleInvoice);
