import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '50%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      flexWrap: 'noWrap',
      padding: '2rem',
    },
  },

  inputContainer: {
    flexGrow: 1,
    maxHeight: '40px',
    width: 400,
    margin: '1rem 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
