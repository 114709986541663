import { Children, cloneElement } from 'react';

// onClick don't set on Children because called on the TabParent
const TabChild = props => {
  const { children = null, onClick = null, label, ...rest } = props;

  return Children.map(children, child => {
    return child ? cloneElement(child, rest) : null;
  });
};

export default TabChild;
