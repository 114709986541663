import { ReactElement } from 'react';
import clsx from 'classnames';
import { AppHeaderInterface } from './app-header.type';
import { useStyles } from './app-header.style';
import Box from '@material-ui/core/Box';

const AppHeaderView = (props: AppHeaderInterface): ReactElement => {
  const { children, className } = props;
  const classes = useStyles();

  return (
    <Box px={6} py={{ xs: 1, xl: 3 }} className={clsx(classes.appHeader, className)}>
      {children}
    </Box>
  );
};

export default AppHeaderView;
