import { FC, useState, useEffect, useRef } from 'react';
import { useLocale } from 'react-admin';

import { actorGetActionValue, actorOnDispatch } from '../../type/actor-setup';
import { getTranslatedName } from '../../helper/MetaHelper';
import { BreadcrumbController } from './breadcrumb.type';
import { prepareLink } from './breadcrumb.helper';
import BreadcrumbView from './breadcrumb.view';
import { MetaDataBase } from '../../helper/Types';

export const Breadcrumb: FC<BreadcrumbController> = props => {
  const { isHeaderPage, moduleName, moduleTableName, parentInformation } = props;
  const { parentResource, parentTitle } = parentInformation ?? {};
  const resource = `${moduleName}/${moduleTableName}`;

  const [currentMetaData, setCurrentMetaData] = useState<MetaDataBase | null>(
    actorGetActionValue('metaData', resource) as null | unknown as MetaDataBase,
  );

  const resourceAccessRef = useRef(resource);

  useEffect(() => {
    resourceAccessRef.current = resource;
  }, [resource]);

  useEffect(() => {
    actorOnDispatch('metaData', _metaData => {
      const metaDataInActor = _metaData?.[resourceAccessRef.current] as MetaDataBase;
      setCurrentMetaData(metaDataInActor);
    });

    actorOnDispatch('urlInfo', () => {
      const metaDataInActor = actorGetActionValue('metaData', [
        resourceAccessRef.current,
      ]) as unknown as MetaDataBase | null;

      setCurrentMetaData(metaDataInActor);
    });
  }, []);

  let preparedTitle: string | null = null;
  const locale = useLocale();

  let preparedLink: string | null = null;

  // create link and title for simple resources
  if (!parentInformation && currentMetaData) {
    preparedLink = prepareLink(resource, currentMetaData);
    preparedTitle = getTranslatedName(currentMetaData, locale);
  }

  // create link for `header & details` resources for header
  if (parentInformation && parentTitle && isHeaderPage) {
    preparedLink = `/${parentResource}`;
    preparedTitle = parentTitle;
  }

  // create title for `header & details` resources for detail
  if (parentInformation && !isHeaderPage) {
    const currentMetaData = actorGetActionValue('metaData', resource)!;
    preparedTitle = getTranslatedName(currentMetaData, locale);
  }

  const isReady = !!(preparedTitle && preparedLink);

  return (
    <BreadcrumbView
      isSimpleBreadcrumb={!parentInformation}
      isReady={isReady}
      link={preparedLink}
      title={preparedTitle}
    />
  );
};
