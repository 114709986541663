import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function ArchiveIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.4595 14.5H19H19.5V14V5V4.5H19H4.99H4.48944L4.49 5.00056L4.5 14.0006L4.50056 14.5H5H9.56046C9.79341 15.6383 10.805 16.5 12.01 16.5C13.215 16.5 14.2266 15.6383 14.4595 14.5ZM12.95 9V9.5H13.45H14.7929L12 12.2929L9.20711 9.5H10.55H11.05V9V6.5H12.95V9ZM19 19.5H19.5V19V16V15.5H19H15.46H15.174L15.029 15.7465C14.412 16.7955 13.2996 17.5 12.01 17.5C10.7185 17.5 9.59834 16.794 8.99255 15.7492L8.84806 15.5H8.56H5H4.5V16V19V19.5H5H19ZM4.99 3.5H19C19.8239 3.5 20.5 4.17614 20.5 5V19C20.5 19.8239 19.8239 20.5 19 20.5H4.99C4.16103 20.5 3.5 19.8288 3.5 19V5C3.5 4.17123 4.16103 3.5 4.99 3.5Z"
        stroke="#666666"
      />
    </SvgIcon>
  );
}

export default withStyles(styles)(ArchiveIcon);
