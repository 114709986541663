import {
  SERVICE_WORKER_CACHE_VERSION,
  setValue,
  getValue,
} from '../core/configProvider';

export const checkCache = async (currentMetaVersion: string): Promise<void> => {
  if (!('serviceWorker' in navigator)) return;

  const savedMetaVersion = await getValue(SERVICE_WORKER_CACHE_VERSION);

  if (!('serviceWorker' in navigator)) return;

  if (savedMetaVersion) {
    if (savedMetaVersion != currentMetaVersion) {
      caches?.keys().then(async cacheList => {
        for (const cacheName of cacheList) await caches.delete(cacheName);
      });
      setValue(SERVICE_WORKER_CACHE_VERSION, currentMetaVersion);
    }
  } else {
    setValue(SERVICE_WORKER_CACHE_VERSION, currentMetaVersion);
  }
};
