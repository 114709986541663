import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { RelationEditButtonViewInterface } from './relation-edit-button.type';
import { IconButton, Icon, Tooltip } from '@material-ui/core';
import { useStyles } from './relation-edit-button.style';

const RelationEditButtonView = (
  props: RelationEditButtonViewInterface,
): ReactElement => {
  const { disabled, iconClassName, onClick, onTouchStart } = props;
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Tooltip title={translate('ra.action.edit')}>
      <IconButton
        color="primary"
        className={classes.iconButton}
        disabled={disabled}
        onClick={onClick}
        onTouchStart={onTouchStart}
      >
        <Icon fontSize="small" className={iconClassName}>
          create
        </Icon>
      </IconButton>
    </Tooltip>
  );
};

export default RelationEditButtonView;
