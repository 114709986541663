import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  sortableItem: {
    margin: 0,
    padding: 0,
  },

  searchColumn: {
    display: 'flex',
    margin: '30px 0',
    '&:focus': {
      outline: 'none',
    },
  },

  icon: {
    fontSize: 20,
  },

  IconButton: {
    padding: 7,
    margin: '2px 5px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px',
      margin: 0,
    },
  },

  information: {
    display: 'flex',
    padding: '15px 0 0',
    alignItems: 'center',
    '& span': {
      color: theme.palette.text.hint,
    },
  },
}));
