import { ReactElement } from 'react';
import { AccessPathViewInterface } from './access-path.type';
import { useStyles } from './access-path.style';
import MuiButton from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import { Icon } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const AccessPathView = (props: AccessPathViewInterface): ReactElement => {
  const { labelPath } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.root}>
      <MuiButton
        className={classes.iconButton}
        component={RouterLink}
        color="inherit"
        to="/"
      >
        <Typography
          className={classes.homeTypography}
          component="span"
          variant={'body2'}
          color="textSecondary"
        >
          <Icon fontSize={'small'}>home</Icon>&nbsp;
          {translate('menu.home')}/
        </Typography>
      </MuiButton>
      <Typography component="span" variant={'body2'} color="textSecondary">
        {labelPath}
      </Typography>
    </div>
  );
};

export default AccessPathView;
