import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    width: 150,
  },

  icon: {
    fontSize: '15px !important',
  },
}));
