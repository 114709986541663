import { makeStyles } from '@material-ui/core';
import type { CustomTheme } from '../../../core/themeProvider';
import type { ValidationErrorMessageType } from '../../../helper/Types';
import { getColorBaseOfStatus } from './input-message.helper';

export const useStyles = makeStyles<
  CustomTheme,
  { messageType: ValidationErrorMessageType }
>(theme => {
  return {
    arrow: {
      color: theme.palette.error.main,
    },

    tooltip: {
      backgroundColor: props => getColorBaseOfStatus(props.messageType, theme),
      fontSize: 12,
      padding: '6px 10px',
    },

    icon: {
      color: props => getColorBaseOfStatus(props.messageType, theme),
      cursor: 'pointer',
      transition: 'opacity 200ms',
      flexBasis: 25,
    },

    hiddenError: {
      opacity: 0,
      transition: 'opacity 200ms',
      cursor: 'auto',
    },
  };
});
