import { ReactElement } from 'react';

import { NewFolderFormViewInterface } from '.';
import { FormController } from '../../form';
import { SimpleForm } from '../../form/simple-form';
import { useStyles } from './mail-fom.style';

const NewFolderFormView = (props: NewFolderFormViewInterface): ReactElement => {
  const { fields } = props;

  const classes = useStyles();

  return (
    <FormController formName="simpleForm">
      <SimpleForm fields={fields} className={classes.simpleFormContainer} />
    </FormController>
  );
};

export default NewFolderFormView;
