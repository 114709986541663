import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function RemindNextWeekIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M13.1035 14.8535l-.707-.707 2.146-2.147-2.146-2.146.707-.707 2.854 2.853zm-4 0l-.707-.707 2.146-2.147-2.146-2.146.707-.707 2.854 2.853z"></path>
      <path d="M5 12c0 3.859 3.14 7 7 7s7-3.141 7-7-3.14-7-7-7-7 3.141-7 7zm-1 0c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8-8-3.589-8-8z"></path>
    </SvgIcon>
  );
}

export default withStyles(styles)(RemindNextWeekIcon);
