import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 40,
  },

  noteListContainer: {
    overflow: 'hidden',
    transition: 'all 400ms',
  },

  loadMore: {
    marginTop: 10,
  },
}));
