import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  emptyListContainer: {
    flexDirection: 'column',
    minHeight: 450,
    height: '100%',
    flex: 1,
    display: 'flex',
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    border: theme.palette.grey[500],
    borderRadius: 4,
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: 'row',
  },
}));
