import jalaliMoment from 'jalali-moment';
import lodashGet from 'lodash/get';

import { DatePickerFormat, DatePickerType } from '.';
import { FieldType } from '../../../helper/Types';

/**
 * @function getDatePickerType
 * @param { FieldType } field
 * @returns { DatePickerType }
 */
export const getDatePickerType = (field: FieldType): DatePickerType =>
  lodashGet(field, 'dataType.simple') === 'datetime' ? 'dateTime' : 'simple';

/**
 * @function convertJalaliToGregorian
 * @param date in 'YYYY/MM/DD' || 'YYYY/MM/DD HH:mm' format
 * @param { DatePickerType } type
 * @returns { string } in 'YYYY-MM-DD' || 'YYYY/MM/DD HH:mm' format
 */
export const convertJalaliToGregorian = (
  date: string,
  type: DatePickerType,
): string => {
  return jalaliMoment
    .from(date, 'fa', DatePickerFormat[type])
    .locale('en')
    .format(DatePickerFormat[type]);
};

/**
 * @function isLeapYear
 * @param { number } year
 * @returns { boolean }
 */
export const isLeapYear = (year: number): boolean => {
  const matches = [1, 5, 9, 13, 17, 22, 26, 30];
  const modulus = year - Math.floor(year / 33) * 33;
  let isLeapYear = false;

  for (let i = 0; i != 8; i++) {
    if (matches[i] == modulus) {
      isLeapYear = true;
    }
  }

  return isLeapYear;
};

/**
 * when it's not leap year and when it's last month of year
 * day should not be more than 29
 *
 *
 * @function isInvalidDateForNotLeapYear
 * @param { isInvalidDateForNotLeapYear } date in 'YYYY/MM/DD' format
 * @returns { boolean }
 */
export const isInvalidDateForNotLeapYear = (date: string): boolean => {
  const splittedDateTime = date.split(' ');
  const splittedDate = splittedDateTime[0].split('/');

  const month = splittedDate[1];
  const day = splittedDate[2];

  return +month === 12 && +day > 29;
};

/**
 * @function formatInvalidDateForNotLeapYear
 * @param { string } date in 'YYYY/MM/DD' format
 * @returns { string }
 */
export const formatInvalidDateForNotLeapYear = (date: string): string => {
  const splittedDateTime = date.split(' ');
  const splittedDate = splittedDateTime[0].split('/');

  const year = splittedDate[0];
  const month = splittedDate[1];
  const day = splittedDate[2];

  const newDay = +day > 29 ? 29 : day;

  return `${year}/${month}/${newDay}${splittedDateTime[1]}`;
};

export const CleaveInputDateTimeOptions = {
  numericOnly: true,
  delimiters: ['/', '/', ' ', ':'],
  blocks: [4, 2, 2, 2, 2],
};

export const CleaveInputDateOptions = {
  numericOnly: true,
  delimiters: ['/', '/'],
  blocks: [4, 2, 2],
};

export const checkValueMessageType = (messageType: string): string => {
  switch (messageType) {
    case 'warning':
      return 'warningDatePicker';
    case 'info':
      return 'infoDatePicker';
    case 'success':
      return 'successDatePickerContainer';
    case 'error':
      return 'errorDatePickerContainer';
    default:
      return 'noError';
  }
};
