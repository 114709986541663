import PropTypes from 'prop-types';
import { Children, cloneElement, useContext, useRef } from 'react';
import LoadingBox from '../component/LoadingBox';
import { NewMetaContext } from './NewMetaContext';

const MetaProvider = ({
  resourceName,
  children,
  noLoading = false,
  metaField = 'metaData',
  ...rest
}) => {
  const { getMeta } = useContext(NewMetaContext);
  const metaData = useRef(null);
  const resource = useRef(resourceName);

  if (!metaData.current || resource.current !== resourceName) {
    const newData = getMeta(resourceName);
    if (newData) {
      metaData.current = newData;
      resource.current = resourceName;
    }
  }

  if (!noLoading && !metaData) {
    return <LoadingBox />;
  }

  return Children.map(children, element =>
    cloneElement(element, {
      ...rest,
      [metaField]: metaData.current,
      getMeta: getMeta,
    }),
  );
};

MetaProvider.propTypes = {
  children: PropTypes.any.isRequired,
  resourceName: PropTypes.string.isRequired,
  metaField: PropTypes.string,
  noLoading: PropTypes.bool,
};

export default MetaProvider;
