import { FC, memo, useState } from 'react';
import { MenuAccordionControllerInterface } from './menu-accordion.type';
import MenuAccordionView from './menu-accordion.view';

const MenuAccordionController: FC<MenuAccordionControllerInterface> = memo(props => {
  const {
    children,
    title,
    Icon,
    containerClassName,
    titleClassName,
    collapseClassName,
    isArrowShow,
    onClick,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  /**
   * @function onToggle
   * @returns {void}
   */
  const onToggle = (): void => {
    setIsOpen(prev => !prev);
  };
  return (
    <MenuAccordionView
      title={title}
      Icon={Icon}
      containerClassName={containerClassName}
      titleClassName={titleClassName}
      collapseClassName={collapseClassName}
      isArrowShow={isArrowShow}
      isOpen={isOpen}
      onToggle={onToggle}
      onClick={onClick}
      children={children}
    />
  );
});

export default MenuAccordionController;
