import { FC, memo } from 'react';

import { MailFormActionInterface } from './mail-form-action.type';
import MailFormActionView from './mail-form-action.view';

const MailFormActionController: FC<MailFormActionInterface> = memo(props => {
  const { onSubmit, onCancel } = props;

  return <MailFormActionView onSubmit={onSubmit} onCancel={onCancel} />;
});

export default MailFormActionController;
