import { FC, memo, useEffect, useState } from 'react';

import { isEmpty, isEmptyObject } from '../../../helper/data-helper';
import { IconInputInterface } from './icon-input.type';
import IconInputView from './icon-input.view';
import { actorGetActionValue } from '../../../type/actor-setup';

const IconInputController: FC<IconInputInterface> = memo(props => {
  const { field, value, label, inputRef } = props;
  const { relatedName, name } = field;
  const currentResource = actorGetActionValue('resources')!.current;
  const formData =
    (actorGetActionValue('formData', [
      currentResource.value,
      currentResource.type,
    ]) as FormData | null) ?? {};

  const [icon, setIcon] = useState<string>('');

  /**
   * this function get value from record
   * @function getIcon
   * @returns {string}
   */
  const getIcon = () => {
    let _value = '';
    if (!isEmptyObject(value) && !isEmpty(formData[relatedName])) {
      _value = formData[relatedName];
    }
    return _value;
  };

  useEffect(() => {
    setIcon(getIcon());
  }, [value]);

  return (
    <IconInputView tooltip={label} icon={icon} name={name} inputRef={inputRef} />
  );
});

export default IconInputController;
