import { FC, memo } from 'react';

import { ConfirmDialogInterface } from './confirm-dialog.type';
import ConfirmDialogView from './confirm-dialog.view';

const ConfirmDialogController: FC<ConfirmDialogInterface> = memo(props => {
  return <ConfirmDialogView {...props} />;
});

export default ConfirmDialogController;
