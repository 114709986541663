import { FC, memo } from 'react';
import { DraggedCalendarEventInterface } from '.';

import { EventsCalendarInterface } from './events-calendar.type';
import EventsCalendarView from './events-calendar.view';

const EventsCalendarController: FC<EventsCalendarInterface> = memo(props => {
  const { setDraggedEvent } = props;

  /**
   * this function send drag item data
   * @function handleDragStart
   * @param {DraggedCalendarEventInterface} event
   * @returns {void}
   */
  const handleDragStart = (event: DraggedCalendarEventInterface): void => {
    setDraggedEvent(event);
  };
  return <EventsCalendarView handleDragStart={handleDragStart} />;
});

export default EventsCalendarController;
