import { FC, memo } from 'react';
import { useLocale } from 'react-admin';
import NewWindow from '../../NewWindow';
import { FullScreenContentInterface } from '.';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import momentJalaali from 'moment-jalaali';
import { useStyles } from './post-card.style';
import {
  gregorianDateTimeFormat,
  jalaliDateTimeFormat,
} from '../../../helper/CalendarMetaHelper';
import { RichTextEditorField } from '../../rich-text-editor-field';
import { removePageBreakFromHtmlString } from '../../rich-text-editor-field/rich-text-editor-field.helper';
import { isEmpty } from '../../../helper/data-helper';

export const FullContentDialogPopOut: FC<FullScreenContentInterface> = memo(
  props => {
    const { post, imageUrl, onCloseShowFullContent } = props;
    const { annsubject, anntexthtml, createdate } = post;

    const classes = useStyles();
    const locale = useLocale();

    return (
      <NewWindow
        title={post.annsubject}
        features={{
          height: +screen.height,
          width: +screen.width,
          top: 0,
          left: 0,
          fullscreen: 'yes',
        }}
        copyStyles={true}
        onUnload={onCloseShowFullContent}
        onBlock={onCloseShowFullContent}
      >
        <div
          style={{
            background: '#fff',
            direction: locale === 'fa' ? 'rtl' : 'ltr',
            padding: '4rem 8rem',
          }}
        >
          <Grid item xs={12} className={classes.container}>
            {!isEmpty(imageUrl) && (
              <CardMedia
                component="img"
                className={classes.image}
                image={imageUrl}
                alt={annsubject}
              />
            )}
            <Typography component="h2" variant="h5">
              {annsubject}
            </Typography>
            <Typography
              style={{ marginBottom: '1rem' }}
              variant="subtitle1"
              color="textSecondary"
            >
              {locale === 'fa'
                ? momentJalaali(createdate).format(jalaliDateTimeFormat)
                : momentJalaali(createdate).format(gregorianDateTimeFormat)}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              <RichTextEditorField
                externalValue={removePageBreakFromHtmlString(anntexthtml)}
                isPrintMode
              />
            </Typography>
          </Grid>
        </div>
      </NewWindow>
    );
  },
);
