import { ReactElement } from 'react';
import { ControlViewInterface } from './control.type';
import TextField from '@material-ui/core/TextField';

const ControlView = (props: ControlViewInterface): ReactElement => {
  const {
    selectProps,
    children,
    handleChangeText,
    inputComponent,
    innerRef,
    errorMessage,
  } = props;
  const { classes, textFieldProps } = selectProps;

  return (
    <TextField
      className={classes.control}
      variant="outlined"
      error={!!errorMessage}
      //@ts-ignore
      {...textFieldProps}
      onChange={handleChangeText}
      helperText={errorMessage}
      InputProps={{
        //@ts-ignore
        inputComponent,
        inputProps: {
          ...props.innerProps,
          className: classes.input,
          inputRef: innerRef,
          children: children,
        },
        classes: {
          error: classes.error,
        },
      }}
    />
  );
};

export default ControlView;
