import { MenuItemParams } from '../menu/sidebar-menu';

/**
 * function that define top header item
 * @function isTopHeaderItem
 * @param {number} id
 * @param {Object[]} item
 * @returns {boolean}
 */

export const isTopHeaderItem = (item: MenuItemParams): boolean => {
  return item.currentLevel === 0;
};

/**
 * function that define that the item's parent has not parent (define top-header item)
 * @function isHeaderItem
 * @param {number} id
 * @param {Object[]} item
 * @returns {boolean}
 */
export const isHeaderItem = (id: number, item: Object[]): boolean => {
  return item.includes(id);
};
