import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  table: {
    borderCollapse: 'unset',
    borderSpacing: '15px',
    tableLayout: 'fixed',
  },

  tableCell: {
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: 8,
    [theme.breakpoints.up('lg')]: {
      height: 40,
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
      marginBottom: 5,
    },

    '& .quickEditButton': {
      transition: 'opacity 200ms',
      opacity: 0,
    },
    '&:hover .quickEditButton': {
      transition: 'opacity 200ms',
      opacity: 1,
    },
  },

  emptyTableCell: {
    [theme.breakpoints.up('lg')]: {
      height: 40,
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
    },
  },
}));
