import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { Paper, Typography, Button, Icon, Link } from '@material-ui/core';
import classNames from 'classnames';

import PointOfSaleSearch from './PointOfSaleSearch';
import PointOfSaleInvoice from './PointOfSaleInvoice';
import PointOfSaleProductList from './PointOfSaleProductList';
import PointOfSaleCalculator from './PointOfSaleCalculator';
import PointOfSaleCustomer from './PointOfSaleCustomer';
import { CONFIG_CURRENCY_SYMBOL, getValue } from '../../core/configProvider';

const productList = [
  {
    id: 1,
    imgUrl:
      'https://guesseu.scene7.com/is/image/GuessEU/M63H24W7JF0-L302-ALTGHOST?wid=1500&fmt=jpeg&qlt=80&op_sharpen=0&op_usm=1.0,1.0,5,0&iccEmbed=0',
    name: 'لباس بهاری قرمز',
    price: 1100000,
  },
  {
    id: 2,
    imgUrl:
      'https://guesseu.scene7.com/is/image/GuessEU/FLGLO4FAL12-BEIBR?wid=700&amp;fmt=jpeg&amp;qlt=80&amp;op_sharpen=0&amp;op_usm=1.0,1.0,5,0&amp;iccEmbed=0',
    name: 'کفش قهوه ای که جلوش طلایی کار شده کفش قهوه ای که جلوش طلایی کار شده کفش قهوه ای که جلوش طلایی کار شده کفش قهوه کفش قهوه ای که جلوش طلایی کار شده کفش قهوه ای که جلوش طلایی کار شده کفش قهوه ای که جلوش طلایی ک کفش قهوه ای که جلوش طلایی کار شده کفش قهوه ای که جلوش طلایی کار شده کفش قهوه ای که جلوش طلایی کار شده کفش قهوه ای که جلوش طلایی کار شده ار شده ای که جلوش طلایی کار شده',
    price: 9103202320093000,
  },
  {
    id: 3,
    imgUrl:
      'https://guesseu.scene7.com/is/image/GuessEU/HWVG6216060-TAN?wid=700&amp;fmt=jpeg&amp;qlt=80&amp;op_sharpen=0&amp;op_usm=1.0,1.0,5,0&amp;iccEmbed=0',
    name: 'کیف',
    price: 945000,
  },
  {
    id: 4,
    imgUrl:
      'http://guesseu.scene7.com/is/image/GuessEU/WC0001FMSWC-G5?wid=520&fmt=jpeg&qlt=80&op_sharpen=0&op_usm=1.0,1.0,5,0&iccEmbed=0',
    name: 'ساعت باحال',
    price: 4380000,
  },
  {
    id: 5,
    imgUrl:
      'https://guesseu.scene7.com/is/image/GuessEU/AW6308VIS03-SAP?wid=700&amp;fmt=jpeg&amp;qlt=80&amp;op_sharpen=0&amp;op_usm=1.0,1.0,5,0&amp;iccEmbed=0',
    name: 'روسری گوشه قرمز',
    price: 2000000,
  },
  {
    id: 6,
    imgUrl:
      'https://guesseu.scene7.com/is/image/GuessEU/M63H24W7JF0-L302-ALTGHOST?wid=1500&fmt=jpeg&qlt=80&op_sharpen=0&op_usm=1.0,1.0,5,0&iccEmbed=0',
    name: 'لباس آستین دار و دکمه دار',
    price: 1100000,
  },
  {
    id: 7,
    imgUrl:
      'https://guesseu.scene7.com/is/image/GuessEU/FLGLO4FAL12-BEIBR?wid=700&amp;fmt=jpeg&amp;qlt=80&amp;op_sharpen=0&amp;op_usm=1.0,1.0,5,0&amp;iccEmbed=0',
    name: 'کفش ساق بلند',
    price: 3910000,
  },
  {
    id: 8,
    imgUrl:
      'https://guesseu.scene7.com/is/image/GuessEU/HWVG6216060-TAN?wid=700&amp;fmt=jpeg&amp;qlt=80&amp;op_sharpen=0&amp;op_usm=1.0,1.0,5,0&amp;iccEmbed=0',
    name: 'کیف بند دار قهوه ای',
    price: 1945000,
  },
  {
    id: 9,
    imgUrl:
      'http://guesseu.scene7.com/is/image/GuessEU/WC0001FMSWC-G5?wid=520&fmt=jpeg&qlt=80&op_sharpen=0&op_usm=1.0,1.0,5,0&iccEmbed=0',
    name: 'ساعت با بند مشکی',
    price: 4380000,
  },
  {
    id: 10,
    imgUrl:
      'https://guesseu.scene7.com/is/image/GuessEU/AW6308VIS03-SAP?wid=700&amp;fmt=jpeg&amp;qlt=80&amp;op_sharpen=0&amp;op_usm=1.0,1.0,5,0&amp;iccEmbed=0',
    name: 'روسری در هم پیچیده',
    price: 4500000,
  },
];

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },

  item: {
    padding: '10px 0',
    borderBottom: `2px solid ${theme.palette.divider}`,
  },

  invoiceContainer: {
    padding: 20,
    flexBasis: '35%',
    margin: 25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  searchAndCategoryContainer: {
    flexGrow: 1,
    margin: 25,
    display: 'flex',
    flexDirection: 'column',
  },

  customer: {
    display: 'flex',
    paddingTop: 0,
    alignItems: 'center',
    '& span': {
      margin: '0 5px',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },

  invoiceAction: {
    margin: '10px 0',
  },

  invoiceActionButton: {
    color: '#337ab7',
  },

  invoicePay: {
    padding: 18,
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: 'none',
  },

  invoicePayText: {
    color: '#fafafa',
  },

  note: {
    padding: 0,
  },

  textareaNote: {
    padding: 5,
    resize: 'none',
    outline: 'none',
    width: '100%',
    height: '100%',
    border: 'none',
    color: '#337ab7',
    '&:focus': {
      outline: `1px solid ${theme.palette.divider}`,
    },
  },

  categoryContainer: {
    border: '1px solid red',
    flexGrow: 1,
  },

  calculatorAndCustomerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const PointOfSaleView = props => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.container}>
      <div className={classes.searchAndCategoryContainer}>
        <PointOfSaleSearch />
        <div className={classes.categoryContainer}>
          must be use 'CategoryListView' component
        </div>
        <div className={classes.calculatorAndCustomerContainer}>
          <PointOfSaleCustomer />
          <PointOfSaleCalculator />
        </div>
      </div>
      <Paper className={classes.invoiceContainer} elevation={0}>
        <Link className={classNames(classes.item, classes.customer)}>
          <Icon color="action" fontSize="small">
            person
          </Icon>
          <span>{translate('pos.addCustomer')}</span>
        </Link>
        <PointOfSaleProductList productList={productList} className={classes.item} />
        <div className={classNames(classes.item, classes.note)}>
          <textarea
            className={classes.textareaNote}
            placeholder={translate('pos.note')}
          />
        </div>
        <PointOfSaleInvoice className={classes.item} />
        <div className={classes.invoiceAction}>
          <Button className={classes.invoiceActionButton}>
            {translate('pos.discard')}
          </Button>
          <Button className={classes.invoiceActionButton}>
            {translate('pos.park')}
          </Button>
        </div>
        <Button className={classes.invoicePay} color="secondary" variant="contained">
          <Typography variant="subtitle1" className={classes.invoicePayText}>
            {translate('pos.pay')}
          </Typography>
          <Typography variant="subtitle1" className={classes.invoicePayText}>
            2000000{' '}
            {getValue(CONFIG_CURRENCY_SYMBOL) || translate('shop.basket.currency')}
          </Typography>
        </Button>
      </Paper>
    </div>
  );
};

PointOfSaleView.propTypes = {};

export default PointOfSaleView;
