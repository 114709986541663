import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function DoubleTickIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8672 14.14L18.0002 7"
        stroke="#00AAEC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 11.8516L6.83502 16.7016C7.03448 16.894 7.30082 17.0016 7.578 17.0016C7.85518 17.0016 8.12153 16.894 8.32098 16.7016"
        stroke="#00AAEC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 11.85L10.835 16.7C11.0345 16.8925 11.3008 17 11.578 17C11.8552 17 12.1215 16.8925 12.321 16.7L22 7"
        stroke="#00AAEC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default withStyles(styles)(DoubleTickIcon);
