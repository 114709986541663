import { put, takeEvery } from 'redux-saga/effects';
import { showNotification } from '../../helper/general-function-helper';
import { NotificationType } from './action';

export interface NotificationSideEffect {
  body: string;
  level: NotificationType;
  messageArgs?: Record<string, unknown>;
}

interface ActionWithSideEffect {
  type: string;
  payload?: any;
  error: string | { message: string };
  meta: {
    notification: NotificationSideEffect;
    optimistic?: boolean;
  };
}

/**
 * Notification Side Effects
 */
function* handleNotification({
  error,
  meta: { notification, optimistic },
}: ActionWithSideEffect) {
  const { body, level, messageArgs = {} } = notification;
  if (error) {
    return yield showNotification(
      typeof error === 'string' ? error : error.message || body,
      level || 'error',
      {
        messageArgs,
        undoable: false,
      },
    );
  }
  yield showNotification(body, level || 'info', {
    messageArgs,
    undoable: optimistic,
  });
}

export default function* () {
  yield takeEvery(
    // @ts-ignore
    action => action.meta && action.meta.notification,
    handleNotification,
  );
}
