import { DateLocalizer } from './localizer';
import {
  add,
  startOf,
  max,
  min,
  eq,
  lt,
  gt,
  gte,
  lte,
  hours,
  minutes,
  seconds,
  milliseconds,
} from 'date-arithmetic';

const weekRangeFormat = ({ start, end }, culture, local) =>
  local.format(start, 'MMMM DD', culture) +
  ' – ' +
  // updated to use this localizer 'eq()' method
  local.format(end, local.eq(start, end, 'month') ? 'DD' : 'MMMM DD', culture);

const dateRangeFormat = ({ start, end }, culture, local) =>
  local.format(start, 'L', culture) + ' – ' + local.format(end, 'L', culture);

const timeRangeFormat = ({ start, end }, culture, local) =>
  local.format(start, 'LT', culture) + ' – ' + local.format(end, 'LT', culture);

const timeRangeStartFormat = ({ start }, culture, local) =>
  local.format(start, 'LT', culture) + ' – ';

const timeRangeEndFormat = ({ end }, culture, local) =>
  ' – ' + local.format(end, 'LT', culture);

export const formats = {
  dateFormat: 'DD',
  dayFormat: 'DD ddd',
  weekdayFormat: 'ddd',

  selectRangeFormat: timeRangeFormat,
  eventTimeRangeFormat: timeRangeFormat,
  eventTimeRangeStartFormat: timeRangeStartFormat,
  eventTimeRangeEndFormat: timeRangeEndFormat,

  timeGutterFormat: 'LT',

  monthHeaderFormat: 'MMMM YYYY',
  dayHeaderFormat: 'dddd MMM DD',
  dayRangeHeaderFormat: weekRangeFormat,
  agendaHeaderFormat: dateRangeFormat,

  agendaDateFormat: 'ddd MMM DD',
  agendaTimeFormat: 'LT',
  agendaTimeRangeFormat: timeRangeFormat,
};

const MILLI = {
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  day: 1000 * 60 * 60 * 24,
};

function fixUnit(unit) {
  let datePart = unit ? unit.toLowerCase() : unit;
  if (datePart === 'FullYear') {
    datePart = 'year';
  } else if (!datePart) {
    datePart = undefined;
  }
  return datePart;
}

export default function (moment) {
  const locale = (m, c) => (c ? m.locale(c) : m);

  /*** BEGIN localized date arithmetic methods with moment ***/
  function defineComparators(a, b, unit) {
    const datePart = fixUnit(unit);
    const dtA = datePart ? moment(a).startOf(datePart) : moment(a);
    const dtB = datePart ? moment(b).startOf(datePart) : moment(b);
    return [dtA, dtB, datePart];
  }

  function endOf(date = null, unit) {
    const datePart = fixUnit(unit);
    if (datePart) {
      return moment(date).endOf(datePart).toDate();
    }
    return moment(date).toDate();
  }

  function neq(a, b, unit) {
    const [dtA, dtB, datePart] = defineComparators(a, b, unit);
    return !dtA.isSame(dtB, datePart);
  }

  function inRange(day, min, max, unit = 'day') {
    const datePart = fixUnit(unit);
    const mDay = moment(day);
    const mMin = moment(min);
    const mMax = moment(max);
    return mDay.isBetween(mMin, mMax, datePart, '[]');
  }

  function merge(date, time) {
    if (!date && !time) return null;

    const tm = moment(time).format('HH:mm:ss');
    const dt = moment(date).startOf('day').format('MM/DD/YYYY');
    // We do it this way to avoid issues when timezone switching
    return moment(`${dt} ${tm}`, 'MM/DD/YYYY HH:mm:ss').toDate();
  }

  // function add(date, adder, unit) {
  //   const datePart = fixUnit(unit);
  //   return moment(date).add(adder, datePart).toDate();
  // }

  function range(start, end, unit = 'day') {
    const datePart = fixUnit(unit);
    // because the add method will put these in tz, we have to start that way
    let current = moment(start).toDate();
    const days = [];

    while (lte(current, end)) {
      days.push(current);
      current = add(current, 1, datePart);
    }

    return days;
  }

  function ceil(date, unit) {
    let floor = startOf(date, unit);

    return eq(floor, date) ? floor : add(floor, 1, unit);
  }

  function diff(dateA, dateB, unit) {
    if (!unit || unit === 'milliseconds') return Math.abs(+dateA - +dateB);

    // the .round() handles an edge case
    // with DST where the total won't be exact
    // since one day in the range may be shorter/longer by an hour
    return Math.round(
      Math.abs(
        +startOf(dateA, unit) / MILLI[unit] - +startOf(dateB, unit) / MILLI[unit],
      ),
    );
  }
  function firstOfWeek(culture) {
    const data = culture ? moment.localeData(culture) : moment.localeData();
    return data ? data.firstDayOfWeek() : 0;
  }

  function firstVisibleDay(date) {
    const md = moment.from(date);
    md.locale('fa');
    let firstOfMonth = md.startOf('month').startOf('week');
    return firstOfMonth.toDate();
  }

  function lastVisibleDay(date) {
    const md = moment.from(date);
    md.locale('fa');
    let endOfMonth = md.endOf('month').endOf('week');
    return endOfMonth.toDate();
  }

  function visibleDays(date) {
    let current = firstVisibleDay(date);
    const last = lastVisibleDay(date);
    const days = [];

    while (lte(current, last)) {
      days.push(current);
      current = add(current, 1, 'day');
    }

    return days;
  }
  /*** END localized date arithmetic methods with moment ***/

  /**
   * Moved from TimeSlots.js, this method overrides the method of the same name
   * in the localizer.js, using moment to construct the js Date
   * @param {Date} dt - date to start with
   * @param {Number} minutesFromMidnight
   * @param {Number} offset
   * @returns {Date}
   */
  function getSlotDate(dt, minutesFromMidnight, offset) {
    return moment(dt)
      .startOf('day')
      .minute(minutesFromMidnight + offset)
      .toDate();
  }

  // moment will automatically handle DST differences in it's calculations
  function getTotalMin(start, end) {
    return diff(start, end, 'minutes');
  }

  function getMinutesFromMidnight(start) {
    const dayStart = moment(start).startOf('day');
    const day = moment(start);
    return day.diff(dayStart, 'minutes');
  }

  // These two are used by DateSlotMetrics
  function continuesPrior(start, first) {
    return lt(start, first, 'day');
  }

  function continuesAfter(start, end, last) {
    const singleDayDuration = eq(start, end, 'minutes');

    return singleDayDuration ? gte(end, last, 'minutes') : gt(end, last, 'minutes');
  }

  // These two are used by eventLevels
  function sortEvents({
    evtA: { start: aStart, end: aEnd, allDay: aAllDay },
    evtB: { start: bStart, end: bEnd, allDay: bAllDay },
  }) {
    const startSort = +startOf(aStart, 'day') - +startOf(bStart, 'day');

    const durA = diff(aStart, ceil(aEnd, 'day'), 'day');

    const durB = diff(bStart, ceil(bEnd, 'day'), 'day');

    return (
      startSort || // sort by start Day first
      Math.max(durB, 1) - Math.max(durA, 1) || // events spanning multiple days go first
      !!bAllDay - !!aAllDay || // then allDay single day events
      +aStart - +bStart || // then sort by start time *don't need moment conversion here
      +aEnd - +bEnd // then sort by end time *don't need moment conversion here either
    );
  }

  function inEventRange({
    event: { start, end },
    range: { start: rangeStart, end: rangeEnd },
  }) {
    const startOfDay = moment(start).startOf('day');
    const eEnd = moment(end);
    const rStart = moment(rangeStart);
    const rEnd = moment(rangeEnd);

    const startsBeforeEnd = startOfDay.isSameOrBefore(rEnd, 'day');
    // when the event is zero duration we need to handle a bit differently
    const sameMin = !startOfDay.isSame(eEnd, 'minutes');
    const endsAfterStart = sameMin
      ? eEnd.isAfter(rStart, 'minutes')
      : eEnd.isSameOrAfter(rStart, 'minutes');

    return startsBeforeEnd && endsAfterStart;
  }

  // moment treats 'day' and 'date' equality very different
  // moment(date1).isSame(date2, 'day') would test that they were both the same day of the week
  // moment(date1).isSame(date2, 'date') would test that they were both the same date of the month of the year
  function isSameDate(date1, date2, unit = 'day') {
    return eq(date1, date2, unit);
  }

  /**
   * This method, called once in the localizer constructor, is used by eventLevels
   * 'eventSegments()' to assist in determining the 'span' of the event in the display,
   * specifically when using a timezone that is greater than the browser native timezone.
   * @returns number
   */
  function browserTZOffset() {
    /**
     * Date.prototype.getTimezoneOffset horrifically flips the positive/negative from
     * what you see in it's string, so we have to jump through some hoops to get a value
     * we can actually compare.
     */
    const dt = new Date();
    const neg = /-/.test(dt.toString()) ? '-' : '';
    const dtOffset = dt.getTimezoneOffset();
    const comparator = Number(`${neg}${Math.abs(dtOffset)}`);
    // moment correctly provides positive/negative offset, as expected
    const mtOffset = moment().utcOffset();
    return mtOffset > comparator ? 1 : 0;
  }

  function isJustDate(date) {
    return (
      hours(date) === 0 &&
      minutes(date) === 0 &&
      seconds(date) === 0 &&
      milliseconds(date) === 0
    );
  }

  function startAndEndAreDateOnly(start, end) {
    return isJustDate(start) && isJustDate(end);
  }

  return new DateLocalizer({
    formats,
    firstOfWeek,
    firstVisibleDay,
    lastVisibleDay,
    visibleDays,
    format(value, format, culture) {
      return locale(moment(value), culture).format(format);
    },
    lt,
    lte,
    gt,
    gte,
    eq,
    neq,
    merge,
    inRange,
    startOf,
    endOf,
    range,
    add,
    diff,
    ceil,
    min,
    max,
    minutes,
    getSlotDate,
    getTotalMin,
    getMinutesFromMidnight,
    continuesPrior,
    continuesAfter,
    sortEvents,
    inEventRange,
    isSameDate,
    browserTZOffset,
    startAndEndAreDateOnly,
  });
}
