import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { CheckedMailActionViewInterface } from './checked-mail-action.type';
import { useStyles } from './checked-mail-action.style';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import MarkUnReadIcon from '@material-ui/icons/MarkunreadOutlined';
import MarkReadIcon from '@material-ui/icons/DraftsOutlined';
import { Divider, IconButton } from '@material-ui/core';
import { LabelAction } from './label-action';
import ArchiveIcon from '../../../../../icon/ArchiveIcon';
import ReferencesIcon from '../../../../../icon/ReferencesIcon';
import { MoveToAction } from './move-to-action';
import UnarchiveIcon from '@material-ui/icons/Unarchive';

const CheckedMailActionView = (
  props: CheckedMailActionViewInterface,
): ReactElement => {
  const {
    mailActionsHandler,
    shouldMarkAsRead,
    onMarkUnReadClick,
    onArchiveClick,
    onReferenceClick,
    onUnArchiveClick,
    allArchived,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={translate('mail.toReference')}>
        <Box component="span" color="text.disabled">
          <IconButton onClick={onReferenceClick}>
            <ReferencesIcon className={classes.root} />
          </IconButton>
        </Box>
      </Tooltip>
      {!allArchived ? (
        <Tooltip title={translate('mail.toArchive')}>
          <Box component="span" color="text.disabled">
            <IconButton onClick={onArchiveClick}>
              <ArchiveIcon className={classes.root} />
            </IconButton>
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title={translate('mail.toUnArchive')}>
          <Box component="span" color="text.disabled">
            <IconButton onClick={onUnArchiveClick}>
              <UnarchiveIcon className={classes.root} />
            </IconButton>
          </Box>
        </Tooltip>
      )}

      {!shouldMarkAsRead ? (
        <Tooltip title={translate('mail.markAsUnRead')}>
          <Box component="span" color="text.disabled">
            <IconButton onClick={() => onMarkUnReadClick(null, true)}>
              <MarkUnReadIcon className={classes.root} />
            </IconButton>
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title={translate('mail.markAsRead')}>
          <Box component="span" color="text.disabled">
            <IconButton onClick={() => onMarkUnReadClick(null, false)}>
              <MarkReadIcon className={classes.root} />
            </IconButton>
          </Box>
        </Tooltip>
      )}
      <Divider orientation="vertical" flexItem />
      <MoveToAction mailActionsHandler={mailActionsHandler} />
      <LabelAction mailActionsHandler={mailActionsHandler} />
    </Box>
  );
};

export default CheckedMailActionView;
