import { ReactElement } from 'react';
import { VisitorFormViewInterface } from './visitor-form.type';
import { useStyles } from './visitor-form.style';
import { useLocale } from 'react-admin';
import { DynamicInput } from '../../dynamic-input';
import lodashGet from 'lodash/get';

const VisitorFormView = (props: VisitorFormViewInterface): ReactElement => {
  const { fields } = props;
  const locale = useLocale();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {fields &&
        fields.map(field => {
          return (
            <div className={classes.inputContainer}>
              <DynamicInput
                isProfile
                key={field.id}
                field={field}
                currentTabIndex={0}
                label={lodashGet(field, ['translatedCaption', locale], field.name)}
              />
            </div>
          );
        })}
    </div>
  );
};

export default VisitorFormView;
