import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  menucontent: {
    color: theme.palette.primary.appPrimaryTextColor,
    fontSize: 'small',
    margin: '0 1rem',
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  menuIcon: {
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    color: theme.palette.primary.appPrimaryTextColor,
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 19,
    },
  },
  accordionlistLiContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '.2rem 0',
    padding: '0 8px',
    borderRadius: 8,
    lineHeight: 3.6,
    maxHeight: 40,
    '&:hover': {
      backgroundColor: theme.palette.primary.appPrimaryDividerColor,
    },
    '&.active': {
      backgroundColor: theme.palette.primary.appPrimaryDividerColor,
    },
    cursor: 'pointer',
    transition: 'all 0.3s linear !important',
  },
  items: {
    padding: '0 8px',
  },
  menucontentChild: {
    color: '#585858',
    fontSize: 'small',
    margin: '0 1rem',
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  menuContentChildSelected: {
    color: '#00AAEC !important',
  },
  outlineCheckBox: {
    color: '#585858',
    width: '1.677em',
    height: '1.677em',
    border: '1px solid #585858',
    borderRadius: 2,
  },
  checkBox: {
    color: '#00AAEC !important',
    border: '1px solid #00AAEC',
    borderRadius: 2,
  },
}));
