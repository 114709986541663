import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Icon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  customerContainer: {
    margin: '0 10px 0 0',
    marginTop: 20,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },

  customerItem: {
    display: 'flex',
    alignItems: 'center',
  },

  customerItemValue: {
    margin: '0 10px',
  },
}));

const PointOfSaleCustomer = props => {
  const classes = useStyles();

  return (
    <Paper className={classes.customerContainer} elevation={0}>
      <div className={classes.customerItem}>
        <Icon color="action" fontSize="small">
          person
        </Icon>
        <span className={classes.customerItemValue}>علی رمضانی</span>
      </div>
      <div className={classes.customerItem}>
        <Icon color="action" fontSize="small">
          person
        </Icon>
        <span className={classes.customerItemValue}>US10047103840200</span>
      </div>
      <div className={classes.customerItem}>
        <Icon color="action" fontSize="small">
          phone_enabled
        </Icon>
        <span className={classes.customerItemValue}>09152502743</span>
      </div>
      <div className={classes.customerItem}>
        <Icon color="action" fontSize="small">
          credit_card
        </Icon>
        <span className={classes.customerItemValue}>میزان اعتبار: 0</span>
      </div>
      <div className={classes.customerItem}>
        <Icon className="fas fa-percent" color="action" fontSize="small"></Icon>
        <span className={classes.customerItemValue}>درصد تخفیف: 5%</span>
      </div>
      <div className={classes.customerItem}>
        <Icon color="action" fontSize="small">
          card_giftcard
        </Icon>
        <span className={classes.customerItemValue}>هدیه: 0</span>
      </div>
    </Paper>
  );
};

PointOfSaleCustomer.propTypes = {};

export default PointOfSaleCustomer;
