import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { AnnouncementPostsInterface } from './announcement-posts.type';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useStyles } from './announcement-posts.style';
import { PostCard } from './post-card';
import Pagination from '../Pagination';

const AnnouncementPostsView = (props: AnnouncementPostsInterface): ReactElement => {
  const { data, isLoading, paginationParams, onChangePageHandler } = props;
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Grid className={classes.container} item>
      <Box className={classes.toolbarContainer}>
        <Typography variant="h6" gutterBottom>
          {translate('blog.recentNotes')}
        </Typography>
      </Box>

      <Divider style={{ marginBottom: '2rem' }} />
      <div className={classes.contentContainer}>
        {data?.map(post => (
          <PostCard key={post?.announcement_id} post={post} isLoading={isLoading} />
        ))}
      </div>
      <Box
        className={classes.footerContainer}
        component="footer"
        sx={{ bgcolor: 'background.paper' }}
      >
        <Pagination
          total={paginationParams?.total}
          page={paginationParams?.currentPage}
          perPage={10}
          rowsPerPageOptions={[10]}
          setPage={onChangePageHandler}
          setPerPage={() => {}}
          isLoading={false}
          isCompactMode={false}
        />
      </Box>
    </Grid>
  );
};

export default AnnouncementPostsView;
