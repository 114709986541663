import { FC, memo, useEffect, useState, useCallback } from 'react';
import {
  MailActions,
  MailFolderInterface,
  OnGetFoldersParams,
  OnMoveToFolderParams,
} from '../../../..';
import { isEmptyObject } from '../../../../../../helper/data-helper';

import { MoveToActionInterface } from './move-to-action.type';
import MoveToActionView from './move-to-action.view';

const MoveToActionController: FC<MoveToActionInterface> = memo(props => {
  const { mailActionsHandler } = props;
  const [isFolderListOpen, setIsFolderListOpen] = useState<
    (EventTarget & HTMLElement) | null
  >(null);

  const [folders, setFolders] = useState<MailFolderInterface[]>([]);

  /**
   * @function successGetFoldersCallback
   * @param { Record<string, unknown> } response
   * @returns { void }
   */
  const successGetFoldersCallback = (response: Record<string, unknown>): void => {
    response?.data &&
      !isEmptyObject(response?.data) &&
      setFolders(response?.data as MailFolderInterface[]);
  };

  /**
   * @function getFolders
   * @returns { void }
   */
  const getFolders = useCallback(() => {
    mailActionsHandler(MailActions.onGetFolders, {
      isForInbox: 0,
      successCallback: successGetFoldersCallback,
    } as OnGetFoldersParams);
  }, []);

  /**
   * @function onOpenFolderList
   * @param event
   * @returns { void }
   */
  const onOpenFolderList = (event: any): void => {
    setIsFolderListOpen(event.currentTarget);
  };

  /**
   * @function onCloseFolderList
   * @returns { void }
   */
  const onCloseFolderList = (): void => {
    setIsFolderListOpen(null);
  };

  /**
   * @function onCreateNewFolder
   * @returns { void }
   */
  const onCreateNewFolder = (): void => {
    mailActionsHandler(MailActions.onOpenNewFolderForm);
  };

  /**
   * @function onSubmitMailToFolder
   * @param { MailFolderInterface } selectedFolder
   * @returns { void }
   */
  const onSubmitMailToFolder = (selectedFolder: MailFolderInterface): void => {
    mailActionsHandler(MailActions.onMoveToFolder, {
      Folders_ID: selectedFolder?.folders_id,
    } as OnMoveToFolderParams);
  };

  useEffect(() => {
    getFolders();
  }, []);

  return (
    <MoveToActionView
      folders={folders}
      isFolderListOpen={isFolderListOpen}
      onOpenFolderList={onOpenFolderList}
      onCloseFolderList={onCloseFolderList}
      onCreateNewFolder={onCreateNewFolder}
      onSubmitMailToFolder={onSubmitMailToFolder}
    />
  );
});

export default MoveToActionController;
