import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import Collapse from '@kunukn/react-collapse';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import classes from './accordion-item.style.module.css';

import type { AccordionItemViewInterface } from './accordion-item.type';

const AccordionItemView = (props: AccordionItemViewInterface): ReactElement => {
  const {
    title,
    containerClassName,
    collapseClassName,
    isOpen,
    onToggle,
    children,
  } = props;

  const locale = useLocale();

  return (
    <>
      <div
        className={`${classes['toggle']} ${containerClassName}`}
        onClick={onToggle}
      >
        {locale === 'en' ? (
          <KeyboardArrowLeft
            className={`${isOpen ? classes['icon-expanded-en'] : classes['icon']}`}
          />
        ) : (
          <KeyboardArrowRight
            className={`${isOpen ? classes['icon-expanded'] : classes['icon']}`}
          />
        )}
        <span className="toggle-text">{title}</span>
      </div>
      <Collapse className={collapseClassName} isOpen={isOpen}>
        {children}
      </Collapse>
    </>
  );
};

export default AccordionItemView;
