import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  appHeader: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${grey[300]}`,
    [theme.breakpoints.up('xl')]: {
      height: 77,
    },
  },
}));
