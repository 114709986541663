import { memo, FC, cloneElement } from 'react';
import { createPortal } from 'react-dom';
import { useTranslate } from 'react-admin';

import { HeaderTitleInterface } from './header-title.type';

const HeaderTitleController: FC<HeaderTitleInterface> = memo(props => {
  const { defaultTitle, record, title } = props;

  const translate = useTranslate();
  const container =
    typeof document !== 'undefined'
      ? document.getElementById('react-admin-title')
      : null;
  if (!container) return null;

  const titleElement = !title ? (
    <span>{defaultTitle}</span>
  ) : typeof title === 'string' ? (
    <span>{translate(title, { _: title })}</span>
  ) : (
    cloneElement(title, { record })
  );
  return createPortal(titleElement, container);
});

export default HeaderTitleController;
