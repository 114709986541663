import { DRAWER_CLOSE_WIDTH, DRAWER_OPEN_WIDTH } from '../../../core/configProvider';
import { getDrawerPatternInfo } from '../../../helper/PatternMetaHelper';

/**
 * it computes drawer width base of `isDrawerOpen`
 * @function getDrawerWidth
 * @param {boolean} isDrawerOpen
 * @returns {number} drawer width
 */
export const getDrawerWidth = (isDrawerOpen: boolean): number => {
  return isDrawerOpen
    ? getDrawerPatternInfo(DRAWER_OPEN_WIDTH)
    : getDrawerPatternInfo(DRAWER_CLOSE_WIDTH);
};
