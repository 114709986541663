import React from 'react';

export const ExpandChildMenuIcon = (props: any) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={props.width}
      height={props.height}
      viewBox="0 0 489.3 489.3"
      fill={props.color}
    >
      <g>
        <g>
          <path
            className="st0"
            d="M477,0H12.4C5.6,0,0.2,5.5,0.2,12.2V235c0,6.8,5.5,12.2,12.2,12.2s12.3-5.5,12.3-12.2V24.5h440.2v440.2H253
			c-6.8,0-12.3,5.5-12.3,12.3s5.5,12.3,12.3,12.3h224c6.8,0,12.3-5.5,12.3-12.3V12.3C489.2,5.5,483.8,0,477,0z"
          />
          <path
            className="st0"
            d="M0.1,476.9c0,6.8,5.5,12.3,12.2,12.3h170.4c6.8,0,12.3-5.5,12.3-12.3V306.6c0-6.8-5.5-12.3-12.3-12.3H12.4
			c-6.8,0-12.2,5.5-12.2,12.3v170.3L0.1,476.9L0.1,476.9z M24.5,318.8h145.9v145.9H24.5V318.8z"
          />
          <path
            className="st0"
            d="M222.9,266.3c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6L379,127.6v79.9c0,6.8,5.5,12.3,12.3,12.3
			s12.3-5.5,12.3-12.3V98.1c0-6.8-5.5-12.3-12.3-12.3H281.8c-6.8,0-12.3,5.5-12.3,12.3s5.5,12.3,12.3,12.3h79.9L222.9,249
			C218.1,253.8,218.1,261.5,222.9,266.3z"
          />
        </g>
      </g>
    </svg>
  );
};
