import { ReactElement } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { Box, Tooltip } from '@material-ui/core';
import momentJalaali from 'moment-jalaali';

import { MoreDetailInterface } from './more-detail.type';
import { convertStringToArray, isEmpty } from '../../../../../../helper/data-helper';

import { useStyles } from './more-detail.style';

const MoreDetailBodyView = (props: MoreDetailInterface): ReactElement => {
  const { mail, isMainMail } = props;
  const translate = useTranslate();
  const locale = useLocale();
  const classes = useStyles();

  momentJalaali.locale(locale);

  const toPersonInfo = isMainMail
    ? mail?.__topersoninfo_id_value
    : mail?.__refertopersoninfo_id_value;

  const ccPersonInfo = isMainMail
    ? mail?.__ccpersoninfo_id_value
    : mail?.__referccpersoninfo_id_value;

  const bccPersonInfo = isMainMail
    ? mail?.__bccpersoninfo_id_value
    : mail?.__referbccpersoninfo_id_value;

  /**
   * @function generateInfo
   * @param  {string } info
   * @returns { ReactElement }
   */
  const generateInfo = (info: string) => {
    return convertStringToArray(info, ',')?.map(
      (item: string) =>
        !isEmpty(item) && (
          <>
            <Tooltip title={item}>
              <Box component="span" fontWeight={400} fontSize={13.5}>
                {item?.length > 18 ? `${item?.substring(0, 18)} ...` : item}
              </Box>
            </Tooltip>
            {' , '}
          </>
        ),
    );
  };

  return (
    <Box px={2} minWidth={300} maxWidth={600} className={classes.moreText}>
      {mail && (
        <>
          <Box>
            <Box component="span" color="text.light">
              {translate('mail.from')}:{'  '}
            </Box>{' '}
            <Tooltip title={mail?.fromcontactinfo ?? ''}>
              <Box component="span" fontWeight="bold" fontSize={14}>
                {mail?.fromcontactinfo}{' '}
              </Box>
            </Tooltip>
          </Box>

          {toPersonInfo && (
            <Box mt={2}>
              <Box component="span" color="text.light">
                {translate('mail.to')}:{'  '}
              </Box>
              {'  '}
              {generateInfo(toPersonInfo)}
            </Box>
          )}

          {ccPersonInfo && (
            <Box mt={2}>
              <Box component="span" color="text.light">
                {translate('mail.cc')}:{'  '}
              </Box>
              {'  '}
              {generateInfo(ccPersonInfo)}
            </Box>
          )}

          {bccPersonInfo && (
            <Box mt={1}>
              <Box component="span" color="text.light">
                {translate('mail.bcc')}:{'  '}
              </Box>
              {'  '}
              {generateInfo(bccPersonInfo)}
            </Box>
          )}

          <Box mt={2}>
            <Box component="span" color="text.light">
              {translate('mail.referenceListHeader.date')}:{'  '}
            </Box>
            {locale === 'fa'
              ? momentJalaali(mail?.referdate).format('jDD jMMMM jYYYY,  HH:mm')
              : momentJalaali(mail?.referdate).format('DD MMMM YYYY, HH:mm')}
            {'  '}({momentJalaali(mail.referdate).startOf('second').fromNow()}){' '}
          </Box>

          {/* <Box mt={2}>
            <Box component="span" color="text.light">
              {translate('mail.mailSubject')}:{'  '}
            </Box>
            {mail?.messageparaph ?? mail?.subject}
          </Box> */}
        </>
      )}
    </Box>
  );
};

export default MoreDetailBodyView;
