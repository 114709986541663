import { FC, useState, memo } from 'react';

import { MenuHeaderInterface } from './menu-header.type';
import MenuHeaderView from './menu-header.view';

const MenuHeaderController: FC<MenuHeaderInterface> = memo(props => {
  const {
    searchInMenu,
    selectedList,
    selectedMenuLabel,
    backClickHandler,
    width,
    isDrawerOpen,
  } = props;
  const [showSearchBox, setShowSearchBox] = useState<boolean>(false);

  /**
   * @function toggleSearchBox
   * @returns {void}
   */
  const toggleSearchBox = (): void => {
    setShowSearchBox(prev => !prev);
    searchInMenu(''); //empty search box
  };

  return (
    <MenuHeaderView
      searchInMenu={searchInMenu}
      selectedList={selectedList}
      selectedMenuLabel={selectedMenuLabel}
      backClickHandler={backClickHandler}
      width={width}
      showSearchBox={showSearchBox}
      toggleSearchBox={toggleSearchBox}
      isDrawerOpen={isDrawerOpen}
    />
  );
});

export default MenuHeaderController;
