import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import ContentLoader from 'react-content-loader';

export const MailDetailSkeleton = (): ReactElement => {
  const locale = useLocale();
  return (
    <ContentLoader
      speed={2}
      width={1200}
      height={550}
      viewBox="0 0 1200 550"
      backgroundColor="#d9d9d9"
      foregroundColor="#ecebeb"
      rtl={locale !== 'en'}
      style={{ width: '100%', height: '100%' }}
    >
      <rect x="40" y="0" rx="3" ry="3" width="88" height="4" />
      <circle cx="60" cy="70" r="20" />
      <rect x="90" y="70" rx="3" ry="3" width="52" height="6" />
      <rect x="900" y="70" rx="3" ry="3" width="200" height="6" />

      <rect x="70" y="140" rx="3" ry="3" width="300" height="8" />
      <rect x="70" y="170" rx="3" ry="3" width="410" height="8" />
      <rect x="70" y="200" rx="3" ry="3" width="350" height="8" />
      <rect x="70" y="230" rx="3" ry="3" width="200" height="8" />

      <rect x="70" y="320" rx="3" ry="3" width="100" height="35" />
      <rect x="180" y="320" rx="3" ry="3" width="100" height="35" />
      <rect x="290" y="320" rx="3" ry="3" width="100" height="35" />
    </ContentLoader>
  );
};
