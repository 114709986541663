import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import lodashGet from 'lodash/get';
import { dxEvent } from 'devextreme/events';

const useStyles = makeStyles(theme => ({
  menuItem: {
    fontSize: 12,
  },
}));

const ServiceButton = props => {
  const { selectedIds, service, locale, onClick, forceDisable } = props;
  const classes = useStyles();

  const checkIsDisabled = () => {
    if (!service) {
      return true;
    }

    switch (service.related) {
      case 'SingleRecord':
        return selectedIds.length !== 1;

      case 'MultiRecords':
        return !selectedIds.length;

      default:
        return false;
    }
  };

  /**
   * @function onClickMenu
   * @param {*} event
   */
  const onClickMenu = event => {
    onClick(service);
  };

  return (
    <MenuItem
      className={classes.menuItem}
      disabled={forceDisable || checkIsDisabled()}
      onClick={onClickMenu}
      data-test-service-id={service.id || service.uniqueId}
      data-test-is-manual-service={service.manualExecute}
    >
      {lodashGet(
        service,
        ['translatedTitle', locale],
        lodashGet(service, ['title']),
      )}
    </MenuItem>
  );
};

ServiceButton.propTypes = {
  service: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedIds: PropTypes.array,
  forceDisable: PropTypes.bool,
};

export default ServiceButton;
