import { FC, memo } from 'react';

import { AnnouncementPostsInterface } from './announcement-posts.type';
import AnnouncementPostsView from './announcement-posts.view';

const AnnouncementPostsController: FC<AnnouncementPostsInterface> = memo(props => {
  const { data, paginationParams, onChangePageHandler, isLoading } = props;

  return (
    <AnnouncementPostsView
      data={data}
      isLoading={isLoading}
      paginationParams={paginationParams}
      onChangePageHandler={onChangePageHandler}
    />
  );
});

export default AnnouncementPostsController;
