import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  pointer: {
    cursor: 'pointer',
  },

  rotate180: {
    transform: 'rotate(180deg)',
  },

  contentClassName: {
    padding: '0 !important',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },
}));
