import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, InputBase, Button, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  searchContainer: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
  },

  searchInput: {
    flexGrow: 1,
    padding: '0 10px',
  },

  searchButton: {
    padding: 10,
  },

  divider: {
    width: 1,
    height: 30,
  },
}));

const PointOfSaleSearch = props => {
  const { translate } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.searchContainer} elevation={0}>
      <InputBase
        className={classes.searchInput}
        placeholder={translate('pos.searchForProductNameOrBarcode')}
      />
      <Button className={classes.searchButton} disabled>
        {translate('pos.giftCard')}
      </Button>
      <Divider className={classes.divider} />
      <Button className={classes.searchButton}>{translate('pos.customSale')}</Button>
    </Paper>
  );
};

PointOfSaleSearch.propTypes = {};

export default compose(translate)(PointOfSaleSearch);
