import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  contentText: {
    whiteSpace: 'pre-wrap',
  },
  requestId: {
    textAlign: 'center',
    fontSize: '0.7em',
    borderTop: `1px solid ${theme.palette.divider}`,
    lineHeight: 2,
    cursor: 'pointer',
  },
  undo: {
    color: theme.palette.primary.light,
  },
  confirm: {
    backgroundColor: theme.palette.background.default,
  },

  warning: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
    zIndex: 5000,
  },

  error: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    zIndex: 5000,
  },
}));
