import { FC, useEffect, useState } from 'react';

import { CustomChildMenuInterface } from './custom-child-menu.type';
import { actorDispatch, actorOnDispatch } from '../../../type/actor-setup';
import { useStyles } from './custom-child-menu.style';
import { ChildMenu } from '../../child-menu';

const CustomChildMenuView: FC<CustomChildMenuInterface> = props => {
  const { width } = props;
  const [isChildMenuOpen, setIsChildMenuOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    actorOnDispatch('isChildMenuOpen', setIsChildMenuOpen, {
      preserve: false,
    });
  }, []);

  /**
   * @function onCloseChildMenu
   * @returns {void}
   */
  const closeChildMenu = (): void => {
    actorDispatch('isChildMenuOpen', false);
  };

  return (
    <>
      {width !== 'xs' ? (
        <>
          <ChildMenu />

          {isChildMenuOpen && (
            <span className={classes.backdrop} onClick={closeChildMenu} />
          )}
        </>
      ) : null}
    </>
  );
};

export default CustomChildMenuView;
