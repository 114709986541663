import { isEmpty } from '../../../helper/data-helper';
/**
 * Validate if a hex code is valid or not
 * @function isValidColor
 * @param {string} str hex code
 * @returns {boolean}
 * @see {@link https://stackoverflow.com/a/8868839/3382152}
 */
export const isValidColor = (str: string | null): boolean => {
  return !isEmpty(str) && str?.match(/^#[a-f0-9]{6}$/i) !== null;
};
