import { Button, Divider, Typography } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { FC, memo, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { createPortal } from 'react-dom';
import { CustomToolbarInterface } from '../../big-calendar';
import { useStyles } from './report-calendar-toolbar.style';

//@ts-ignore
const ReportCalendarToolbarController: FC<CustomToolbarInterface> = memo(props => {
  const translate = useTranslate();

  const classes = useStyles();
  const { onNavigate, onView, view } = props;
  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    setDomReady(true);
  });

  return (
    domReady &&
    createPortal(
      <div className={classes.root}>
        <Typography component={'span'} variant="h6" className={classes.label}>
          {props.label}
        </Typography>
        <div className={classes.action}>
          <div className={classes.buttonActions}>
            <Button
              size={'small'}
              className={classes.navigateButton}
              onClick={() => onNavigate('PREV')}
            >
              <KeyboardArrowRightIcon />
            </Button>
            <Button
              size={'small'}
              className={classes.navigateButtonToday}
              onClick={() => onNavigate('TODAY')}
            >
              {translate('calendar.today')}
            </Button>
            <Button
              size={'small'}
              className={classes.navigateButton}
              onClick={() => onNavigate('NEXT')}
            >
              <KeyboardArrowLeftIcon />
            </Button>
          </div>
          <div className={classes.viewButtons}>
            <Button
              className={`${classes.viewButton} ${
                view === 'month' && classes.checkedButton
              }`}
              size={'small'}
              onClick={() => onView('month')}
            >
              {translate('calendar.month')}
            </Button>
            <Divider orientation={'vertical'} />
            <Button
              className={`${classes.viewButton} ${
                view === 'week' && classes.checkedButton
              }`}
              size={'small'}
              onClick={() => onView('week')}
            >
              {translate('calendar.week')}
            </Button>
            <Divider orientation={'vertical'} />
            <Button
              className={`${classes.viewButton} ${
                view === 'day' && classes.checkedButton
              }`}
              size={'small'}
              onClick={() => onView('day')}
            >
              {translate('calendar.day')}
            </Button>
          </div>
        </div>
      </div>,
      document.getElementById('toolbar-big-calendar') as Element,
    )
  );
});

export default ReportCalendarToolbarController;
