import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  appsContentContainer: (props: { isDetailView: boolean; fullView: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${props.isDetailView ? 60 : 115}px)`,
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${props.fullView ? 0 : 60}px)`,
    },
    [theme.breakpoints.up('xl')]: {
      height: `calc(100% - ${props.fullView ? 0 : 77}px)`,
    },
  }),
}));
