import { ReactElement } from 'react';
import { DialogContent, DialogTitle } from '@material-ui/core';
import lodashGet from 'lodash/get';

import { FieldType, MetaData } from '../../helper/Types';
import { CustomForm, FormController } from '../form';
import QuickCreateButtonToolbar from '../QuickCreateButtonToolbar';
import { DialogViewProps, ServiceDialogDataItem } from './dialogs-stack.type';
import { ServiceDialogForm } from '../form/service-dialog-form';
import { useStyles } from './dialogs-stack.style';

export const ServiceDialogView = (props: DialogViewProps): ReactElement => {
  const { dialogData, closeDialogHandler } = props;

  const classes = useStyles();

  const { metaData, data } = dialogData;
  const { service, locale, serviceParams } = data as ServiceDialogDataItem;

  const fields = (service?.parameters as Array<Record<string, FieldType>>).map(
    parameter => parameter.field,
  );

  const { customRefresh, customSubmit, ...restServiceParam } = serviceParams;

  return (
    <>
      <DialogTitle id="show-image-dialog-title">
        {lodashGet(
          service,
          ['translatedTitle', locale],
          lodashGet(service, ['title']),
        )}
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
        data-style-dialog-service="dialogService"
      >
        <FormController formName="serviceDialogForm">
          <CustomForm
            Toolbar={
              <QuickCreateButtonToolbar
                closeDialog={closeDialogHandler}
                disableFullFormButton={true}
                metaData={metaData as MetaData}
                justSaveAndClose={true}
                isFromDropdown={false}
                customSubmit={customSubmit}
                customRefresh={customRefresh}
                resource={serviceParams?.parentResource}
                customClassName={classes.serviceBottomToolbarClassName}
              />
            }
            isBottomToolbar
          >
            <ServiceDialogForm
              serviceParams={restServiceParam}
              fields={fields}
              isServiceMode
            />
          </CustomForm>
        </FormController>
      </DialogContent>
    </>
  );
};
