import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';

import { useStyles } from './reference-header.style';

const ReferenceHeaderView = (): ReactElement => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box className={classes.root}>
      <Box component="span" width={0.18}>
        {translate('mail.referenceListHeader.date')}
      </Box>

      <Box component="span" width={0.2}>
        {translate('mail.referenceListHeader.sender')}
      </Box>

      <Box component="span" width={0.18}>
        {translate('mail.referenceListHeader.receiver')}
      </Box>

      <Box component="span" width={0.2}>
        {translate('mail.referenceListHeader.referenceParaph')}
      </Box>

      <Box component="span" width={0.15}>
        {translate('mail.referenceListHeader.seenDate')}
      </Box>

      <Box component="span" width={0.05}>
        {translate('mail.referenceListHeader.kind')}
      </Box>

      <Box component="span" width={0.02}>
        {translate('mail.recall')}
      </Box>
    </Box>
  );
};

export default ReferenceHeaderView;
