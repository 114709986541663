import { ReactElement } from 'react';
import Draggable from 'react-draggable';
import { AppDialogInterface } from './app-dialog.type';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogActions } from '@material-ui/core';

/**
 * @function PaperComponent
 * @param { PaperProps } props
 * @returns { ReactElement }
 */
const PaperComponent = (props: PaperProps): ReactElement => {
  return (
    // @ts-ignore
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

const AppDialogView = (props: AppDialogInterface): ReactElement => {
  const {
    open,
    handleClose,
    dialogTitle,
    dialogContent,
    dialogActions,
    fullScreen,
    maxWidth,
    contentClassName,
    disableBackdropClick = false,
  } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      keepMounted
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      disableBackdropClick={disableBackdropClick}
      disableEnforceFocus={true}
      data-test="app-dialog"
      data-style-dialog="dialog"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <DialogContent
        className={contentClassName}
        data-style-dialog-content="dialogContent"
      >
        {dialogContent}
      </DialogContent>
      <DialogActions data-style-dialog-action="dialogActions">
        {dialogActions}
      </DialogActions>
    </Dialog>
  );
};

export default AppDialogView;
